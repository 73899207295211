import { validateTravelers as I } from "@cvccorp-engines/pax";
import { FlightOptions as g } from "../store/FlightStore.types.js";
function x(n) {
  var d, l;
  let s = !1;
  const p = () => Object.entries(n.multidestinations).reduce((N, [E, t]) => {
    var f;
    const D = !t.destination, m = !t.origin, u = !((f = t.calendarDates) != null && f.startDate);
    return s = D || m || u, {
      ...N,
      [Number(E)]: {
        destination: D,
        origin: m,
        calendarDates: {
          startDate: u,
          endDate: !1
        }
      }
    };
  }, {}), e = !n.destination, a = !n.classe, i = !n.origin, o = !(n.choice !== g.GOING_AND_BACK || (d = n.calendarDates) != null && d.endDate), c = !((l = n.calendarDates) != null && l.startDate), [r] = I(n.pax);
  s = e || i || o || c || a || r;
  const h = n.choice === g.MULTIPLE_DESTINATION ? p() : {};
  return [s, {
    destination: e,
    origin: i,
    multidestinations: h,
    classe: a,
    pax: r,
    calendarDates: {
      endDate: o,
      startDate: c
    }
  }];
}
export {
  x as default
};
