import e, { Fragment as p } from "react";
import h from "../LocationAndDate/index.js";
const C = (n) => {
  const {
    children: t,
    onDateChange: a,
    onDateClear: o,
    max: r,
    min: i,
    origin: l,
    destination: m,
    onDestinationChange: s,
    onOriginChange: c,
    onOpenPax: d,
    onReverse: f,
    dates: g = [],
    errors: u = {},
    isMult: D = !1
  } = n;
  return /* @__PURE__ */ e.createElement(p, null, /* @__PURE__ */ e.createElement(h, { errors: u, dates: g, onDateClear: o, onDateChange: a, max: r, min: i, isMult: D, origin: l, destination: m, onDestinationChange: s, onOriginChange: c, onReverse: f, onOpenPax: d }), t);
};
C.displayName = "DefaultLocationForm";
export {
  C as default
};
