function s(a, t) {
  const {
    allowEmpty: n = !0
  } = t || {}, e = new URLSearchParams();
  return Object.entries(a).forEach(([c, r]) => {
    !r && !n || e.append(c, r ? r.trim() : "");
  }), e;
}
export {
  s as default
};
