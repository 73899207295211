import s from "react";
function n() {
  return n = Object.assign ? Object.assign.bind() : function(i) {
    for (var l = 1; l < arguments.length; l++) {
      var a = arguments[l];
      for (var e in a) ({}).hasOwnProperty.call(a, e) && (i[e] = a[e]);
    }
    return i;
  }, n.apply(null, arguments);
}
function f(i, l) {
  if (i == null) return {};
  var a = {};
  for (var e in i) if ({}.hasOwnProperty.call(i, e)) {
    if (l.includes(e)) continue;
    a[e] = i[e];
  }
  return a;
}
var p = {
  size: {
    xs: "0.75rem",
    sm: "1rem",
    md: "1.25rem",
    lg: "1.5rem",
    xl: "2rem",
    xxl: "2.5rem"
  }
}, r = p.size, w = {
  xsmall: r.xs,
  small: r.sm,
  medium: r.md,
  large: r.lg,
  xlarge: r.xl,
  xxlarge: r.xxl
}, _ = ["size", "className"], $ = function(l) {
  var a, e, o = l.size, m = o === void 0 ? "medium" : o, c = l.className, u = c === void 0 ? "" : c, t = f(l, _), v = t, g = ["chui-icon", u].join(" "), h = v.color || "#000014", x = n({}, t, {
    color: void 0
  }), d = r[m] || w[m];
  return /* @__PURE__ */ s.createElement("svg", n({
    xmlns: "http://www.w3.org/2000/svg",
    fill: h,
    viewBox: "0 0 24 24",
    width: (a = t.width) != null ? a : d,
    height: (e = t.height) != null ? e : d,
    className: g
  }, x), /* @__PURE__ */ s.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M12 5.777a3.695 3.695 0 1 0 0 7.39 3.695 3.695 0 0 0 0-7.39M9.307 9.472a2.695 2.695 0 1 1 5.39 0 2.695 2.695 0 0 1-5.39 0",
    clipRule: "evenodd"
  }), /* @__PURE__ */ s.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M12 2.221a7.24 7.24 0 0 0-7.25 7.25c0 2.436 1.155 4.988 2.478 7.11 1.331 2.133 2.876 3.901 3.747 4.785l.002.002a1.433 1.433 0 0 0 2.021 0c.87-.87 2.422-2.612 3.76-4.738 1.332-2.114 2.493-4.672 2.493-7.158a7.24 7.24 0 0 0-7.25-7.25m-6.25 7.25A6.24 6.24 0 0 1 12 3.222a6.24 6.24 0 0 1 6.251 6.25c0 2.204-1.042 4.567-2.339 6.627a28 28 0 0 1-3.62 4.563.433.433 0 0 1-.607.001c-.83-.843-2.325-2.553-3.608-4.61-1.292-2.072-2.327-4.429-2.327-6.58",
    clipRule: "evenodd"
  }));
};
$.displayName = "OutlinePinMap";
export {
  f as _,
  n as a,
  r as e,
  w as l,
  $ as r
};
