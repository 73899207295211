"use client";
import e from "react";
import h from "@cvccorp-components/chui-react-components/components/Button";
import a from "@cvccorp-components/chui-react-components/components/Grid";
import M from "@cvccorp-components/chui-react-components/components/Typography";
import b from "@cvccorp-components/chui-react-components/hooks/useMediaQuery";
import { v as j } from "../../index-Cq97huam.js";
import { DestinationCircle as w, MultiDestinationWrapper as I, LocationAndDateContainer as L } from "../../styled.js";
import R from "../DefaultLocationForm/index.js";
function O(l) {
  const {
    index: n,
    destinationKey: r,
    max: s,
    min: m,
    origin: c,
    destination: u,
    errors: f,
    isLast: p = !1,
    dates: d = [],
    hidePax: x = !0,
    onReverse: E,
    onDateClear: y,
    onDateChange: g,
    handleRemove: t,
    onOriginChange: C,
    onDestinationChange: v,
    firstInputRef: D
  } = l, o = b("(max-width: 720px)"), i = t;
  return /* @__PURE__ */ e.createElement(e.Fragment, null, o && /* @__PURE__ */ e.createElement(a, { container: !0, spacing: 0, justifyContent: "space-between", alignItems: "flex-start" }, /* @__PURE__ */ e.createElement(a, { item: !0, xs: 6, style: i ? void 0 : {
    paddingBlockEnd: 16
  } }, /* @__PURE__ */ e.createElement("div", { style: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    columnGap: 8
  } }, /* @__PURE__ */ e.createElement(w, null, n), /* @__PURE__ */ e.createElement(M, { variant: "headline", scale: 7, style: {
    fontSize: 18
  } }, "Voo"))), i && /* @__PURE__ */ e.createElement(a, { item: !0, xs: 6 }, /* @__PURE__ */ e.createElement("div", { style: {
    display: "flex",
    justifyContent: "flex-end",
    transform: "translateX(12px)"
  } }, /* @__PURE__ */ e.createElement(h, { "aria-label": `Remover voo ${n}`, icon: /* @__PURE__ */ e.createElement(j, null), variant: "text", color: "neutral", size: "lg", type: "button", onClick: () => t == null ? void 0 : t(r) })))), /* @__PURE__ */ e.createElement(I, { index: n, isLast: p }, /* @__PURE__ */ e.createElement("span", { className: "destination" }), /* @__PURE__ */ e.createElement(L, null, /* @__PURE__ */ e.createElement(R, { max: s, min: m, errors: f, onRemove: i && !o ? () => t == null ? void 0 : t(r) : void 0, hidePax: x, dates: d, onDateChange: g, onDateClear: y, isMult: !0, origin: c, destination: u, onOriginChange: C, onDestinationChange: v, onReverse: E, firstInputRef: D }))));
}
export {
  O as default
};
