import e, { useState as i } from "react";
import { SearchButton as s } from "@cvccorp-engines/components";
import { PaxBusing as u, DEFAULT_BUSING as c } from "@cvccorp-engines/pax";
import { u as p } from "../../EngineBusingContext-aoE1hnqw.js";
import f from "../Calendar/Calendar.js";
import x from "../Pesqinc/Pesqinc.js";
import { EngineContainer as E } from "./DefaultLocationForm.styles.js";
const g = () => {
  const {
    paxValue: t,
    setPaxValue: a,
    errors: n,
    handleSearch: l
  } = p(), [o, r] = i(!1);
  return /* @__PURE__ */ e.createElement(E, null, /* @__PURE__ */ e.createElement(x, null), /* @__PURE__ */ e.createElement(u, { open: o, value: t, error: n.pax, onToggle: () => r(!o), onClear: () => a(c), onConfirm: (m) => {
    a(m), r(!1);
  } }), /* @__PURE__ */ e.createElement(f, null), /* @__PURE__ */ e.createElement(s, { onClick: l, "aria-label": "Buscar rodoviário fácil" }));
};
g.displayName = "DefaultLocationForm";
export {
  g as default
};
