import { GTMProducts as d, GTMProductTower as n, gtm as e } from "@cvccorp-engines/components";
import { d as m } from "../dayjs-CKf7tiKR.js";
const u = (r, i) => {
  const [t, o] = i.split(":");
  return m(r).add(Number(t), "hours").add(Number(o), "minutes");
}, k = (r) => {
  var c;
  const t = [r.pickupLocation.country].concat(r.showReturnLocation ? [r.returnLocation.country] : []).every((p) => p === "Brasil"), o = u(r.pickupDate, r.pickupTime), a = u(r.returnDate, r.returnTime);
  return {
    product: d.CAR,
    product_tower: t ? n.NATIONAL : n.INTERNATIONAL,
    checkin_date: e.formatDate(r.pickupDate),
    checkout_date: e.formatDate(r.returnDate),
    duration_days: a.diff(o, "days").toString(),
    checkin_time: r.pickupTime,
    checkout_time: r.returnTime,
    origin_city: r.pickupLocation.city,
    destination_city: ((c = r.returnLocation) == null ? void 0 : c.city) ?? ""
  };
};
export {
  k as formatStateToGTM
};
