import { Button as e } from "@cvccorp-components/chui-react-components";
import n from "@emotion/styled";
const p = n.span`
    visibility: hidden;
    opacity: 0;
    width: 0;
    height: 0;
`, d = n.div`
    position: relative;
    scrollbar-width: 0px;
    container-type: inline-size;
`, f = n.nav`
    &::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: 0px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;
    overflow-x: auto;
    display: inline-flex;
    flex-wrap: nowrap;
    height: 57px;

    gap: 24px;
`, y = n(e)(({
  theme: t,
  show: o,
  right: i,
  left: r
}) => ({
  right: i,
  left: r,
  visibility: o ? "visible" : "hidden",
  pointerEvents: o ? "auto" : "none",
  opacity: o ? 1 : 0,
  position: "absolute",
  zIndex: t.zIndex.default,
  transition: "all 200ms",
  transform: `scale(${o ? 1 : 0.05})`,
  "@media (max-width: 599px)": {
    background: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)",
    width: 95,
    height: "100%",
    pointerEvents: "none",
    transform: i === 0 ? "rotate(180deg) translateX(-1px)" : "none"
  },
  [`@media (min-width:0px) and (max-width:${t.breakpoints.layoutGridSm})`]: {
    display: "none"
  }
})), l = (t) => t.name === "cvc" ? {
  color: t.colors.brand.secondary[500]
} : {
  color: t.colors.brand.primary[500]
}, a = (t, o) => {
  const {
    color: i
  } = l(t);
  return {
    "*": {
      fontWeight: t.typography.font.weight.regular,
      fontSize: t.typography.font.size.xs,
      color: o ? i : t.colors.neutral.black,
      fill: o ? i : t.colors.neutral.black,
      fontFamily: t.typography.font.family[2]
    },
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    position: "relative",
    ":after": {
      content: o ? '" "' : void 0,
      position: "absolute",
      background: i,
      width: 20,
      height: 2,
      left: "50%",
      bottom: 0,
      transform: "translateX(calc(50% - 20px))"
    }
  };
}, g = n.button(({
  theme: t,
  underline: o
}) => ({
  ...a(t, o),
  backgroundColor: "transparent",
  border: "none"
})), b = n.a(({
  theme: t,
  underline: o
}) => a(t, o)), u = n.div(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  whiteSpace: "nowrap"
}));
export {
  y as Button,
  d as Container,
  p as Hidden,
  f as Nav,
  g as NavigationButton,
  b as NavigationLink,
  u as ProductWrapper
};
