import e from "react";
import { FormContainer as t } from "@cvccorp-engines/components";
import { useMotorToursContext as D } from "../../context/EngineToursContext.js";
import { useToursStore as f } from "../../store/ToursStore.js";
import m from "../ToursDefaultLocationForm/ToursDefaultLocationForm.js";
import { MotorContainer as u, ButtonContainer as C } from "./styled.js";
function h() {
  const {
    handleSearch: c
  } = D(), {
    destinations: n,
    date: o,
    allDestiny: r,
    errors: a,
    setAllDestiny: i,
    setDestinations: s,
    setDate: l
  } = f();
  return /* @__PURE__ */ e.createElement(t, { onSearch: c, formId: "form-tours", renderMobile: () => /* @__PURE__ */ e.createElement(t.Mobile, { title: "Circuito", label: "Destino" }, /* @__PURE__ */ e.createElement(u, { twoItems: !0 }, /* @__PURE__ */ e.createElement(m, { date: o, errors: a, allDestiny: r, destinations: n, onDateChange: l, onAllDestinyChange: i, onDestinationsChange: s }))), renderDesktop: () => /* @__PURE__ */ e.createElement(u, { twoItems: !0 }, /* @__PURE__ */ e.createElement(m, { date: o, errors: a, allDestiny: r, destinations: n, onDateChange: l, onAllDestinyChange: i, onDestinationsChange: s }, /* @__PURE__ */ e.createElement(C, null, /* @__PURE__ */ e.createElement(t.Submit, null)))) });
}
h.displayName = "SingleDestinationForm";
export {
  h as default
};
