import a, { useRef as E, Fragment as R } from "react";
import { useFormMobile as D, InputDatePicker as P } from "@cvccorp-engines/components";
import h from "../SingleTickets/SingleTickets.js";
function b(o) {
  const {
    errors: e,
    max: l,
    min: c,
    destination: i,
    date: u,
    onDestinationChange: t,
    onDateChange: d,
    onDateClear: m
  } = o, {
    firstInputRef: p
  } = D(), f = E(null), n = () => e != null && e.destination ? "Preencha o campo de destino" : "", s = () => e != null && e.date ? "Preencha o campo de data" : "";
  return /* @__PURE__ */ a.createElement(R, null, /* @__PURE__ */ a.createElement(h, { supportText: n(), error: n(), label: "Destino", value: i, onSelect: (r) => {
    t == null || t(r);
  }, inputProps: {
    inputRef: p
  }, onClear: () => {
    t == null || t(void 0);
  } }), /* @__PURE__ */ a.createElement(P, { label: "Escolha a data", value: u, onChange: d, onClear: m, max: l, min: c, error: s(), inputRef: f }));
}
b.displayName = "LocationAndDate";
export {
  b as default
};
