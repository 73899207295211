import e, { useEffect as c } from "react";
import { useResponsive as u } from "@cvccorp-components/chui-react-components";
import p from "@cvccorp-components/chui-react-components/components/Button";
import o from "@cvccorp-components/chui-react-components/components/Drawer";
import f from "@cvccorp-components/chui-react-components/components/Input";
import { s as E } from "../../FilledSearch-CbEpjqut.js";
import { useEnginesConfigContext as d } from "@cvccorp-engines/provider";
import { MobileContainer as b } from "./FormContainer.styles.js";
import M from "./FormContainerSubmit.js";
import { useFormMobile as g } from "./FormMobileProvider.js";
function w(l) {
  const {
    children: i,
    label: m,
    title: a
  } = l, {
    searchButton: s
  } = d(), {
    isModalOpen: r,
    setIsModalOpen: t
  } = g(), {
    isMobile: n
  } = u();
  return c(() => {
    !n && r && t(!1);
  }, [n, r, t]), /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(b, { onClick: () => t(!0) }, /* @__PURE__ */ e.createElement(f, { label: m, fullWidth: !0, inputAttributes: {
    readOnly: !0
  } }), /* @__PURE__ */ e.createElement(p, { size: "lg", color: s.color, icon: /* @__PURE__ */ e.createElement(E, null) })), /* @__PURE__ */ e.createElement(o, { open: r, keepMounted: !1 }, /* @__PURE__ */ e.createElement(o.Header, { backButtonProps: {
    "aria-label": "Voltar"
  }, title: a, onBack: () => t(!1) }), /* @__PURE__ */ e.createElement(o.Main, { style: {
    padding: "24px 16px"
  } }, i), /* @__PURE__ */ e.createElement(o.Footer, null, /* @__PURE__ */ e.createElement(M, null))));
}
export {
  w as default
};
