"use client";
import a from "react";
import c from "@cvccorp-components/chui-react-components/components/Typography";
import { useTheme as u } from "@emotion/react";
import b from "../../utils/countPax.js";
import "@cvccorp-engines/date";
import s from "../Counter/Counter.js";
import { usePax as x } from "./PaxProvider/PaxProvider.js";
const h = 9;
function P(i) {
  const {
    addPax: n,
    removePax: o,
    roomIndex: t
  } = i, {
    travelers: e,
    errors: l
  } = x(), d = u(), {
    total: p
  } = b(e), m = p >= h;
  return /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(s, { label: "Adultos", helperText: "Acima de 12 anos", min: 1, max: m ? e.adult : 9, onChange: (r) => {
    r > e.adult ? n("adult", t) : o("adult", t);
  }, value: e.adult }), /* @__PURE__ */ a.createElement(s, { label: "Crianças", helperText: "De 2 até 12 anos", max: m ? e.children : 8, onChange: (r) => {
    r > e.children ? n("children", t) : o("children", t);
  }, value: e.children }), /* @__PURE__ */ a.createElement(s, { label: "Bebês", helperText: "De 0 até 23 meses", max: m ? e.baby : e.adult ?? 1, onChange: (r) => {
    r > e.baby ? n("baby", t) : o("baby", t);
  }, value: e.baby }), (l == null ? void 0 : l.baby) && /* @__PURE__ */ a.createElement("div", { style: {
    paddingInline: 16,
    transform: "translateY(-60%)",
    textAlign: "end"
  } }, /* @__PURE__ */ a.createElement(c, { scale: 8, variant: "label", style: {
    color: d.colors.feedback.danger[600]
  } }, l.baby)), /* @__PURE__ */ a.createElement(c, { variant: "paragraph", scale: 3, color: "support.optional", weight: "regular", style: {
    margin: "0 16px 10px 16px"
  } }, "*Idade ao voltar da viagem"));
}
export {
  P as default
};
