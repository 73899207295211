import c, { useMemo as h, Fragment as G } from "react";
import { useFormMobile as q, InputGroup as A, InputDatePickerRange as z, gtm as H } from "@cvccorp-engines/components";
import { d as S } from "../../dayjs-CKf7tiKR.js";
import { PaxPackages as K } from "@cvccorp-engines/pax";
import { usePackageStore as Q } from "../../store/PackageStore/PackageStore.js";
import k from "../SinglePackage/SinglePackage.js";
import { LocationAndDateWrapper as U } from "./styled.js";
function V({
  max: y,
  min: I,
  dates: e = [],
  errors: a,
  origin: m,
  isMult: w = !1,
  destination: p,
  onReverse: o,
  onDateClear: B,
  onDateChange: P,
  onOriginChange: d,
  onDestinationChange: u
}) {
  const {
    openPax: L,
    pax: N,
    setOpenPax: b,
    clearPax: R,
    setPax: T
  } = Q(), {
    firstInputRef: W
  } = q(), _ = h(() => {
    var t, l;
    return {
      endDate: (t = e[1]) == null ? void 0 : t.toJSON(),
      startDate: (l = e[0]) == null ? void 0 : l.toJSON()
    };
  }, [e]), j = () => {
    var l, f, n, D, i, s;
    const t = {
      startDate: "",
      endDate: ""
    };
    return (l = a == null ? void 0 : a.calendarDates) != null && l.startDate && (t.startDate = "Preencha a data de ida"), (f = a == null ? void 0 : a.calendarDates) != null && f.endDate && (t.endDate = "Preencha a data de volta"), (n = a == null ? void 0 : a.calendarDates) != null && n.startDate && ((D = a == null ? void 0 : a.calendarDates) != null && D.endDate) && (t.startDate = "Preencha a data de ida", t.endDate = "Preencha a data de volta"), !((i = a == null ? void 0 : a.calendarDates) != null && i.startDate) && !((s = a == null ? void 0 : a.calendarDates) != null && s.endDate) && (t.startDate = "", t.endDate = ""), t;
  }, F = () => a != null && a.destination ? "Preencha o campo de destino" : "", J = () => a != null && a.origin ? "Preencha o campo de origem" : "", M = () => {
    H.pushEvent("pacote_home_engajamento", {
      category: "pacote_busca",
      action: "click-swap",
      label: `${m == null ? void 0 : m.iata} - ${p == null ? void 0 : p.iata}`
    }), o == null || o(p, m);
  }, $ = (t) => {
    if ((e == null ? void 0 : e.length) === 2) return !1;
    if (e[0]) {
      const l = S(e[0]), f = S(t), n = l.add(29, "day"), D = l.subtract(29, "day");
      return f.isAfter(n) || f.isBefore(D);
    }
    return !1;
  }, E = j(), v = J(), x = F();
  return /* @__PURE__ */ c.createElement(G, null, /* @__PURE__ */ c.createElement(A, { hasError: a.destination || a.origin, fullWidth: !0, onReverse: M, status: x || v ? "danger" : void 0, hideIcon: !0, hideClearIcon: !1 }, /* @__PURE__ */ c.createElement(k, { supportText: v, error: v, label: "Origem", placeholder: "De onde você vai sair?", value: m, onSelect: (t) => {
    d == null || d(t);
  }, onClear: () => {
    d == null || d(void 0);
  }, inputProps: {
    inputRef: W
  } }), /* @__PURE__ */ c.createElement(k, { supportText: x, error: x, value: p, placeholder: "Para onde vai?", onSelect: (t) => {
    u == null || u(t);
  }, onClear: () => {
    u == null || u(void 0);
  } })), /* @__PURE__ */ c.createElement(U, { isMult: w }, /* @__PURE__ */ c.createElement(A, { fullWidth: !0, status: E.startDate || E.endDate ? "danger" : void 0 }, /* @__PURE__ */ c.createElement(z, { value: e, max: y, min: I, showRangeCount: !0, error: E, onChange: (t) => {
    P == null || P(t);
  }, onClear: B, disableDates: $ })), /* @__PURE__ */ c.createElement(K, { infantAge: 0, open: L, error: a.pax, calendarDates: _, onConfirm: (t) => {
    T(t), b(!1);
  }, onClear: R, onToggle: b, value: N })));
}
V.displayName = "LocationAndDate";
export {
  V as default
};
