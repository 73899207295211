import React from "react";
import ReactDOM from "react-dom/client";
import type { ProductTypes } from "@cvccorp-engines/components";
import App from "./App";

// Função de embedding
const bootstrap = (rootId: string, product: ProductTypes = "packages") => {
    const loadedElement: HTMLElement = document.getElementById(rootId)!;
    const root = ReactDOM.createRoot(loadedElement);

    root.render(
        <React.StrictMode>
            <App product={product} />
        </React.StrictMode>,
    );
};

export default bootstrap;
