import u, { createContext as a, useMemo as c, useContext as f } from "react";
const l = {
  color: "primary"
}, t = {
  searchButton: l
}, o = a(t);
function g(r) {
  const {
    children: s,
    ...e
  } = r, n = C() ?? t, i = c(() => ({
    ...n,
    ...e
  }), [e, n]);
  return /* @__PURE__ */ u.createElement(o.Provider, { value: i }, s);
}
g.displayName = "EnginesConfigProvider";
function C() {
  return f(o);
}
export {
  g as default,
  C as useEnginesConfigContext
};
