import C from "react";
import { CalendarDatePicker as h } from "@cvccorp-engines/components";
import { u as g } from "../../../EngineBusingCharterContext-Dp5Np45L.js";
import p from "./useFetchCalendar.js";
const u = ({
  minDate: n,
  maxDate: r
}) => {
  var t;
  const {
    setCalendarDateSelected: o,
    calendarDateSelected: e,
    errors: a
  } = g(), {
    isLoading: l,
    options: i,
    initialDate: d,
    handleChangeCalendar: s
  } = p(), c = (t = a == null ? void 0 : a.dates) == null ? void 0 : t.startDate;
  return /* @__PURE__ */ C.createElement(h, { label: "Escolha a data", isLoading: l, options: i, onChange: (m) => o([new Date(m)]), calendarDateVisible: d, value: e.length ? e[0] : void 0, error: c ? "Preencha a data" : void 0, onChangeCalendar: s, min: n, max: r });
};
u.displayName = "CalendarPicker";
export {
  u as default
};
