import n from "./countPax.js";
function a(t) {
  const {
    description: o
  } = n(t);
  return o;
}
export {
  a as default
};
