import o from "../../libs/fetcher.js";
import i from "../../utils/QueryString.js";
const s = async (t) => {
  try {
    const {
      data: r
    } = await o.get("/api/flights/locations", {
      params: {
        query: i.text(t).toString()
      }
    });
    return r;
  } catch {
    throw new Error("Error in Locations");
  }
};
export {
  s as getFlightsLocations
};
