import e from "react";
import { DEFAULT_ROOM as P } from "../../constants/defaultValues.js";
import x from "../../utils/formatPaxPackages.js";
import g from "../Dropdown/Dropdown.js";
import C from "./PaxContent/PaxContent.js";
import d, { PaxConsumer as E } from "./PaxProvider/PaxProvider.js";
function k(m) {
  const {
    calendarDates: r,
    value: i = [P],
    error: n,
    open: l,
    onToggle: f,
    onConfirm: a,
    onClear: o,
    infantAge: c = 0,
    variant: p
  } = m;
  return /* @__PURE__ */ e.createElement(d, { rooms: i, endDate: r == null ? void 0 : r.endDate, infantAge: c, variant: p }, /* @__PURE__ */ e.createElement(E, null, ({
    rooms: t,
    onClearRooms: s,
    errors: u
  }) => /* @__PURE__ */ e.createElement(g, { open: l, onToggle: f, error: n, supportText: n ? "Preencha os ocupantes corretamente" : "", onClickOutside: () => {
    a(t);
  }, onConfirm: () => {
    u || a(t);
  }, onClear: () => {
    o == null || o(), s();
  }, label: x(t), renderContent: () => /* @__PURE__ */ e.createElement(C, null) })));
}
k.displayName = "PaxPackages";
export {
  k as default
};
