import styled from "@emotion/styled";

export const Container = styled.div`
    box-sizing: border-box;
    box-shadow: ${({ theme }) => theme.shadow.light[300]};
    z-index: ${({ theme }) => theme.zIndex.default};
    background: ${props => props.theme.colors.neutral.white};
    width: 100%;
    margin: 0px auto;
    border-radius: 16px;
    container-type: inline-size;

    padding: ${({ theme }) => theme.spacing.size[8]};

    @media (min-width: 600px) {
        position: relative;
    }

    @container (max-width: 899px) {
        padding-inline: ${({ theme }) => theme.spacing.size[5]};
        padding-block: ${({ theme }) => theme.spacing.size[5]};
    }

    .chui-engines-navigation {
        margin-bottom: ${({ theme }) => theme.spacing.size[8]};
    }
`;
