import t, { useCallback as ne, Fragment as L } from "react";
import { useFormMobile as G, SearchButton as re, FormContainer as x, OptionsBar as P } from "@cvccorp-engines/components";
import ie from "@cvccorp-components/chui-react-components/components/Button";
import oe from "@cvccorp-components/chui-react-components/components/Checkbox";
import ae from "@cvccorp-components/chui-react-components/components/Divider";
import N from "@cvccorp-components/chui-react-components/components/Grid";
import $ from "@cvccorp-components/chui-react-components/hooks/useMediaQuery";
import { _ as le, a as j, e as se, l as me } from "./PesqincFight-CZaFSiGf.js";
import { useTheme as ce } from "@emotion/react";
import { d as ue } from "./dayjs-CKf7tiKR.js";
import { useFlightStore as m } from "./store/FlightStore.js";
import { AddDestinationButtonWrapper as ge, MotorContainer as he, buttonContainer as de } from "./styled.js";
import fe from "./components/DefaultLocationForm/index.js";
import pe from "./context/EngineFlightContext.js";
import De from "./context/useEngineFlight.js";
import { FlightOptions as B } from "./store/FlightStore.types.js";
import Ee from "./hooks/useMergedMultDestination.js";
import ve from "./components/MultiDestinationsForm/MultiDestinationRow.js";
import { ButtonContainer as Ce } from "./components/MultiDestinationsForm/styles.js";
var ye = ["size", "className"], T = function(a) {
  var l, c, E = a.size, f = E === void 0 ? "medium" : E, p = a.className, C = p === void 0 ? "" : p, g = le(a, ye), y = g, r = ["chui-icon", C].join(" "), d = y.color || "#000014", v = j({}, g, {
    color: void 0
  }), F = se[f] || me[f];
  return /* @__PURE__ */ t.createElement("svg", j({
    xmlns: "http://www.w3.org/2000/svg",
    fill: d,
    viewBox: "0 0 24 24",
    width: (l = g.width) != null ? l : F,
    height: (c = g.height) != null ? c : F,
    className: r
  }, v), /* @__PURE__ */ t.createElement("path", {
    fill: void 0,
    d: "M11.47 21.77a.75.75 0 0 0 1.28-.55v-8.48h8.48a.75.75 0 1 0 0-1.5h-8.48V2.76a.75.75 0 1 0-1.5 0v8.48H2.77a.75.75 0 1 0 0 1.5h8.48v8.5c0 .199.079.39.22.53"
  }));
};
T.displayName = "FilledAdd";
function W({
  errors: i
}) {
  const {
    name: a
  } = ce(), l = Ee(), c = $("(max-width: 962px)"), E = $("(max-width: 720px)"), {
    multidestinations: f,
    addMultdestinations: p,
    calendarDates: C,
    removeMultdestinations: g,
    setCalendarDates: y,
    clearDates: r,
    setMultiDestination: d,
    setDestination: v,
    setOrigin: F,
    backToFirstOrigin: _,
    setBackToFirstOrigin: H,
    origin: b
  } = m(), {
    firstInputRef: K
  } = G(), Q = (e = !1) => /* @__PURE__ */ t.createElement(t.Fragment, null, e ? null : /* @__PURE__ */ t.createElement(t.Fragment, null, E && /* @__PURE__ */ t.createElement(ae, { color: "gray-light", size: "sm", style: {
    marginBlock: 24
  } }))), U = ne(g, [g]), Y = (e) => {
    var n;
    return e === -1 ? A(C) : A((n = f[e]) == null ? void 0 : n.calendarDates).sort((o, s) => o > s ? 0 : -1);
  };
  function q(e, n) {
    if (!e) return;
    const [o, s] = Array.isArray(e) ? e : [e];
    if (n === -1) return y({
      startDate: o,
      endDate: s
    });
    d(n, {
      calendarDates: {
        startDate: o,
        endDate: s
      }
    });
  }
  function O(e, n) {
    if (n === -1) return v(e);
    d(n, {
      destination: e
    });
  }
  function J(e, n) {
    if (n === -1) return F(e);
    d(n, {
      origin: e
    });
  }
  function X(e, n, o) {
    if (e === -1) {
      F(n), v(o);
      return;
    }
    d(e, {
      origin: n,
      destination: o
    });
  }
  function Z(e) {
    if (e === -1) return r();
  }
  function ee(e) {
    var n;
    return e === -1 ? i : (n = i == null ? void 0 : i.multidestinations) == null ? void 0 : n[e];
  }
  function te(e) {
    var u;
    const n = Object.entries(f ?? []);
    if (!(n != null && n.length)) return;
    const [o, s] = n.reverse()[0];
    e && !(s != null && s.destination) ? O(b, +o) : ((u = s.destination) == null ? void 0 : u.mastercode) === (b == null ? void 0 : b.mastercode) && O(void 0, +o), H(e);
  }
  const R = (e, n = () => !0, o = (u) => !1, s = !0) => {
    var w, M;
    if (!n()) return;
    let u;
    const k = s ? -1 : 1;
    let D = e + k;
    for (; o(D) && !u; )
      u = (M = (w = l[D]) == null ? void 0 : w.calendarDates) == null ? void 0 : M.startDate, D = D + k;
    return u;
  };
  return /* @__PURE__ */ t.createElement(L, null, /* @__PURE__ */ t.createElement(N, { container: !0, spacing: 0 }, /* @__PURE__ */ t.createElement(N, { item: !0, xs: 12 }, l.map((e, n, o) => {
    var k, D, w, M;
    const s = R(n, () => n > 0, (h) => h >= 0), u = R(n, () => n !== l.length - 1, (h) => h < l.length, !1);
    return /* @__PURE__ */ t.createElement(L, { key: (k = e.origin) == null ? void 0 : k.mastercode }, /* @__PURE__ */ t.createElement(N, { item: !0, xs: 12, style: {
      marginBlockEnd: 12
    }, key: [(D = e.calendarDates) == null ? void 0 : D.startDate, (w = e.destination) == null ? void 0 : w.mastercode, (M = e.origin) == null ? void 0 : M.mastercode].join("|") }, /* @__PURE__ */ t.createElement(ve, { index: n + 1, isLast: n === o.length - 1, destinationKey: e.key, hidePax: n !== o.length - 1, dates: Y(e.key), handleRemove: o.length > 1 ? U : void 0, errors: ee(e.key), onDateChange: (h) => q(h, e.key), onDateClear: () => Z(e.key), min: s ? new Date(s) : void 0, max: u ? new Date(u) : void 0, destination: e.destination, origin: e.origin, onDestinationChange: (h) => O(h, e.key), onOriginChange: (h) => J(h, e.key), onReverse: () => X(e.key, e.destination, e.origin), firstInputRef: n === 0 ? K : void 0 })), Q(n === o.length - 1));
  }), /* @__PURE__ */ t.createElement(N, { item: !0, xs: 12, style: {
    paddingBlockEnd: 16
  } }, /* @__PURE__ */ t.createElement(ge, null, /* @__PURE__ */ t.createElement(ie, { fullWidth: c, icon: /* @__PURE__ */ t.createElement(T, null), variant: "outline", color: a === "cvc" ? "secondary" : "primary", type: "button", onClick: () => {
    var e;
    p({
      origin: (e = l[l.length - 1]) == null ? void 0 : e.destination,
      destination: _ ? b : void 0
    });
  }, size: "md" }, "Adicionar voo"), /* @__PURE__ */ t.createElement(oe, { checked: _, onChange: te, label: "Voltar para a primeira origem", color: "primary", size: "md" })))), /* @__PURE__ */ t.createElement(Ce, { item: !0, xs: 12, justifyContent: "flex-end" }, /* @__PURE__ */ t.createElement(re, { "aria-label": "Buscar voo", style: c ? void 0 : {
    minWidth: 184
  } }))));
}
W.displayName = "MultiDestinationsForm";
function S() {
  const i = m(({
    origin: r
  }) => r), a = m(({
    setOrigin: r
  }) => r), l = m(({
    reverse: r
  }) => r), c = m(({
    destination: r
  }) => r), E = m(({
    setDestination: r
  }) => r), f = m(({
    calendarDates: r
  }) => r), p = m(({
    clearDates: r
  }) => r), C = m(({
    errors: r
  }) => r), g = m(({
    setCalendarDates: r
  }) => r), {
    firstInputRef: y
  } = G();
  return /* @__PURE__ */ t.createElement(he, null, /* @__PURE__ */ t.createElement(fe, { errors: C, origin: i, destination: c, onDestinationChange: E, onOriginChange: a, dates: A(f), onReverse: l, onDateClear: p, onDateChange: (r) => {
    if (!r) return p();
    const [d, v] = Array.isArray(r) ? r : [r];
    g({
      startDate: d,
      endDate: v
    });
  }, min: ue().add(1, "d").toDate(), firstInputRef: y }, /* @__PURE__ */ t.createElement(de, null, /* @__PURE__ */ t.createElement(x.Submit, null))));
}
S.displayName = "SingleDestinationForm";
const Fe = {
  multidestinations: W,
  oneway: S,
  roundtrip: S
};
function A(i) {
  const a = [];
  return i != null && i.startDate && a.push(new Date(i.startDate)), i != null && i.endDate && a.push(new Date(i.endDate)), a;
}
function I() {
  const i = m((c) => c.choice), a = m((c) => c.errors), l = Fe[i];
  return /* @__PURE__ */ t.createElement(l, { errors: a });
}
I.displayName = "EngineFlightForms";
const z = [{
  value: B.GOING_AND_BACK,
  label: "Ida e volta"
}, {
  value: B.ONLY_GOING,
  label: "Só ida"
}, {
  value: B.MULTIPLE_DESTINATION,
  label: "Vários destinos"
}], V = () => {
  const {
    handleSearch: i
  } = De(), {
    choice: a,
    setChoice: l
  } = m();
  return /* @__PURE__ */ t.createElement(x, { formId: "form-flight", onSearch: i, renderMobile: () => /* @__PURE__ */ t.createElement(x.Mobile, { title: "Passagens", label: "Destino" }, /* @__PURE__ */ t.createElement(P, { options: z, value: a, onChange: l }), /* @__PURE__ */ t.createElement(I, null)), renderDesktop: () => /* @__PURE__ */ t.createElement(t.Fragment, null, /* @__PURE__ */ t.createElement(P, { options: z, value: a, onChange: l }), /* @__PURE__ */ t.createElement(I, null)) });
};
V.displayName = "EngineFlightContent";
function be() {
  return /* @__PURE__ */ t.createElement(pe, null, /* @__PURE__ */ t.createElement(V, null));
}
be.displayName = "EngineFlight";
export {
  be as E,
  W as M,
  S,
  A as g
};
