import N from "react";
import { OptionsBar as I } from "@cvccorp-engines/components";
import { NAVIGATION_CONFIG as i, NAVIGATION_PRODUCTS as t, NAVIGATION_LINKS as o, useEngineNavigation as c } from "@cvccorp-engines/navigation";
const e = i.get(t.BUSING_CHARTER), a = i.get(t.BUSING), l = [{
  product: e.id,
  value: o[t.BUSING_CHARTER],
  label: e.label
}, {
  product: a.id,
  value: o[t.BUSING],
  label: a.label
}], s = () => {
  const {
    navigationType: r
  } = c();
  return /* @__PURE__ */ N.createElement(I, { variant: r, options: l, value: e.id, isChecked: (n) => n.product === e.id });
};
s.displayName = "ProductOptions";
export {
  s as default
};
