import at, { createContext as it, useState as U, useCallback as ot, useMemo as ut, useContext as ct } from "react";
import { useIsomorphicLayoutEffect as dt } from "@cvccorp-components/chui-react-components";
import { getParams as $, EngineQueryParams as g, useParsedLocalStorage as ft, LocalStorageKeys as lt, redirectToSearch as ht } from "@cvccorp-engines/components";
import { formatAgeToDate as mt, formatPaxForService as $t, DEFAULT_BUSING as gt } from "@cvccorp-engines/pax";
import Dt from "./utils/formatUrlToSearch.js";
var X = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function _t(S) {
  return S && S.__esModule && Object.prototype.hasOwnProperty.call(S, "default") ? S.default : S;
}
var tt = { exports: {} };
(function(S, K) {
  (function(H, _) {
    S.exports = _();
  })(X, function() {
    var H = 1e3, _ = 6e4, k = 36e5, d = "millisecond", p = "second", D = "minute", L = "hour", w = "day", j = "week", l = "month", R = "quarter", E = "year", N = "date", C = "Invalid Date", V = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/, W = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g, J = {
      name: "en",
      weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"),
      months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"),
      ordinal: function(t) {
        var e = ["th", "st", "nd", "rd"], r = t % 100;
        return "[" + t + (e[(r - 20) % 10] || e[r] || e[0]) + "]";
      }
    }, G = function(t, e, r) {
      var n = String(t);
      return !n || n.length >= e ? t : "" + Array(e + 1 - n.length).join(r) + t;
    }, Z = {
      s: G,
      z: function(t) {
        var e = -t.utcOffset(), r = Math.abs(e), n = Math.floor(r / 60), s = r % 60;
        return (e <= 0 ? "+" : "-") + G(n, 2, "0") + ":" + G(s, 2, "0");
      },
      m: function u(t, e) {
        if (t.date() < e.date()) return -u(e, t);
        var r = 12 * (e.year() - t.year()) + (e.month() - t.month()), n = t.clone().add(r, l), s = e - n < 0, a = t.clone().add(r + (s ? -1 : 1), l);
        return +(-(r + (e - n) / (s ? n - a : a - n)) || 0);
      },
      a: function(t) {
        return t < 0 ? Math.ceil(t) || 0 : Math.floor(t);
      },
      p: function(t) {
        return {
          M: l,
          y: E,
          w: j,
          d: w,
          D: N,
          h: L,
          m: D,
          s: p,
          ms: d,
          Q: R
        }[t] || String(t || "").toLowerCase().replace(/s$/, "");
      },
      u: function(t) {
        return t === void 0;
      }
    }, B = "en", i = {};
    i[B] = J;
    var M = "$isDayjsObject", v = function(t) {
      return t instanceof A || !(!t || !t[M]);
    }, b = function u(t, e, r) {
      var n;
      if (!t) return B;
      if (typeof t == "string") {
        var s = t.toLowerCase();
        i[s] && (n = s), e && (i[s] = e, n = s);
        var a = t.split("-");
        if (!n && a.length > 1) return u(a[0]);
      } else {
        var c = t.name;
        i[c] = t, n = c;
      }
      return !r && n && (B = n), n || !r && B;
    }, f = function(t, e) {
      if (v(t)) return t.clone();
      var r = typeof e == "object" ? e : {};
      return r.date = t, r.args = arguments, new A(r);
    }, o = Z;
    o.l = b, o.i = v, o.w = function(u, t) {
      return f(u, {
        locale: t.$L,
        utc: t.$u,
        x: t.$x,
        $offset: t.$offset
      });
    };
    var A = function() {
      function u(e) {
        this.$L = b(e.locale, null, !0), this.parse(e), this.$x = this.$x || e.x || {}, this[M] = !0;
      }
      var t = u.prototype;
      return t.parse = function(e) {
        this.$d = function(r) {
          var n = r.date, s = r.utc;
          if (n === null) return /* @__PURE__ */ new Date(NaN);
          if (o.u(n)) return /* @__PURE__ */ new Date();
          if (n instanceof Date) return new Date(n);
          if (typeof n == "string" && !/Z$/i.test(n)) {
            var a = n.match(V);
            if (a) {
              var c = a[2] - 1 || 0, h = (a[7] || "0").substring(0, 3);
              return s ? new Date(Date.UTC(a[1], c, a[3] || 1, a[4] || 0, a[5] || 0, a[6] || 0, h)) : new Date(a[1], c, a[3] || 1, a[4] || 0, a[5] || 0, a[6] || 0, h);
            }
          }
          return new Date(n);
        }(e), this.init();
      }, t.init = function() {
        var e = this.$d;
        this.$y = e.getFullYear(), this.$M = e.getMonth(), this.$D = e.getDate(), this.$W = e.getDay(), this.$H = e.getHours(), this.$m = e.getMinutes(), this.$s = e.getSeconds(), this.$ms = e.getMilliseconds();
      }, t.$utils = function() {
        return o;
      }, t.isValid = function() {
        return this.$d.toString() !== C;
      }, t.isSame = function(e, r) {
        var n = f(e);
        return this.startOf(r) <= n && n <= this.endOf(r);
      }, t.isAfter = function(e, r) {
        return f(e) < this.startOf(r);
      }, t.isBefore = function(e, r) {
        return this.endOf(r) < f(e);
      }, t.$g = function(e, r, n) {
        return o.u(e) ? this[r] : this.set(n, e);
      }, t.unix = function() {
        return Math.floor(this.valueOf() / 1e3);
      }, t.valueOf = function() {
        return this.$d.getTime();
      }, t.startOf = function(e, r) {
        var n = this, s = !!o.u(r) || r, a = o.p(e), c = function(T, O) {
          var Y = o.w(n.$u ? Date.UTC(n.$y, O, T) : new Date(n.$y, O, T), n);
          return s ? Y : Y.endOf(w);
        }, h = function(T, O) {
          return o.w(n.toDate()[T].apply(n.toDate("s"), (s ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice(O)), n);
        }, m = this.$W, y = this.$M, I = this.$D, x = "set" + (this.$u ? "UTC" : "");
        switch (a) {
          case E:
            return s ? c(1, 0) : c(31, 11);
          case l:
            return s ? c(1, y) : c(0, y + 1);
          case j:
            var P = this.$locale().weekStart || 0, F = (m < P ? m + 7 : m) - P;
            return c(s ? I - F : I + (6 - F), y);
          case w:
          case N:
            return h(x + "Hours", 0);
          case L:
            return h(x + "Minutes", 1);
          case D:
            return h(x + "Seconds", 2);
          case p:
            return h(x + "Milliseconds", 3);
          default:
            return this.clone();
        }
      }, t.endOf = function(e) {
        return this.startOf(e, !1);
      }, t.$set = function(e, r) {
        var n, s = o.p(e), a = "set" + (this.$u ? "UTC" : ""), c = (n = {}, n[w] = a + "Date", n[N] = a + "Date", n[l] = a + "Month", n[E] = a + "FullYear", n[L] = a + "Hours", n[D] = a + "Minutes", n[p] = a + "Seconds", n[d] = a + "Milliseconds", n)[s], h = s === w ? this.$D + (r - this.$W) : r;
        if (s === l || s === E) {
          var m = this.clone().set(N, 1);
          m.$d[c](h), m.init(), this.$d = m.set(N, Math.min(this.$D, m.daysInMonth())).$d;
        } else c && this.$d[c](h);
        return this.init(), this;
      }, t.set = function(e, r) {
        return this.clone().$set(e, r);
      }, t.get = function(e) {
        return this[o.p(e)]();
      }, t.add = function(e, r) {
        var n, s = this;
        e = Number(e);
        var a = o.p(r), c = function(I) {
          var x = f(s);
          return o.w(x.date(x.date() + Math.round(I * e)), s);
        };
        if (a === l) return this.set(l, this.$M + e);
        if (a === E) return this.set(E, this.$y + e);
        if (a === w) return c(1);
        if (a === j) return c(7);
        var h = (n = {}, n[D] = _, n[L] = k, n[p] = H, n)[a] || 1, m = this.$d.getTime() + e * h;
        return o.w(m, this);
      }, t.subtract = function(e, r) {
        return this.add(-1 * e, r);
      }, t.format = function(e) {
        var r = this, n = this.$locale();
        if (!this.isValid()) return n.invalidDate || C;
        var s = e || "YYYY-MM-DDTHH:mm:ssZ", a = o.z(this), c = this.$H, h = this.$m, m = this.$M, y = n.weekdays, I = n.months, x = n.meridiem, P = function(O, Y, z, st) {
          return O && (O[Y] || O(r, s)) || z[Y].slice(0, st);
        }, F = function(O) {
          return o.s(c % 12 || 12, O, "0");
        }, q = x || function(T, O, Y) {
          var z = T < 12 ? "AM" : "PM";
          return Y ? z.toLowerCase() : z;
        };
        return s.replace(W, function(T, O) {
          return O || function(Y) {
            switch (Y) {
              case "YY":
                return String(r.$y).slice(-2);
              case "YYYY":
                return o.s(r.$y, 4, "0");
              case "M":
                return m + 1;
              case "MM":
                return o.s(m + 1, 2, "0");
              case "MMM":
                return P(n.monthsShort, m, I, 3);
              case "MMMM":
                return P(I, m);
              case "D":
                return r.$D;
              case "DD":
                return o.s(r.$D, 2, "0");
              case "d":
                return String(r.$W);
              case "dd":
                return P(n.weekdaysMin, r.$W, y, 2);
              case "ddd":
                return P(n.weekdaysShort, r.$W, y, 3);
              case "dddd":
                return y[r.$W];
              case "H":
                return String(c);
              case "HH":
                return o.s(c, 2, "0");
              case "h":
                return F(1);
              case "hh":
                return F(2);
              case "a":
                return q(c, h, !0);
              case "A":
                return q(c, h, !1);
              case "m":
                return String(h);
              case "mm":
                return o.s(h, 2, "0");
              case "s":
                return String(r.$s);
              case "ss":
                return o.s(r.$s, 2, "0");
              case "SSS":
                return o.s(r.$ms, 3, "0");
              case "Z":
                return a;
            }
            return null;
          }(T) || a.replace(":", "");
        });
      }, t.utcOffset = function() {
        return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
      }, t.diff = function(e, r, n) {
        var s, a = this, c = o.p(r), h = f(e), m = (h.utcOffset() - this.utcOffset()) * _, y = this - h, I = function() {
          return o.m(a, h);
        };
        switch (c) {
          case E:
            s = I() / 12;
            break;
          case l:
            s = I();
            break;
          case R:
            s = I() / 3;
            break;
          case j:
            s = (y - m) / 6048e5;
            break;
          case w:
            s = (y - m) / 864e5;
            break;
          case L:
            s = y / k;
            break;
          case D:
            s = y / _;
            break;
          case p:
            s = y / H;
            break;
          default:
            s = y;
        }
        return n ? s : o.a(s);
      }, t.daysInMonth = function() {
        return this.endOf(l).$D;
      }, t.$locale = function() {
        return i[this.$L];
      }, t.locale = function(e, r) {
        if (!e) return this.$L;
        var n = this.clone(), s = b(e, r, !0);
        return s && (n.$L = s), n;
      }, t.clone = function() {
        return o.w(this.$d, this);
      }, t.toDate = function() {
        return new Date(this.valueOf());
      }, t.toJSON = function() {
        return this.isValid() ? this.toISOString() : null;
      }, t.toISOString = function() {
        return this.$d.toISOString();
      }, t.toString = function() {
        return this.$d.toUTCString();
      }, u;
    }(), Q = A.prototype;
    return f.prototype = Q, [["$ms", d], ["$s", p], ["$m", D], ["$H", L], ["$W", w], ["$M", l], ["$y", E], ["$D", N]].forEach(function(u) {
      Q[u[1]] = function(t) {
        return this.$g(t, u[0], u[1]);
      };
    }), f.extend = function(u, t) {
      return u.$i || (u(t, A, f), u.$i = !0), f;
    }, f.locale = b, f.isDayjs = v, f.unix = function(u) {
      return f(1e3 * u);
    }, f.en = i[B], f.Ls = i, f.p = {}, f;
  });
})(tt);
var et = tt.exports;
const nt = /* @__PURE__ */ _t(et);
var pt = { exports: {} };
(function(S, K) {
  (function(H, _) {
    S.exports = _(et);
  })(X, function(H) {
    function _(p) {
      return p && typeof p == "object" && "default" in p ? p : {
        default: p
      };
    }
    var k = _(H), d = {
      name: "pt-br",
      weekdays: "domingo_segunda-feira_terça-feira_quarta-feira_quinta-feira_sexta-feira_sábado".split("_"),
      weekdaysShort: "dom_seg_ter_qua_qui_sex_sáb".split("_"),
      weekdaysMin: "Do_2ª_3ª_4ª_5ª_6ª_Sá".split("_"),
      months: "janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro".split("_"),
      monthsShort: "jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez".split("_"),
      ordinal: function(D) {
        return D + "º";
      },
      formats: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D [de] MMMM [de] YYYY",
        LLL: "D [de] MMMM [de] YYYY [às] HH:mm",
        LLLL: "dddd, D [de] MMMM [de] YYYY [às] HH:mm"
      },
      relativeTime: {
        future: "em %s",
        past: "há %s",
        s: "poucos segundos",
        m: "um minuto",
        mm: "%d minutos",
        h: "uma hora",
        hh: "%d horas",
        d: "um dia",
        dd: "%d dias",
        M: "um mês",
        MM: "%d meses",
        y: "um ano",
        yy: "%d anos"
      }
    };
    return k.default.locale(d, null, !0), d;
  });
})(pt);
nt.locale("pt-br");
const rt = it(null), Mt = (S) => {
  const {
    children: K,
    isAtlas: H
  } = S, [_, k] = U(() => $("ForwardOnly") === "true" || !1), [d, p] = U(() => {
    const i = {
      origin: void 0,
      destination: void 0
    };
    return $(g.ORIGIN_ID) && (i.origin = {
      name: $(g.ORIGIN_DESC),
      id: $(g.ORIGIN_ID)
    }), $(g.DESTINATION_ID) && (i.destination = {
      name: $(g.DESTINATION_DESC),
      id: $(g.DESTINATION_ID)
    }), i;
  }), [D, L] = U(() => {
    let i = gt;
    return ($(g.ADULTS) || $(g.CHILDREN) || $(g.CHILDREN_AGES)) && (i = {
      adult: Number($(g.ADULTS)) || 1,
      children: Number($(g.CHILDREN)) || 0,
      childrenDateOfBirth: $(g.CHILDREN_AGES).length ? $(g.CHILDREN_AGES).split(",").map((M) => nt(String(mt(M))).toString()) : []
    }), i;
  }), [w, j] = U([]), [l, R] = U(() => {
    const i = [];
    return $(g.CHECKIN) && (i[0] = $(g.CHECKIN)), $(g.CHECKOUT) && (i[1] = $(g.CHECKOUT)), i;
  }), [E, N] = U({
    pesqinc: !1,
    pax: !1,
    dates: {
      startDate: !1,
      endDate: !1
    }
  }), [C, V] = ft(lt.LAST_SEARCH_BUSING);
  dt(() => {
    C && (W(C.origin, "ORIGIN"), W(C.destination, "DESTINATION"), L(C.pax), R(C.calendarDates), k(C.isOneWay));
  }, []);
  const W = (i, M) => {
    j([]), p((v) => ({
      ...v,
      [M.toLowerCase()]: i
    }));
  }, J = (i) => N((M) => ({
    ...M,
    ...i
  })), G = ot(() => {
    const i = [!1, {}], M = !(d != null && d.origin), v = !(d != null && d.destination), b = !$t([D]).length, f = !l.length, o = l.length !== 2;
    return _ ? (i[0] = [M, v, b, f].some((A) => A), i[1] = {
      pesqinc: M || v,
      pax: b,
      dates: {
        startDate: l.length < 1,
        endDate: !1
      }
    }) : (i[0] = [M, v, b, o].some((A) => A), i[1] = {
      pesqinc: M || v,
      pax: b,
      dates: {
        startDate: l.length < 1,
        endDate: l.length < 2
      }
    }), i;
  }, [_, d, D, l]), Z = () => {
    const [i, M] = G(), v = D.childrenDateOfBirth.length < D.children;
    if (N({
      ...M,
      pax: v
    }), i || v) return;
    const b = {
      origin: d == null ? void 0 : d.origin,
      destination: d == null ? void 0 : d.destination,
      pax: D,
      calendarDates: l,
      isOneWay: _
    }, f = Dt(b);
    V(b), ht(f);
  }, B = ut(() => ({
    pesqincLocationSelected: d,
    setPesqincLocationSelected: W,
    calendarDateSelected: l,
    setCalendarDateSelected: R,
    errors: E,
    setErrors: J,
    paxValue: D,
    setPaxValue: L,
    startDate: w,
    setStartDate: j,
    handleSearch: Z,
    isOneWay: _,
    setIsOneWay: k,
    isAtlas: H,
    persistedBusing: C
  }), [d, l, E, D, w, _, H, C]);
  return /* @__PURE__ */ at.createElement(rt.Provider, { value: B }, K);
};
Mt.displayName = "EngineBusingProvider";
function wt() {
  const S = ct(rt);
  if (!S) throw new Error("Chamada do hook useEngineBusingContext inválida! Certifique-se que ele esta sendo chamado dentro do contexto EngineBusingContext");
  return S;
}
export {
  Mt as E,
  rt as a,
  nt as d,
  wt as u
};
