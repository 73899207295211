import e from "react";
import { useResponsive as i } from "@cvccorp-components/chui-react-components";
import l from "./components/DefaultLocationForm/DefaultLocationForm.js";
import m from "./components/ProductOptions/ProductOptions.js";
import s from "./components/SearchOptions/SearchOptions.js";
import { E as a } from "./EngineBusingContext-aoE1hnqw.js";
import { OptionsContainer as c } from "./EngineBusing.styles.js";
const p = (t) => {
  const {
    isAtlas: n
  } = t, {
    isMobile: r,
    isTablet: o
  } = i();
  return /* @__PURE__ */ e.createElement(a, { isAtlas: n }, /* @__PURE__ */ e.createElement(c, null, /* @__PURE__ */ e.createElement(m, null), !o && !r ? /* @__PURE__ */ e.createElement("div", { className: "divider" }) : null, /* @__PURE__ */ e.createElement(s, null)), /* @__PURE__ */ e.createElement(l, null));
};
p.displayName = "EngineBusing";
export {
  p as default
};
