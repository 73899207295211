import { create as o } from "zustand";
var c = /* @__PURE__ */ ((t) => (t.PACKAGE = "/pacotes-turisticos", t.PACKAGE_FULL = "/pacotes-estatico", t))(c || {});
const r = o((t) => ({
  choice: "/pacotes-turisticos",
  setChoice: (e) => t(() => ({
    choice: e
  }))
}));
export {
  c as OptionPackagePackageFullStoreBarValue,
  r as usePackagePackageFullStoreStore
};
