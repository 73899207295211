import i from "@emotion/styled";
const o = i.div`
    position: relative;

    @container (min-width: 1024px) {
        display: flex;

        > div {
            width: 100%;
        }
    }
`;
export {
  o as Container
};
