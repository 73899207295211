import { NAVIGATION_PRODUCTS as A, NAVIGATION_CONFIG as I, NAVIGATION_LINKS as E } from "../../../constants/products.js";
const N = {
  [A.AIR]: E[A.AIR],
  [A.HOTEL]: E[A.HOTEL],
  [A.PACKAGES]: E[A.PACKAGES],
  [A.RESORTS]: E[A.RESORTS],
  [A.DESTINO]: E[A.DESTINO],
  [A.EXCHANGE]: E[A.EXCHANGE]
}, T = [A.AIR, A.HOTEL, A.PACKAGES, A.RESORTS, A.DESTINO, A.EXCHANGE].map((S) => ({
  ...I.get(S),
  href: N[S]
})).filter(Boolean);
export {
  N as PRODUCTS_LINK_SUBMARINO,
  T as defaultProductsSubmarino
};
