import u from "./calcAge.js";
function i(e) {
  return e != null && e.length ? e.reduce((l, t) => {
    const o = [];
    for (let r = 0; r < t.adult; r++)
      o.push(30);
    for (let r = 0; r < t.children; r++) {
      const n = u(t.childrenDateOfBirth[r]);
      o.push(n);
    }
    return l.push(o), l;
  }, []) : [];
}
export {
  i as default,
  i as formatPaxForService
};
