import i from "@emotion/styled";
const o = i.div`
    padding: 0px;
    display: flex;
    width: 100%;
    position: relative;
`;
export {
  o as Container
};
