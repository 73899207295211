function b(t, r) {
  return r ? "#14d264" : t ? "#ff7323" : "#a5a5b9";
}
function m(t, r) {
  return t != null && t.length ? t.reduce((e, {
    promotional: o,
    date: u,
    bestPriceWithTax: s
  }) => {
    const n = Math.ceil(s), i = r === n;
    if (e.some((d) => d.date === u)) return e;
    const f = b(o, i);
    return e.push({
      date: u,
      subtitle: new Intl.NumberFormat("pt-br").format(n),
      subtitleProps: {
        style: {
          fontWeight: 700,
          color: f
        }
      }
    }), e;
  }, []) : [];
}
export {
  m as default,
  b as determineColor
};
