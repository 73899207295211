import { memo } from "react";
import { ErrorProvider } from "@cvccorp-engines/components";
import EngineSearchProvider from "../context/EngineSearchProvider";
import EngineWrapper from "./EngineWrapper";
import { EngineProps } from "./EngineWrapper.types";

const Engine = (props: EngineProps) => {
    const { onError, renderNavigation } = props;
    return (
        <ErrorProvider onError={onError}>
            <EngineSearchProvider renderNavigation={renderNavigation}>
                <EngineWrapper />
            </EngineSearchProvider>
        </ErrorProvider>
    );
};

export default memo(Engine);
