import e from "react";
import { useIsomorphicLayoutEffect as N } from "@cvccorp-components/chui-react-components";
import { getCalendarDatesAsDate as I, FormContainer as t, OptionsBar as C } from "@cvccorp-engines/components";
import { d as J } from "../../dayjs-CKf7tiKR.js";
import "../../context/EngineHotelContext/EngineHotelContext.js";
import P from "../../context/EngineHotelContext/useEngineHotel.js";
import "../../context/EngineResortsContext/EngineResortsContext.js";
import { useEngineResortsContext as T } from "../../context/EngineResortsContext/useEngineResortsContext.js";
import "../../store/HotelStore/HotelStore.js";
import { useResortsStore as j } from "../../store/ResortsStore/ResortsStore.js";
import { useHotelsResortsStore as V, OptionHotelsResortsBarValue as S } from "../../store/HotelResortsStore/HotelsResortsStore.js";
import { choices as R } from "../EnginesUnified/EnginesUnified.js";
import b from "../LocationAndDate/LocationAndDate.js";
import g from "../ResortDefaultLocationForm/index.js";
import { Container as M, ButtonContainer as q, ResortMotorContainer as H, ResortButtonContainer as w } from "./Form.styles.js";
function z({
  hideChips: s = !1,
  product: o
}) {
  const {
    choice: n,
    setChoice: r
  } = V(), {
    handleSearch: k
  } = P(), {
    handleSearch: O
  } = T(), {
    destination: i,
    setDestination: m,
    calendarDates: F,
    setCalendarDates: v,
    errors: c,
    clearDates: d,
    setOpenPax: x
  } = j();
  N(() => {
    o && [S.HOTELS, S.RESORTS].includes(o) && r(o);
  }, []);
  const p = J().add(1, "d").toDate(), u = I(F), h = () => x(!0), D = (E) => {
    if (!E) return;
    const [a, l] = E;
    v({
      startDate: a == null ? void 0 : a.toJSON(),
      endDate: l == null ? void 0 : l.toJSON()
    });
  }, f = {
    hotel: {
      handleSearch: k,
      renderMobile: () => /* @__PURE__ */ e.createElement(M, null, /* @__PURE__ */ e.createElement(b, null)),
      renderDesktop: () => /* @__PURE__ */ e.createElement(M, null, /* @__PURE__ */ e.createElement(b, null), /* @__PURE__ */ e.createElement(q, null, /* @__PURE__ */ e.createElement(t.Submit, null))),
      title: "Hotel"
    },
    resorts: {
      handleSearch: O,
      renderMobile: () => /* @__PURE__ */ e.createElement(H, null, /* @__PURE__ */ e.createElement(g, { errors: c, destination: i, onDestinationChange: m, onOpenPax: h, dates: u, onDateClear: d, onDateChange: D, min: p })),
      renderDesktop: () => /* @__PURE__ */ e.createElement(H, null, /* @__PURE__ */ e.createElement(g, { errors: c, destination: i, onDestinationChange: m, onOpenPax: h, dates: u, onDateClear: d, onDateChange: D, min: p }, /* @__PURE__ */ e.createElement(w, null, /* @__PURE__ */ e.createElement(t.Submit, null)))),
      title: "Resort"
    }
  }, {
    handleSearch: B,
    renderMobile: L,
    renderDesktop: y,
    title: A
  } = f[n] ?? f.hotel;
  return /* @__PURE__ */ e.createElement(t, { formId: "form-hotel", onSearch: B, renderMobile: () => /* @__PURE__ */ e.createElement(t.Mobile, { title: A, label: "Destino" }, s ? void 0 : /* @__PURE__ */ e.createElement(C, { options: R, value: n, onChange: r }), L()), renderDesktop: () => /* @__PURE__ */ e.createElement(e.Fragment, null, s ? void 0 : /* @__PURE__ */ e.createElement(C, { options: R, value: n, onChange: r }), y()) });
}
z.displayName = "Form";
export {
  z as default
};
