import k, { createContext as E, useCallback as f, useMemo as F, useContext as v } from "react";
import { useIsomorphicLayoutEffect as M } from "@cvccorp-components/chui-react-components";
import { useParsedLocalStorage as L, LocalStorageKeys as A, gtm as T, redirectToSearch as y } from "@cvccorp-engines/components";
import { d as B } from "../../dayjs-CKf7tiKR.js";
import { usePackageFullStoreStore as w } from "../../store/EnginePackageFullStore/EnginePackageFullStore.js";
import { formatStateToGTM as R } from "../../utils/packageFullFormatStateToGTM.js";
import U from "../../utils/packageFullFormatUrlToSearch.js";
import p from "../../utils/packageFullValidateChildrenPaxDate.js";
const x = E(void 0);
function _(o) {
  const {
    setErrors: c,
    setErrorsPax: l,
    setCalendarDates: g,
    setDestination: P,
    setPax: S,
    setOrigin: h,
    ...e
  } = w(), [t, d] = L(A.LAST_SEARCH_PACKAGE_FULL);
  M(() => {
    t && !e.origin && (h(t.origin), P(t.destination), S(t.pax), B(/* @__PURE__ */ new Date()).isAfter(t.calendarDates) || g(t.calendarDates));
  }, []);
  const u = f(() => {
    let r = !1;
    const n = !e.pax || p(e.pax)[0], s = !e.destination, a = !e.origin, i = !e.calendarDates;
    return r = s || a || i || n, [r, {
      destination: s,
      origin: a,
      pax: n,
      calendarDates: {
        startDate: i
      }
    }];
  }, [e.calendarDates, e.destination, e.origin, e.pax]), m = f(() => {
    const r = e.calendarDates, [n, s] = u(), [a, i] = p(e.pax, r);
    if (c({
      ...s,
      pax: a
    }), l(i), n || a)
      return;
    const C = U(e);
    d({
      calendarDates: e.calendarDates,
      destination: e.destination,
      origin: e.origin,
      pax: e.pax
    }), T.onEngineSearch(R(e)), y(C);
  }, [e, c, l, u, d]), D = F(() => ({
    handleSearch: m
  }), [m]);
  return /* @__PURE__ */ k.createElement(x.Provider, { ...o, value: D });
}
_.displayName = "MotorPackageFullProvider";
function O() {
  const o = v(x);
  if (!o) throw new Error("Chamada do hook useMotorPackageFullContext inválida! Certifique-se que ele esta sendo chamado dentro do contexto MotorPackageFullContext");
  return o;
}
export {
  _ as default,
  O as useMotorPackageFullContext
};
