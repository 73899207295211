import n, { Children as s, isValidElement as C, cloneElement as c } from "react";
import { EnginesConfigProvider as p } from "@cvccorp-engines/provider";
import { Container as E, CollapsableContainer as f } from "./styled.js";
const r = () => {
}, g = (o) => {
  const {
    renderContentAfter: t = r,
    renderContentBefore: a = r,
    renderExpandedEngine: l,
    children: i,
    isExpanded: d
  } = o, m = s.map(i, (e) => C(e) ? c(e, {
    className: "engine-motor"
  }) : e);
  return /* @__PURE__ */ n.createElement(E, null, /* @__PURE__ */ n.createElement(f, null, a(), m, t()), /* @__PURE__ */ n.createElement(p, { searchButton: {
    color: "secondary"
  } }, d ? l() : void 0));
};
g.displayName = "CollapsableEngineContainer";
export {
  g as default
};
