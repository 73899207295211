import e, { useState as s } from "react";
import { SearchButton as m } from "@cvccorp-engines/components";
import { PaxPackages as c } from "@cvccorp-engines/pax";
import { u, d as f } from "../../EngineBusingCharterContext-Dp5Np45L.js";
import p from "../Calendar/Calendar.js";
import { P as x } from "../../Pesqinc-Dm95lmbS.js";
import { EngineContainer as P } from "./DefaultLocationForm.styles.js";
const E = () => {
  const {
    paxValue: r,
    setPaxValue: a,
    handleSearch: n,
    isExcursion: l
  } = u(), [o, t] = s(!1);
  return /* @__PURE__ */ e.createElement(P, { shortSize: l }, /* @__PURE__ */ e.createElement(x, null), /* @__PURE__ */ e.createElement(c, { open: o, value: r, onToggle: () => t(!o), onClear: () => a(f), onConfirm: (i) => {
    a(i), t(!1);
  } }), /* @__PURE__ */ e.createElement(p, null), /* @__PURE__ */ e.createElement(m, { onClick: n, "aria-label": "Buscar rodoviário exclusivo" }));
};
E.displayName = "DefaultLocationForm";
export {
  E as default
};
