import r, { useRef as k, useMemo as I } from "react";
import { useFormMobile as g, InputGroup as B, InputDatePickerRange as F } from "@cvccorp-engines/components";
import { d as i } from "../../dayjs-CKf7tiKR.js";
import { PaxPackages as J } from "@cvccorp-engines/pax";
import { useResortsStore as L } from "../../store/ResortsStore/ResortsStore.js";
import { resortsOptions as M } from "../../utils/resortOptions.js";
import N from "../SingleResorts/SingleResorts.js";
import { LocationAndDateWrapper as T } from "./styled.js";
function U({
  onDateChange: s,
  onDateClear: P,
  max: x = i(/* @__PURE__ */ new Date()).add(1.5, "year").toDate(),
  min: R,
  onDestinationChange: d,
  destination: E,
  dates: a = []
}) {
  const v = k(null), {
    setOpenPax: D,
    clearPax: b,
    pax: A,
    openPax: O,
    errors: e,
    setPax: S
  } = L(), {
    firstInputRef: h
  } = g(), w = I(() => {
    var t, n;
    return {
      endDate: (t = a[1]) == null ? void 0 : t.toJSON(),
      startDate: (n = a[0]) == null ? void 0 : n.toJSON()
    };
  }, [a]), y = (t) => {
    if ((a == null ? void 0 : a.length) === 2) return !1;
    if (a[0]) {
      const n = i(a[0]), o = i(t), l = n.add(29, "day"), c = n.subtract(29, "day");
      return o.isAfter(l) || o.isBefore(c);
    }
    return !1;
  }, u = () => {
    var n, o, l, c, p, m;
    const t = {
      startDate: "",
      endDate: ""
    };
    return (n = e == null ? void 0 : e.calendarDates) != null && n.startDate && (t.startDate = "Preencha a data de ida"), (o = e == null ? void 0 : e.calendarDates) != null && o.endDate && (t.endDate = "Preencha a data de volta"), (l = e == null ? void 0 : e.calendarDates) != null && l.startDate && ((c = e == null ? void 0 : e.calendarDates) != null && c.endDate) && (t.startDate = "Preencha a data de ida", t.endDate = "Preencha a data de volta"), !((p = e == null ? void 0 : e.calendarDates) != null && p.startDate) && !((m = e == null ? void 0 : e.calendarDates) != null && m.endDate) && (t.startDate = "", t.endDate = ""), t;
  }, f = () => e != null && e.destination ? "Preencha o campo de destino" : "";
  return /* @__PURE__ */ r.createElement(r.Fragment, null, /* @__PURE__ */ r.createElement(N, { label: "Destino", options: M, error: !!f(), value: E, onSelect: (t) => {
    d == null || d(t);
  }, inputProps: {
    inputRef: h,
    supportText: f()
  } }), /* @__PURE__ */ r.createElement(T, null, /* @__PURE__ */ r.createElement(B, { fullWidth: !0, status: u().startDate || u().endDate ? "danger" : void 0 }, /* @__PURE__ */ r.createElement(F, { value: a, inputOneProps: {
    inputRef: v
  }, max: x, min: R, showRangeCount: !0, disableDates: y, error: u(), onChange: (t) => {
    s == null || s(t);
  }, onClear: P })), /* @__PURE__ */ r.createElement(J, { open: O, value: A, error: e.pax, calendarDates: w, onConfirm: (t) => {
    S(t), D(!1);
  }, onClear: b, onToggle: D })));
}
export {
  U as default
};
