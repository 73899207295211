import s from "../../libs/fetcher.js";
import "../../libs/cookies.js";
const m = async ({
  searchString: o = "",
  qty: a = "200",
  locationId: t,
  searchV2: i,
  isAtlas: n
}) => {
  try {
    const {
      data: r
    } = await s.get(`/api/dynamic-busing/${i ? "v2" : "v1"}/cvc/road/locations`, {
      headers: {
        isAtlas: n
      },
      params: {
        ...o && {
          name: o,
          description: o
        },
        ...t && {
          id: t
        },
        quant: a
      }
    });
    return r;
  } catch {
    throw new Error("Error in Busing Locations");
  }
};
export {
  m as getBusingLocations
};
