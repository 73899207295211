import A, { createContext as S, useCallback as T, useMemo as M, useContext as P } from "react";
import { useIsomorphicLayoutEffect as N } from "@cvccorp-components/chui-react-components";
import { gtm as h, redirectToSearch as B, DateFormat as v } from "@cvccorp-engines/components";
import { d as s } from "../dayjs-CKf7tiKR.js";
import H from "../hooks/useTravelInsuranceSearchHistory/useTravelInsuranceSearchHistory.js";
import { useTravelInsuranceStore as O } from "../store/TravelInsuranceStore.js";
import { formatStateToGTM as R } from "../utils/formatStateToGTM.js";
import q from "../utils/formatUrlToSearch.js";
const g = S(void 0), x = (t) => ({
  startDate: s(t.startDate).format(v.YEAR_MONTH_DATE_TIMEZONE),
  endDate: s(t.endDate).format(v.YEAR_MONTH_DATE_TIMEZONE)
});
function w(t) {
  var m, f;
  const {
    setErrors: i,
    setPax: I,
    setDestination: C,
    setCalendarDates: _,
    ...e
  } = O(), {
    addValue: c,
    searchHistory: d
  } = H();
  N(() => {
    if (d.length && !e.destination) {
      const a = d[0];
      C(a.destination), I(a.pax), s(/* @__PURE__ */ new Date()).isAfter(a.calendarDates.startDate) || _(x(a.calendarDates));
    }
  }, []);
  const l = T(() => {
    var p, E;
    let a = !1;
    const n = !e.pax || e.pax.adult === 0 && e.pax.elderly === 0, o = !e.destination, r = !((p = e.calendarDates) != null && p.endDate), D = !((E = e.calendarDates) != null && E.startDate);
    return a = o || r || D || n, [a, {
      destination: o,
      pax: n,
      calendarDates: {
        endDate: r,
        startDate: D
      }
    }];
  }, [(m = e == null ? void 0 : e.calendarDates) == null ? void 0 : m.endDate, (f = e == null ? void 0 : e.calendarDates) == null ? void 0 : f.startDate, e.destination, e.pax]), u = T(() => {
    var r;
    const [a, n] = l();
    if (i({
      ...n
    }), a)
      return;
    h.pushEvent("resultado_busca_engajamento", {
      category: "Ingresso-Pesquisa-Vendedor",
      action: (r = e.destination) == null ? void 0 : r.description
    });
    const o = q(e).replaceAll("+,+", ", ").replaceAll("+", " ");
    c({
      pax: e.pax,
      calendarDates: x(e.calendarDates),
      destination: e.destination
    }), h.onEngineSearch(R(e)), B(o);
  }, [i, e, l, c]), y = M(() => ({
    handleSearch: u
  }), [u]);
  return /* @__PURE__ */ A.createElement(g.Provider, { ...t, value: y });
}
w.displayName = "EngineTravelInsuranceProvider";
function F() {
  const t = P(g);
  if (!t) throw new Error("Chamada do hook useEngineTravelInsuranceContext inválida! Certifique-se que ele esta sendo chamado dentro do contexto EngineTravelInsuranceProvider");
  return t;
}
export {
  w as default,
  F as useEngineTravelInsuranceContext
};
