import o from "../../libs/fetcher.js";
import a from "../../utils/QueryString.js";
const n = async (t) => {
  try {
    const {
      data: r
    } = await o.get("https://9rx5899jtb.execute-api.sa-east-1.amazonaws.com/b2b_prd/locations", {
      params: {
        productType: "ATT",
        q: a.text(t).toString()
      }
    });
    return r;
  } catch {
    throw new Error("Error in Locations");
  }
};
export {
  n as getTravelSecurityLocations
};
