import t, { useMemo as G } from "react";
import { useFormMobile as j, getCalendarDatesAsDate as q, InputGroup as w } from "@cvccorp-engines/components";
import { PaxPackages as z } from "@cvccorp-engines/pax";
import { usePackageFullStoreStore as E, defaultPackageFullStorePax as B } from "../../store/EnginePackageFullStore/EnginePackageFullStore.js";
import H from "../PackageFullCalendarPicker/CalendarPicker.js";
import S from "../PackageFullSingleAffreightment/SingleAffreightment.js";
import { LocationAndDateWrapper as K } from "./LocationAndDate.styles.js";
function $(h) {
  const {
    max: b,
    min: y,
    origin: r,
    destination: l,
    isMult: k = !1,
    onOriginChange: a,
    onDestinationChange: i
  } = h, {
    pax: A = B,
    errors: n,
    openPax: L,
    clearPax: O,
    setPax: F,
    setOpenPax: d
  } = E(), {
    firstInputRef: I
  } = j(), c = E((e) => q({
    startDate: e.calendarDates,
    endDate: ""
  })), M = G(() => {
    var e, o;
    return {
      endDate: (e = c[1]) == null ? void 0 : e.toJSON(),
      startDate: (o = c[0]) == null ? void 0 : o.toJSON()
    };
  }, [c]), R = () => n != null && n.destination ? "Preencha o campo de destino" : "", T = () => n != null && n.origin ? "Preencha o campo de origem" : "", C = (e) => {
    a == null || a(e);
  }, J = (e) => {
    i == null || i(e);
  };
  function p(e, o) {
    var f, g, D, P, u, x;
    return (f = e == null ? void 0 : e.city) != null && f.name ? ((D = (g = e == null ? void 0 : e.city) == null ? void 0 : g.state) == null ? void 0 : D.name) + ", " + ((x = (u = (P = e == null ? void 0 : e.city) == null ? void 0 : P.state) == null ? void 0 : u.country) == null ? void 0 : x.name) : (e == null ? void 0 : e.name) ?? o;
  }
  const N = p(r, "Origem"), W = p(l, "Destino"), s = T(), m = R();
  return /* @__PURE__ */ t.createElement(t.Fragment, null, /* @__PURE__ */ t.createElement(w, { fullWidth: !0, status: m || s ? "danger" : void 0 }, /* @__PURE__ */ t.createElement(S, { supportText: s, error: s, placeholder: "De onde você vai sair?", label: N, value: r, type: "origin", onSelect: C, inputProps: {
    inputRef: I
  } }), /* @__PURE__ */ t.createElement(S, { supportText: m, placeholder: "Para onde vai?", error: m, value: l, originId: r == null ? void 0 : r.id, type: "destination", onSelect: J, label: W, isDestination: !0 })), /* @__PURE__ */ t.createElement(K, { isMult: k }, /* @__PURE__ */ t.createElement(z, { infantAge: 1, variant: "package-full", value: A, error: n.pax, calendarDates: M, open: L, onToggle: d, onClear: O, onConfirm: (e) => {
    F(e), d(!1);
  } }), /* @__PURE__ */ t.createElement(H, { max: b, min: y })));
}
export {
  $ as default
};
