import o from "react";
import { InputGroup as t } from "@cvccorp-engines/components";
import { u as s } from "../../EngineBusingContext-aoE1hnqw.js";
import { EPesqincSearchType as a } from "./Pesqinc.types.js";
import i from "./SingleBusing/SingleBusing.js";
const n = {
  ORIGIN: "Preencha o campo de origem",
  DESTINATION: "Preencha o campo de destino"
}, m = () => {
  const {
    errors: r,
    pesqincLocationSelected: e
  } = s();
  return /* @__PURE__ */ o.createElement(t, { status: r.pesqinc ? "danger" : void 0 }, /* @__PURE__ */ o.createElement(i, { searchType: a.ORIGIN, label: "Origem", placeholder: "De onde vai sair?", error: r.pesqinc && !(e != null && e.origin) ? n.ORIGIN : void 0 }), /* @__PURE__ */ o.createElement(i, { searchType: a.DESTINATION, label: "Destino", placeholder: "Para onde vai?", error: r.pesqinc && !(e != null && e.destination) ? n.DESTINATION : void 0 }));
};
m.displayName = "PesqincFull";
export {
  m as default,
  n as errorByType
};
