import c, { createContext as z, useState as I, useMemo as T, useContext as F, useRef as b, useCallback as H, useEffect as y } from "react";
import j from "@cvccorp-components/chui-react-icons/icons/outline/system/OutlineArrowLeft";
import Y from "@cvccorp-components/chui-react-icons/icons/outline/system/OutlineArrowRight";
import { useTheme as M } from "@emotion/react";
import { NAVIGATION_PRODUCTS as l, NAVIGATION_CONFIG as q, NAVIGATION_LINKS as J } from "./constants/products.js";
import { useWindow as Q, useIsomorphicLayoutEffect as G } from "@cvccorp-components/chui-react-components";
import * as Z from "@cvccorp-components/chui-react-icons/icons";
import { Container as ee, Nav as te, Button as B, Hidden as K, NavigationButton as re, NavigationLink as ne, ProductWrapper as oe } from "./components/Navigation/Navigation.styles.js";
import { PRODUCTS_LINK_CVC as ce, defaultProductsCVC as se } from "./components/Navigation/products/cvc.js";
import { defaultProductsSubmarino as le } from "./components/Navigation/products/submarino.js";
const W = z({}), U = (n) => n === "submarino" ? l.AIR : l.PACKAGES, $ = (n) => {
  if (n)
    return typeof n == "string" ? Z[n] : n;
}, ae = ({
  children: n,
  product: m,
  navigationItems: h,
  navigationType: C = "link"
}) => {
  const {
    name: f
  } = M(), d = Q(), [k, A] = I(""), [w, p] = I(m || U(f));
  G(() => {
    const i = d.location.pathname;
    A(i);
  }, [d]), G(() => {
    const {
      product: i
    } = v(m);
    p(i);
  }, [C, m]);
  const g = T(() => {
    const E = f === "cvc" ? se : le;
    return (h != null && h.length ? h : E).map((o) => {
      const a = q.get(o.id);
      return a ? {
        href: o.href ?? J[o.id],
        icon: $(o.icon ?? a.icon),
        id: a.id,
        label: o.label ?? a.label
      } : {
        ...o,
        icon: $(o.icon)
      };
    }).filter((o) => o.href && o.label);
  }, [f, h]);
  function v(i) {
    const E = i || U(f);
    let N = !1, o = E;
    if (C === "link") {
      const a = new URLSearchParams(d.location.search).get("context"), {
        pathname: P
      } = d.location;
      A(P);
      const S = P.startsWith("/p/") ? P.replace(/^\/p/, "") : P, s = g.find((O) => S === O.href);
      if (a === l.RESORTS && (s == null ? void 0 : s.id) === l.HOTEL)
        return {
          checked: !0,
          product: l.RESORTS
        };
      if (S.includes(ce[l.PACKAGE_FULL])) return {
        checked: !0,
        product: l.PACKAGE_COMPLETE
      };
      if ([l.CRU, l.DISNEY].includes(s == null ? void 0 : s.id))
        return {
          checked: !0,
          product: E
        };
      o = (s == null ? void 0 : s.id) ?? E, N = !!s;
    }
    return {
      product: o,
      checked: N
    };
  }
  const L = T(() => ({
    pathname: k,
    currentProduct: w,
    products: g,
    setCurrentProduct: p,
    navigationType: C
  }), [k, w, g, p, C]);
  return /* @__PURE__ */ c.createElement(W.Provider, { value: L }, n);
};
ae.displayName = "EngineNavigationProvider";
const ie = () => {
  const n = F(W);
  if (!n.setCurrentProduct) throw new Error("useEngineNavigation should be used as a child of EngineNavigationProvider");
  return n;
}, ue = [l.PACKAGE_COMPLETE, l.PACKAGES], me = 120, fe = -120, V = ({
  icon: n,
  label: m
}) => /* @__PURE__ */ c.createElement(oe, null, n ? /* @__PURE__ */ c.createElement(n, { scale: "md" }) : void 0, m);
V.displayName = "ProcuctWrapper";
function de(n) {
  const {
    onClick: m,
    className: h = "",
    ...C
  } = n, {
    currentProduct: f,
    products: d,
    navigationType: k
  } = ie(), [A, w] = I(!1), [p, g] = I(!1), {
    name: v = "cvc"
  } = M(), L = b(/* @__PURE__ */ new Map()), i = b(!1), E = b(null), N = b(null), o = b(null), a = b(null), P = H((e, ...t) => {
    if (!(t != null && t.length)) return;
    const r = new IntersectionObserver((u) => {
      u.forEach((R) => {
        e(R.isIntersecting, R.target);
      });
    }, {
      root: document.body,
      threshold: 1
    });
    return t.forEach((u) => u && r.observe(u)), r;
  }, []), S = (e) => {
    var t, r, u;
    (u = (t = a.current) == null ? void 0 : t.scroll) == null || u.call(t, {
      left: ((r = a.current) == null ? void 0 : r.scrollLeft) + e,
      behavior: "smooth"
    });
  }, s = (e) => {
    var t, r;
    (r = (t = L.current.get(e)) == null ? void 0 : t.scrollIntoView) == null || r.call(t, {
      block: "center",
      inline: "center",
      behavior: "smooth"
    });
  };
  y(() => {
    const e = P((t, r) => {
      r.classList.contains("last") ? w(!t) : g(!t);
    }, o.current, N.current);
    return () => {
      e == null || e.disconnect();
    };
  }, [P]), y(() => {
    var e, t;
    i.current || (i.current = !0, (t = (e = E.current) == null ? void 0 : e.scrollIntoView) == null || t.call(e, {
      inline: "center",
      block: "center"
    }));
  }, []);
  const _ = T(() => {
    const e = d.find(({
      id: t
    }) => t && (f === t && !!t || ue.some((r) => f === r)));
    return (e == null ? void 0 : e.label) ?? "";
  }, [d, f]), O = (e) => e.startsWith("/"), D = (e, t) => {
    const r = e.id, u = _ === e.label, R = `${v}-${e.label + r}`;
    return /* @__PURE__ */ c.createElement(re, { key: R, ref: (x) => (L.current.set(r, x), x), onClick: () => {
      s(r), t(r);
    }, underline: u, id: R, type: "button" }, /* @__PURE__ */ c.createElement(V, { label: e.label, icon: e.icon }));
  }, X = (e) => {
    const t = _ === e.label;
    return /* @__PURE__ */ c.createElement(ne, { key: `${v}-${e.label}`, underline: t, id: `${v}-${e.label}`, href: e.href, target: O(e.href ?? "") ? void 0 : "_blank", "aria-current": t ? "page" : void 0, rel: "noreferrer" }, /* @__PURE__ */ c.createElement(V, { icon: e.icon, label: e.label }));
  };
  return /* @__PURE__ */ c.createElement(ee, { className: `${h} chui-engines-navigation`, ...C }, /* @__PURE__ */ c.createElement(te, { ref: a }, /* @__PURE__ */ c.createElement(B, { left: 0, show: p, onClick: () => S(fe), icon: /* @__PURE__ */ c.createElement(j, null), size: "sm", color: "white", shadow: !0 }), /* @__PURE__ */ c.createElement(K, { className: "first", ref: o }), d.map((e) => k === "button" && m && O(e.href ?? "") ? D(e, m) : X(e)), /* @__PURE__ */ c.createElement(K, { className: "last", ref: N }), /* @__PURE__ */ c.createElement(B, { show: A, onClick: () => S(me), right: 0, icon: /* @__PURE__ */ c.createElement(Y, null), size: "sm", color: "white", shadow: !0 })));
}
de.displayName = "Navigation";
export {
  ae as E,
  de as N,
  U as g,
  ie as u
};
