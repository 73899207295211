import t from "@emotion/styled";
const o = t.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`, n = t.div(({
  theme: e
}) => `
    display: flex;
    justify-content: center;
    gap: 4px;
    @media (max-width: ${e.breakpoints.layoutGridSm}) {
        flex-direction: column;
    }

    & fieldset {
        height: fit-content;
    }
`);
export {
  n as DateWrapper,
  o as LocationWrapper
};
