"use client";
import e from "react";
import { DEFAULT_BUSING as u } from "../../constants/defaultValues.js";
import f from "../../utils/formatPaxBusing.js";
import p from "../Dropdown/Dropdown.js";
import c from "./PaxContent.js";
import x, { PaxConsumer as P } from "./PaxProvider/PaxProvider.js";
function g(a) {
  const {
    open: m,
    value: i = u,
    error: o,
    onToggle: l,
    onConfirm: r,
    onClear: n
  } = a;
  return /* @__PURE__ */ e.createElement(x, { travelers: i, error: o }, /* @__PURE__ */ e.createElement(P, null, ({
    onClearTraveler: s,
    travelers: t
  }) => /* @__PURE__ */ e.createElement(p, { label: f(t), error: o, supportText: o ? "Preencha quantos passageiros" : "", onToggle: l, onClickOutside: () => {
    r(t);
  }, onClear: () => {
    n == null || n(), s();
  }, onConfirm: () => {
    r(t);
  }, renderContent: () => /* @__PURE__ */ e.createElement(c, null), open: m })));
}
g.displayName = "PaxBusing";
export {
  g as default
};
