import e from "react";
import { FormContainer as n } from "@cvccorp-engines/components";
import { d } from "../../dayjs-CKf7tiKR.js";
import { useMotorTicketsContext as p } from "../../contexts/EngineTicketsContext.js";
import { useTicketsStore as C } from "../../store/TicketsStore.js";
import c from "../DefaultLocationForm/index.js";
import { MotorContainer as D, buttonContainer as g } from "./styled.js";
function E() {
  const {
    handleSearch: u
  } = p(), {
    destination: o,
    setDestination: r,
    date: f,
    setDate: a,
    errors: i,
    clearDate: m
  } = C(), l = d().add(1, "d").toDate(), s = new Date(f ?? "");
  return /* @__PURE__ */ e.createElement(n, { formId: "form-ticket", onSearch: u, renderMobile: () => /* @__PURE__ */ e.createElement(n.Mobile, { title: "Ingresso", label: "Destino" }, /* @__PURE__ */ e.createElement(D, { twoItems: !0 }, /* @__PURE__ */ e.createElement(c, { errors: i, destination: o, onDestinationChange: r, date: s, onDateChange: (t) => {
    t && a(t.toString());
  }, onDateClear: m, min: l }))), renderDesktop: () => /* @__PURE__ */ e.createElement(D, { twoItems: !0 }, /* @__PURE__ */ e.createElement(c, { errors: i, destination: o, onDestinationChange: r, date: s, onDateChange: (t) => {
    t && a(t.toString());
  }, onDateClear: m, min: l }, /* @__PURE__ */ e.createElement(g, null, /* @__PURE__ */ e.createElement(n.Submit, null)))) });
}
E.displayName = "SingleDestinationForm";
export {
  E as default
};
