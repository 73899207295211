import e from "react";
import { DatePickerDivider as r } from "@cvccorp-components/chui-react-components";
import t from "@cvccorp-components/chui-react-components/components/Grid";
import o from "@cvccorp-components/chui-react-components/components/Spinner";
import { Wrapper as m, PriceLegendContainer as n } from "./CalendarDatePickerContent.styles.js";
function i(a) {
  const {
    isLoading: l
  } = a;
  return l ? /* @__PURE__ */ e.createElement(t, { container: !0, alignItems: "center" }, /* @__PURE__ */ e.createElement(t, { item: !0 }, /* @__PURE__ */ e.createElement(o, { color: "secondary" })), /* @__PURE__ */ e.createElement(t, { item: !0 }, "Carregando preços e datas disponíveis")) : /* @__PURE__ */ e.createElement(m, null, /* @__PURE__ */ e.createElement(n, null, /* @__PURE__ */ e.createElement("span", null, "Preço normal"), /* @__PURE__ */ e.createElement(r, { variant: "regular" })), /* @__PURE__ */ e.createElement(n, null, /* @__PURE__ */ e.createElement("span", null, "Menor Preço"), /* @__PURE__ */ e.createElement(r, { variant: "good" })), /* @__PURE__ */ e.createElement(n, null, /* @__PURE__ */ e.createElement("span", null, "Preço promocional"), /* @__PURE__ */ e.createElement(r, { variant: "bad" })));
}
i.displayName = "CalendarPickerContent";
export {
  i as default
};
