import r, { useMemo as P, Fragment as J } from "react";
import { getCalendarDatesAsDate as L, InputGroup as b, InputDatePickerRange as I } from "@cvccorp-engines/components";
import { d as c } from "../../dayjs-CKf7tiKR.js";
import { PaxPackages as R } from "@cvccorp-engines/pax";
import { useHotelStore as k } from "../../store/HotelStore/HotelStore.js";
import T from "../Pesqinc/Pesqinc.js";
import { LocationAndDateWrapper as W } from "./LocationAndDate.styles.js";
const j = c().add(1, "d").toDate(), q = c(/* @__PURE__ */ new Date()).add(1.5, "year").toDate();
function B() {
  const {
    pax: h,
    openPax: x,
    destination: g,
    calendarDates: D,
    errors: e,
    setPax: E,
    clearPax: C,
    setOpenPax: i,
    clearDates: v,
    setCalendarDates: A,
    setDestination: m
  } = k(), n = P(() => L(D) || [], [D]), S = (t) => {
    if (!t) return;
    const [a, o] = t;
    A({
      startDate: a == null ? void 0 : a.toJSON(),
      endDate: o == null ? void 0 : o.toJSON()
    });
  }, y = P(() => {
    var t, a;
    return {
      endDate: (t = n[1]) == null ? void 0 : t.toJSON(),
      startDate: (a = n[0]) == null ? void 0 : a.toJSON()
    };
  }, [n]), N = () => {
    var a, o, d, s, f, p;
    const t = {
      startDate: "",
      endDate: ""
    };
    return (a = e == null ? void 0 : e.calendarDates) != null && a.startDate && (t.startDate = "Preencha a data de ida"), (o = e == null ? void 0 : e.calendarDates) != null && o.endDate && (t.endDate = "Preencha a data de volta"), (d = e == null ? void 0 : e.calendarDates) != null && d.startDate && ((s = e == null ? void 0 : e.calendarDates) != null && s.endDate) && (t.startDate = "Preencha a data de ida", t.endDate = "Preencha a data de volta"), !((f = e == null ? void 0 : e.calendarDates) != null && f.startDate) && !((p = e == null ? void 0 : e.calendarDates) != null && p.endDate) && (t.startDate = "", t.endDate = ""), t;
  }, O = (t) => {
    if ((n == null ? void 0 : n.length) === 2) return !1;
    if (n[0]) {
      const a = c(n[0]), o = c(t), d = a.add(29, "day"), s = a.subtract(29, "day");
      return o.isAfter(d) || o.isBefore(s);
    }
    return !1;
  }, w = () => e != null && e.destination ? "Preencha o campo de destino" : "", l = N(), u = w();
  return /* @__PURE__ */ r.createElement(J, null, /* @__PURE__ */ r.createElement(T, { supportText: u, error: u, value: g, onSelect: (t) => {
    m(t);
  }, onClear: () => m(void 0) }), /* @__PURE__ */ r.createElement(W, null, /* @__PURE__ */ r.createElement(b, { fullWidth: !0, status: l.startDate || l.endDate ? "danger" : void 0 }, /* @__PURE__ */ r.createElement(I, { value: n, disableDates: O, max: q, min: j, showRangeCount: !0, error: l, onChange: S, onClear: v })), /* @__PURE__ */ r.createElement(R, { open: x, value: h, error: e.pax, calendarDates: y, onConfirm: (t) => {
    E(t), i(!1);
  }, onClear: C, onToggle: i })));
}
B.displayName = "LocationAndDate";
export {
  B as default
};
