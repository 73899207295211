import p, { useState as j, useEffect as x, useCallback as B, useMemo as A } from "react";
import E from "@cvccorp-components/chui-react-components/components/Autocomplete";
import C from "@cvccorp-components/chui-react-components/hooks/useResponsive";
import { r as R } from "../../OutlinePinMap-DS9UaHFY.js";
import { getAffreightmentLocations as D } from "@cvccorp-engines/api";
import { getParams as L, useError as I } from "@cvccorp-engines/components";
import { getAffreightmentParamsForLogs as U } from "../../utils/packageFullGetAffreightmentParams.js";
function g(l) {
  return (l == null ? void 0 : l.name) ?? "";
}
function K(l) {
  const {
    error: N,
    value: a,
    supportText: k,
    supportTextProps: b,
    inputProps: T,
    placeholder: W,
    label: F,
    originId: i,
    type: v,
    onSelect: m
  } = l, [O, h] = j([]), f = v === "origin", d = v === "destination";
  function y(e) {
    if (!(e != null && e.city)) return "";
    const {
      city: {
        name: t,
        state: {
          shortName: n,
          country: {
            name: r
          }
        }
      }
    } = e;
    return t && n && r ? t + " - " + n + ", " + r : "";
  }
  x(() => {
    async function e() {
      const t = await D(void 0, {
        isDestination: d,
        isOrigin: f
      });
      if ((t == null ? void 0 : t.length) > 0) {
        const n = t == null ? void 0 : t.map((r) => ({
          label: g(r),
          value: r
        }));
        h(n);
      }
    }
    e();
  }, []);
  const w = L("packageGroup") ? String(L("packageGroup")).toUpperCase() : "STANDALONE", {
    throwError: s
  } = I(), P = B(async (e) => {
    const t = U(), n = {
      operation: "location",
      productType: "charter",
      packageGroup: w,
      ...t
    }, r = (/* @__PURE__ */ new Date()).getTime();
    try {
      const o = await D(e, {
        isDestination: d,
        isOrigin: f,
        origin: i
      });
      if ((o == null ? void 0 : o.length) > 0) {
        const c = o == null ? void 0 : o.map((u) => ({
          label: g(u),
          value: u
        }));
        h(c);
      } else {
        const u = (/* @__PURE__ */ new Date()).getTime() - r;
        return Object.assign(n, {
          duration: u,
          _message: `EMPTYRESULT ${e}`
        }), s == null || s(n), [];
      }
    } catch {
      const c = (/* @__PURE__ */ new Date()).getTime() - r;
      return Object.assign(n, {
        duration: c,
        _message: `ERROR ${e}`
      }), s == null || s(n), [];
    }
  }, [d, f, i, w, s]), G = A(() => {
    const e = O;
    return a && !e.some((t) => t.value.id === a.id) && e.push({
      value: a,
      label: g(a)
    }), e;
  }, [O, a]);
  x(() => {
    i && P();
  }, [i]);
  const {
    isMobile: S
  } = C(), M = A(() => S ? {
    inputProps: {
      supportTextProps: b,
      fullWidth: !0,
      ...T
    },
    filterOption: () => !0,
    dropdownAttributes: {
      style: {
        width: 530,
        overflow: "hidden",
        textOverflow: "ellipsis"
      }
    },
    renderOption: ({
      option: e
    }) => {
      var t;
      return /* @__PURE__ */ p.createElement(E.Option, { fullWidth: !0, label: (t = e.value) == null ? void 0 : t.name, supportText: y(e.value), onSelect: () => m(e.value), addonBefore: /* @__PURE__ */ p.createElement(R, null), style: {
        display: "flex"
      } });
    }
  } : {
    supportTextProps: b,
    renderOption: ({
      option: e
    }) => {
      var t;
      return /* @__PURE__ */ p.createElement(E.Option, { fullWidth: !0, label: (t = e.value) == null ? void 0 : t.name, supportText: y(e.value), onSelect: () => m(e.value), addonBefore: /* @__PURE__ */ p.createElement(R, null) });
    },
    inputProps: T
  }, [S]);
  return {
    label: F,
    placeholder: W,
    supportText: k,
    onSelect: (e) => {
      m(e);
    },
    options: G,
    value: a,
    error: N,
    ...M,
    onSearch: P
  };
}
export {
  K as default
};
