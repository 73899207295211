import n from "react";
import { EngineProductsName as s, OptionsBar as r } from "@cvccorp-engines/components";
import { useEngineNavigation as p } from "@cvccorp-engines/navigation";
import { usePackagePackageFullStoreStore as u } from "../../store/PackagePackageFullStore/PackagePackageFullStore.js";
const l = {
  product: "packages",
  value: "/pacotes-turisticos",
  label: "Aéreo + Hotel"
}, g = {
  product: "package-complete",
  value: "/pacotes-estatico",
  label: s.PACKAGE_FULL
}, m = [l, g], C = ({
  product: t
}) => {
  const {
    choice: a,
    setChoice: i
  } = u(), {
    navigationType: o
  } = p(), e = o === "button";
  return /* @__PURE__ */ n.createElement(r, { variant: o, options: m, value: e ? a : t, isChecked: e ? void 0 : (c) => c.product === t, onChange: i });
};
C.displayName = "NavigationChips";
export {
  l as CHOICE_PACKAGE,
  g as CHOICE_PACKAGE_FULL,
  m as choices,
  C as default
};
