import type { ProductTypes } from "@cvccorp-engines/components";
import bootstrap from "./bootstrap";
import "./styles.css";
interface CustomWindow extends Window {
    loadWidget(rootId: string, product: ProductTypes, hide?: ProductTypes[]): void;
    rootComponent: void;
}

(window as unknown as CustomWindow).loadWidget = bootstrap;

if (document.getElementById("embedded_cms_motor")) {
    bootstrap("embedded_cms_motor", "packages");
}
