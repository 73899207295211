import u from "react";
import S from "@cvccorp-components/chui-react-components/components/Select";
import { getCalendarMonths as v } from "@cvccorp-engines/api";
import { useFetch as b, useAutoCompleteItemSize as h, InputLoadingOverlay as N } from "@cvccorp-engines/components";
import { Container as g } from "./SelectedDate.styles.js";
const m = (t, e) => {
  if (!t) return !1;
  const [o, l] = t.split("-"), [n, s] = e.value.split("-");
  return Number(o) === Number(n) && Number(l) === Number(s);
}, y = (t) => {
  const {
    value: e,
    onSelect: o,
    inputProps: l,
    error: n = "",
    label: s,
    destinations: p
  } = t, r = p.map(({
    zoneId: i
  }) => i).join(","), {
    data: c,
    isLoading: a
  } = b(() => v(r), [r], {
    enabled: !!r,
    throttle: 500,
    onSuccess: (i) => {
      i && e && !i.some((f) => m(e, f)) && o(void 0);
    }
  }), d = h();
  return /* @__PURE__ */ u.createElement(g, null, /* @__PURE__ */ u.createElement(S, { open: a ? !1 : void 0, placeholder: "Selecione o mês", isOptionSelected: m, label: s, value: e, status: n ? "danger" : void 0, options: r ? c : [], onSelect: o, itemSize: d, disabled: a, inputProps: {
    ...l,
    fullWidth: !0,
    size: "md"
  }, supportText: n }), a ? /* @__PURE__ */ u.createElement(N, null) : void 0);
};
y.displayName = "SelectDate";
export {
  y as default
};
