import l from "react";
import { Pesqinc as m } from "@cvccorp-engines/components";
import { u } from "../../../EngineBusingCharterContext-Dp5Np45L.js";
import g from "./useFetchSingleBusingCharter.js";
const p = ({
  searchType: e,
  isUniqueDirection: t,
  ...n
}) => {
  const {
    pesqincLocationSelected: o,
    setPesqincLocationSelected: r
  } = u(), {
    options: i,
    onSearch: s,
    renderOption: a,
    onSelect: c
  } = g({
    searchType: e,
    isUniqueDirection: t,
    onSelect: r
  });
  return /* @__PURE__ */ l.createElement(m, { ...n, onSearch: s, options: i, renderOption: a, onSelect: c, value: o[e.toLowerCase()] });
};
p.displayName = "SingleBusingCharter";
export {
  p as default
};
