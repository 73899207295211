import { Grid as p } from "@cvccorp-components/chui-react-components";
import i from "@emotion/styled";
const n = i(p)`
    gap: 32px;

    @media (max-width: 600px) {
        gap: 12px;
    }
`, o = i.div`
    padding: 16px 0;
`, r = i.div`
    display: flex;
    gap: 16px;
`;
export {
  r as ChipsContainer,
  o as EngineContainer,
  n as Grid
};
