import { memo } from "react";
import { ChuiThemeProvider } from "@cvccorp-components/chui-react-components/provider/ChuiThemeProvider";
import "@cvccorp-components/chui-react-components/assets/css/chui.css";
import { AssistedSaleProvider } from "@attentions-components/hooks";
import fetcher from "@cvccorp-engines/api";
import type { ProductTypes } from "@cvccorp-engines/components";
import { EngineNavigationProvider } from "@cvccorp-engines/navigation";
import { EnginesConfigProvider } from "@cvccorp-engines/provider";
import EngineWrapper from "./components/EngineWrapper";

fetcher.defaults.baseURL = process.env.REACT_APP_CMS_BASE_HOSTNAME;

interface IAppProps {
    product: ProductTypes;
}

const App = ({ product }: IAppProps) => {
    return (
        <ChuiThemeProvider>
            <EngineNavigationProvider product={product} navigationType="button">
                <AssistedSaleProvider>
                    <EnginesConfigProvider>
                        <EngineWrapper />
                    </EnginesConfigProvider>
                </AssistedSaleProvider>
            </EngineNavigationProvider>
        </ChuiThemeProvider>
    );
};

export default memo(App);
