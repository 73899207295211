import { useContext as e } from "react";
import { EngineFlightContext as t } from "./EngineFlightContext.js";
function i() {
  const o = e(t);
  if (!o) throw new Error("Chamada do hook useMotorAereoContext inválida! Certifique-se que ele esta sendo chamado dentro do contexto MotorAereoContext");
  return o;
}
export {
  i as default
};
