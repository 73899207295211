import c, { createContext as u, useState as p, useEffect as m, useContext as d } from "react";
import { getRecommendedDestinations as l } from "@cvccorp-engines/api";
const o = u([]), f = ({
  children: n
}) => {
  const [s, r] = p([]);
  return m(() => {
    async function a() {
      const e = await l();
      if (!(e != null && e.length)) return;
      const i = e.map((t) => ({
        label: t.name,
        value: {
          zoneId: t.masterCode,
          description: t.name
        }
      }));
      r(i);
    }
    a();
  }, []), /* @__PURE__ */ c.createElement(o.Provider, { value: s }, n);
};
f.displayName = "ToursOptionsProvider";
const x = () => d(o);
export {
  f as default,
  x as useToursOptions
};
