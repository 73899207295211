import t from "@emotion/styled";
const i = t.div(() => ({
  width: "100%",
  "& fieldset": {
    height: "fit-content"
  }
}));
export {
  i as Wrapper
};
