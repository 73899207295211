import { useRef as j, useMemo as D } from "react";
import { getBusingCharterCalendar as F } from "@cvccorp-engines/api";
import { useError as I, useFetch as L, formatOptions as R } from "@cvccorp-engines/components";
import { u as V, a as $ } from "../../../EngineBusingCharterContext-Dp5Np45L.js";
import { formatPaxForService as w } from "@cvccorp-engines/pax";
import { getBusingCharterParamsForLogs as A } from "../../../utils/getBusingParamsForLogs.js";
const K = 60 * 5 * 1e3, J = () => {
  var T, h;
  const {
    setErrors: S,
    pesqincLocationSelected: a,
    calendarDateSelected: b,
    paxValue: u,
    startDate: n,
    setStartDate: v,
    isAtlas: x
  } = V(), {
    throwError: r
  } = I(), d = j(), g = (n == null ? void 0 : n.length) === 2, s = (T = a == null ? void 0 : a.origin) == null ? void 0 : T.id, i = (h = a == null ? void 0 : a.destination) == null ? void 0 : h.id, y = D(() => w(u), [u]), E = async () => {
    var C;
    if (!s && !i) return;
    const t = A({
      operation: "calendarLocation"
    }), e = (/* @__PURE__ */ new Date()).getTime();
    try {
      const m = await F({
        originId: s,
        destinationId: i,
        rooms: y,
        startDate: g ? `${n[0]}-${(C = (+(n[1] ?? "0") + 1).toString()) == null ? void 0 : C.padStart(2, "0")}-01` : void 0,
        numberOfMonths: g ? 2 : void 0,
        isAtlas: x
      }), l = (/* @__PURE__ */ new Date()).getTime() - e;
      return Object.assign(t, {
        message: m.calendar ? "SUCCESS" : "EMPTYRESULT",
        duration: l,
        durationUnit: "milisseconds"
      }), r == null || r(t), m;
    } catch (m) {
      const l = (/* @__PURE__ */ new Date()).getTime() - e;
      return Object.assign(t, {
        message: "Erro ao buscar calendário",
        duration: l,
        durationUnit: "milisseconds",
        errorResponse: m,
        level: 4
      }), r == null || r(t), S({
        dates: {
          startDate: !0,
          endDate: !0
        }
      }), null;
    }
  }, P = (t, e) => {
    d.current && clearTimeout(d.current), d.current = setTimeout(() => {
      v([e.toString(), t.toString().padStart(2, "0")]);
    }, 700);
  }, B = [s, i, b, u, n], {
    data: f,
    isLoading: c,
    previous: M
  } = L(E, B, {
    cacheTime: K,
    enabled: !!(s && i)
  }), p = Object.values(M).filter((t) => !!(t != null && t.calendar)).flatMap((t) => t == null ? void 0 : t.calendar), O = D(() => c ? [] : R(p), [p, c]), o = (() => {
    var e;
    if (!f || c) return;
    const {
      date: t
    } = ((e = f.calendar) == null ? void 0 : e[0]) || {};
    return $(t);
  })();
  return {
    isLoading: c,
    options: O,
    initialDate: {
      month: o == null ? void 0 : o.month(),
      year: o == null ? void 0 : o.year()
    },
    handleChangeCalendar: P
  };
};
export {
  J as default
};
