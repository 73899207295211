"use client";
import t, { useRef as O, useMemo as S } from "react";
import { useFormMobile as k, getCalendarDatesAsDate as y, InputGroup as A, InputDatePickerRange as M } from "@cvccorp-engines/components";
import { d as m } from "../../dayjs-CKf7tiKR.js";
import { PaxTravelInsurance as F } from "@cvccorp-engines/pax";
import { useTravelInsuranceStore as J } from "../../store/TravelInsuranceStore.js";
import L from "../PesqincTravelInsurance/PesqincTravelInsurance.js";
import { LocationAndDateWrapper as N } from "./LocationAndDate.styles.js";
const W = m().add(1.5, "year").toDate(), b = m().add(1, "day").toDate();
function K() {
  const {
    pax: f,
    destination: P,
    calendarDates: d,
    errors: e,
    openPax: h,
    clearPax: v,
    setOpenPax: l,
    setPax: g,
    setCalendarDates: x,
    setDestination: s,
    clearDates: R
  } = J(), {
    firstInputRef: C
  } = k(), E = O(null), I = S(() => y(d) || [], [d]), T = (a) => {
    if (!a) return;
    const [n, o] = a;
    x({
      startDate: n == null ? void 0 : n.toJSON(),
      endDate: o == null ? void 0 : o.toJSON()
    });
  }, r = () => {
    var n, o, i, D, u, p;
    const a = {
      startDate: "",
      endDate: ""
    };
    return (n = e == null ? void 0 : e.calendarDates) != null && n.startDate && (a.startDate = "Preencha a data de ida"), (o = e == null ? void 0 : e.calendarDates) != null && o.endDate && (a.endDate = "Preencha a data de volta"), (i = e == null ? void 0 : e.calendarDates) != null && i.startDate && ((D = e == null ? void 0 : e.calendarDates) != null && D.endDate) && (a.startDate = "Preencha a data de ida", a.endDate = "Preencha a data de volta"), !((u = e == null ? void 0 : e.calendarDates) != null && u.startDate) && !((p = e == null ? void 0 : e.calendarDates) != null && p.endDate) && !(e != null && e.date) && (a.startDate = "", a.endDate = ""), a;
  }, c = () => e != null && e.destination ? "Preencha o campo de destino" : "";
  return /* @__PURE__ */ t.createElement(t.Fragment, null, /* @__PURE__ */ t.createElement(L, { placeholder: "Para onde você vai?", supportText: c(), error: c(), value: P, onSelect: s, inputProps: {
    inputRef: C
  }, onClear: () => s(void 0) }), /* @__PURE__ */ t.createElement(N, null, /* @__PURE__ */ t.createElement(A, { fullWidth: !0, status: r().startDate || r().endDate ? "danger" : void 0 }, /* @__PURE__ */ t.createElement(M, { value: I, inputOneProps: {
    inputRef: E
  }, max: W, min: b, showRangeCount: !0, error: r(), onChange: T, onClear: R, hasMinRange: 3, datePickerProps: {
    minRange: 1
  } })), /* @__PURE__ */ t.createElement(F, { onConfirm: (a) => {
    g(a), l(!1);
  }, open: h, onToggle: l, error: e.pax, onClear: v, value: f })));
}
export {
  K as default
};
