import y, { createContext as B, useCallback as E, useMemo as N, useContext as O } from "react";
import { useIsomorphicLayoutEffect as H } from "@cvccorp-components/chui-react-components";
import { gtm as R, redirectToSearch as w, DateFormat as P } from "@cvccorp-engines/components";
import { d } from "../../dayjs-CKf7tiKR.js";
import I from "../../hooks/usePackageSearchHistory/usePackageSearchHistory.js";
import { usePackageStore as q } from "../../store/PackageStore/PackageStore.js";
import { formatStateToGTM as L } from "../../utils/formatStateToGTM.js";
import U from "../../utils/formatUrlToSearch.js";
import k from "../../utils/validateChildrenPaxDate.js";
const M = B(void 0), C = (a) => ({
  startDate: d(a.startDate).format(P.YEAR_MONTH_DATE_TIMEZONE),
  endDate: d(a.endDate).format(P.YEAR_MONTH_DATE_TIMEZONE)
});
function Y(a) {
  var u, g;
  const {
    setErrors: l,
    setErrorsPax: m,
    setDestination: S,
    setCalendarDates: T,
    setPax: v,
    setOrigin: A,
    ...e
  } = q(), {
    searchHistory: D,
    addValue: f
  } = I();
  H(() => {
    if (!e.destination && D.length) {
      const t = D[0];
      S(t.destination), A(t.origin), v(t.pax), d(/* @__PURE__ */ new Date()).isAfter(t.calendarDates.startDate) || T(C(t.calendarDates));
    }
  }, []);
  const p = E(() => {
    var r, h;
    let t = !1;
    const n = !e.pax || k(e.pax)[0], s = !e.destination, o = !e.origin, i = !((r = e.calendarDates) != null && r.endDate), c = !((h = e.calendarDates) != null && h.startDate);
    return t = s || o || i || c || n, [t, {
      destination: s,
      origin: o,
      pax: n,
      calendarDates: {
        endDate: i,
        startDate: c
      }
    }];
  }, [(u = e.calendarDates) == null ? void 0 : u.endDate, (g = e.calendarDates) == null ? void 0 : g.startDate, e.destination, e.origin, e.pax]), x = E(() => {
    var r;
    const t = (r = e.calendarDates) == null ? void 0 : r.endDate, [n, s] = p(), [o, i] = k(e.pax, t);
    if (l({
      ...s,
      pax: o
    }), m(i), n || o)
      return;
    const c = U(e).replaceAll("+,+", ", ").replaceAll("+", " ");
    f({
      calendarDates: C(e.calendarDates),
      origin: e.origin,
      destination: e.destination,
      pax: e.pax
    }), R.onEngineSearch(L(e)), w(c);
  }, [e, l, m, p, f]), _ = N(() => ({
    handleSearch: x
  }), [x]);
  return /* @__PURE__ */ y.createElement(M.Provider, { ...a, value: _ });
}
Y.displayName = "MotorPackageProvider";
function Q() {
  const a = O(M);
  if (!a) throw new Error("Chamada do hook useMotorPackageContext inválida! Certifique-se que ele esta sendo chamado dentro do contexto MotorPackageContext");
  return a;
}
export {
  Y as default,
  Q as useMotorPackageContext
};
