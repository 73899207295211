import i from "../../libs/fetcher.js";
import e from "../../utils/QueryString.js";
const c = async (o) => {
  var t;
  try {
    const {
      data: r
    } = await i.get("https://locations-api.services.cvc.com.br/v1/locations", {
      params: {
        q: e.text(o).toString(),
        owner: "cars",
        strategy: "iataDrive",
        type: "CITY"
      }
    });
    return {
      meta: r.meta,
      locations: ((t = r.locations) == null ? void 0 : t.filter(({
        officeHours: a
      }) => a.length)) || []
    };
  } catch {
    throw new Error("Error in Locations");
  }
};
export {
  c as getCarsLocations
};
