import { create as i } from "zustand";
import { getPackagePaxInitialValue as s, getPackageStartDateInitialValue as o, getPackageEndDateInitialValue as l, getPackageOriginInitialValue as d, getPackageDestinationInitialValue as c } from "../../utils/getPackageInitialValue.js";
const n = {
  destination: !1,
  origin: !1,
  calendarDates: {
    endDate: !1,
    startDate: !1
  },
  pax: !1
}, D = [{
  adult: 2,
  children: 0,
  childrenDateOfBirth: []
}], P = i((e) => ({
  pax: s(),
  calendarDates: {
    startDate: o(),
    endDate: l()
  },
  origin: d(),
  destination: c(),
  errors: n,
  errorsPax: {},
  openPax: !1,
  setDestination: (r) => e((a) => {
    var t;
    return {
      destination: r,
      inputFocus: (t = a.calendarDates) != null && t.startDate ? "pax" : "dates",
      errors: {
        ...a.errors,
        destination: n.destination
      }
    };
  }),
  setOrigin: (r) => e((a) => {
    var t;
    return {
      origin: r,
      inputFocus: a.destination ? (t = a.calendarDates) != null && t.startDate ? "pax" : "dates" : "destination",
      errors: {
        ...a.errors,
        origin: n.origin
      }
    };
  }),
  setCalendarDates: (r) => e((a) => ({
    calendarDates: r,
    inputFocus: "pax",
    errors: {
      ...a.errors,
      calendarDates: n.calendarDates
    }
  })),
  setPax: (r) => e((a) => ({
    pax: r,
    errorsPax: {},
    errors: {
      ...a.errors,
      pax: !1
    }
  })),
  setErrors: (r) => e(() => ({
    errors: r
  })),
  setErrorsPax: (r) => e(() => ({
    errorsPax: r
  })),
  setOpenPax: (r) => e(() => ({
    openPax: r
  })),
  resetTempPax() {
    return e((r) => ({
      errorsPax: {},
      errors: {
        ...r.errors,
        pax: !1
      }
    }));
  },
  clearPax: () => e((r) => ({
    pax: D,
    errors: {
      ...r.errors,
      pax: !1
    },
    errorsPax: {}
  })),
  clearDates: () => e((r) => ({
    calendarDates: {
      endDate: void 0,
      startDate: void 0
    },
    errors: {
      ...r.errors,
      calendarDates: n.calendarDates
    }
  })),
  reverse() {
    return e((r) => {
      var a, t;
      return {
        destination: r.origin,
        origin: r.destination,
        errors: {
          ...r.errors,
          destination: (a = r.errors) == null ? void 0 : a.origin,
          origin: (t = r.errors) == null ? void 0 : t.destination
        }
      };
    });
  }
}));
export {
  D as defaultPackagePax,
  P as usePackageStore
};
