import u from "@cvccorp-components/chui-react-components/lib/chuiDate";
import h from "../utils/calcAge.js";
function g(i, t) {
  let e = !1;
  const r = {};
  return i.forEach((n, f) => {
    const a = f;
    if (!u(n).isValid()) {
      r[a] = "Informe a data de nascimento", e = !0;
      return;
    }
    if (t && new Date(n).getTime() > new Date(t).getTime()) {
      r[a] = "Data é posterior ao fim da viagem", e = !0;
      return;
    }
    const o = h(n, t);
    (o < 0 || o > 17) && (r[a] = "Idade não está entre 0 e 17 anos", e = !0);
  }), [e, r];
}
function B(i, t) {
  if (!(i != null && i.length)) return [!1, {}];
  let e = !1;
  const r = {};
  return i.forEach((n, f) => {
    n.childrenDateOfBirth.forEach((a, s) => {
      const o = `${f}-${s}`;
      if (!u(a).isValid()) {
        r[o] = "Informe a data de nascimento", e = !0;
        return;
      }
      if (t && new Date(a).getTime() > new Date(t).getTime()) {
        r[o] = "Data é posterior ao fim da viagem", e = !0;
        return;
      }
      const c = h(a, t);
      (c < 0 || c > 17) && (r[o] = "Idade não está entre 0 e 17 anos", e = !0);
    });
  }), [e, r];
}
export {
  B as default,
  g as validateChildrenDateOfBirth
};
