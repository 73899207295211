import {
    PRODUCTS_LINK_SUBMARINO,
    PRODUCTS_LINK_CVC,
    NAVIGATION_PRODUCTS,
    type NavigationProduct,
    useEngineNavigation,
} from "@cvccorp-engines/navigation";
import { useTheme } from "@emotion/react";

export const getDefaultProductByTheme = (theme: string): NavigationProduct =>
    theme === "submarino" ? NAVIGATION_PRODUCTS.AIR : NAVIGATION_PRODUCTS.PACKAGES;

export default function useEngineWrapper() {
    const { currentProduct, pathname, products, setCurrentProduct } = useEngineNavigation();
    const { name: themeName } = useTheme();
    const productsLinks = themeName === "submarino" ? PRODUCTS_LINK_SUBMARINO : PRODUCTS_LINK_CVC;

    return { product: currentProduct, setProduct: setCurrentProduct, pathname, products, productsLinks };
}
