import { createContext, useContext, useMemo } from "react";
import type { EngineSearchContextType, EngineSearchContextValue } from "./EngineSearchProvider.types";

const EngineSearchContext = createContext({} as EngineSearchContextValue);

const EngineSearchProvider = (props: EngineSearchContextType) => {
    const { children, renderNavigation } = props;

    const value = useMemo<EngineSearchContextValue>(() => ({ renderNavigation }), [renderNavigation]);
    return <EngineSearchContext.Provider value={value}>{children}</EngineSearchContext.Provider>;
};

export const useEngineSearchContext = () => {
    const context = useContext(EngineSearchContext);
    if (!context) {
        throw new Error("useEngineSearchContext must be used within a EngineSearchProvider");
    }
    return context;
};

export default EngineSearchProvider;
