import u, { useState as w, useMemo as I } from "react";
import x from "@cvccorp-components/chui-react-components/components/Autocomplete";
import { pesqIncService as D } from "@cvccorp-engines/api";
import { getParams as g, useError as H, useFormMobile as $, Pesqinc as q } from "@cvccorp-engines/components";
import { getHotelParamsForLogs as F } from "../../utils/getHotelParams.js";
import { LocationContainer as k } from "./Pesqinc.styles.js";
const A = (b) => {
  const {
    error: p,
    value: n,
    label: O = "Destino",
    supportText: y,
    supportTextProps: E,
    onClear: a,
    onSelect: l
  } = b, [d, L] = w([]), P = g("packageGroup") ? String(g("packageGroup")).toUpperCase() : "STANDALONE", {
    throwError: m
  } = H(), {
    firstInputRef: S
  } = $();
  function s(e) {
    if (!e) return "";
    const t = e.description.indexOf(" -") > -1 ? e.description.split(" -")[0] : e.description;
    return `${e.type === "HOTEL" ? t : e.city} - ${e != null && e.state ? e.state : ""}, ${e != null && e.country ? e.country : ""}`;
  }
  const h = async (e) => {
    const T = F(), t = {
      operation: "location",
      productType: "hotel",
      packageGroup: P,
      ...T
    }, i = (/* @__PURE__ */ new Date()).getTime();
    try {
      const c = (await D("HOT", e)).locations.sort((o, r) => o.type === "CITY" && r.type === "HOTEL" ? -1 : o.type === "HOTEL" && r.type === "CITY" ? 1 : 0).map((o) => ({
        label: s(o),
        value: o
      }));
      if (c.length === 0) {
        const r = (/* @__PURE__ */ new Date()).getTime() - i;
        Object.assign(t, {
          duration: r,
          _message: `EMPTYRESULT ${e}`
        }), m(t);
      }
      L(c);
    } catch (f) {
      const c = (/* @__PURE__ */ new Date()).getTime() - i;
      return Object.assign(t, {
        duration: c,
        _message: `ERROR ${e}`,
        errorResponse: {
          error: f.message
        }
      }), m(t), [];
    }
  }, R = I(() => {
    const e = d;
    return n && e.push({
      value: n,
      label: s(n)
    }), e;
  }, [d, n]);
  return /* @__PURE__ */ u.createElement(k, null, /* @__PURE__ */ u.createElement(q, { label: p || O, placeholder: "Para onde você vai?", supportText: y, supportTextProps: E, error: p, onSearch: h, onSelect: (e) => {
    if (!e) return a ? a == null ? void 0 : a() : l(e);
    l(e);
  }, inputProps: {
    inputRef: S
  }, options: R, value: n, renderOption: ({
    option: e
  }) => /* @__PURE__ */ u.createElement(x.Option, { fullWidth: !0, supportText: s(e.value), label: s(e.value), onSelect: () => l(e.value) }) }));
};
A.displayName = "PesqIncSingleLocation";
export {
  A as default
};
