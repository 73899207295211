"use client";
import a, { useRef as w } from "react";
import x from "@cvccorp-components/chui-react-components/components/InputDatePickerRange";
import { d as b } from "../../dayjs-CKf7tiKR.js";
import O from "../InputLoadingOverlay/InputLoadingOverlay.js";
import { Wrapper as T } from "./InputDatePickerRange.styles.js";
const W = (i) => {
  const {
    value: s,
    min: p,
    max: l,
    options: u,
    inputGroupProps: d,
    inputOneProps: m,
    inputTwoProps: c,
    content: f,
    isLoading: P = !1,
    error: e = {},
    showRangeCount: D = !0,
    datePickerProps: g,
    onClear: h,
    onFocus: C,
    onChange: r,
    onChangeCalendar: y,
    disableDates: R = () => !1
  } = i, o = p ?? /* @__PURE__ */ new Date(), k = l ?? b(o).add(1, "year").toDate(), v = w(null);
  function n(t) {
    r == null || r(t);
  }
  return /* @__PURE__ */ a.createElement(T, { ref: v }, /* @__PURE__ */ a.createElement(x, { inputGroupProps: {
    fullWidth: !0,
    ...d
  }, inputOneProps: {
    fullWidth: !0,
    label: "Início",
    placeholder: "",
    supportText: e.startDate,
    status: e.startDate ? "danger" : void 0,
    size: "md",
    ...m
  }, inputTwoProps: {
    label: "Fim",
    placeholder: "",
    supportText: e.endDate,
    status: e.endDate ? "danger" : void 0,
    fullWidth: !0,
    size: "md",
    ...c
  }, datePickerProps: {
    options: u,
    onFocus: C,
    maxDate: k,
    minDate: o,
    disable: R,
    size: "sm",
    ...g
  }, value: s, showRangeCount: D, onConfirm: n, onClear: h, onChangeCalendar: y, content: f, onChange: (t) => {
    Array.isArray(t) && n(t);
  } }), P ? /* @__PURE__ */ a.createElement(O, null) : void 0);
};
W.displayName = "DatePickerRange";
export {
  W as default
};
