const r = ({
  level: e = 6,
  message: o = "",
  operation: n = "location",
  ...p
} = {}) => ({
  _app_name: "cvccorp-engines-busing",
  productType: "busing",
  productGroupType: "PACKAGE",
  _message: o,
  level: e,
  operation: n,
  ...p
});
export {
  r as getBusingParamsForLogs
};
