import t from "react";
import { Chip as n } from "@cvccorp-components/chui-react-components";
import { u as r } from "../../EngineBusingContext-aoE1hnqw.js";
const a = () => {
  const {
    isOneWay: e,
    setIsOneWay: o
  } = r();
  return /* @__PURE__ */ t.createElement(n, { color: "secondary", checked: e, onClick: () => o(!e) }, "Somente ida");
};
a.displayName = "SearchOptions";
export {
  a as default
};
