import e from "react";
import { getCalendarDatesAsDate as P, FormContainer as t, OptionsBar as l } from "@cvccorp-engines/components";
import { d as U } from "../../dayjs-CKf7tiKR.js";
import y from "../../context/EngineHotelContext/EngineHotelContext.js";
import A from "../../context/EngineHotelContext/useEngineHotel.js";
import B from "../../context/EngineResortsContext/EngineResortsContext.js";
import { useEngineResortsContext as J } from "../../context/EngineResortsContext/useEngineResortsContext.js";
import "../../store/HotelStore/HotelStore.js";
import { useResortsStore as L } from "../../store/ResortsStore/ResortsStore.js";
import { useHotelsResortsStore as j } from "../../store/HotelResortsStore/HotelsResortsStore.js";
import C from "../LocationAndDate/LocationAndDate.js";
import g from "../ResortDefaultLocationForm/index.js";
import { MotorContainer as b, buttonContainer as I, Container as S, ButtonContainer as T } from "./styles.js";
const s = [{
  value: "hotels",
  label: "Todos"
}, {
  value: "resorts",
  label: "Resorts"
}], M = () => {
  const {
    handleSearch: R
  } = A(), {
    handleSearch: H
  } = J(), {
    choice: n,
    setChoice: o
  } = j(), {
    destination: i,
    setDestination: m,
    calendarDates: k,
    setCalendarDates: v,
    errors: c,
    clearDates: d,
    setOpenPax: F
  } = L(), p = U().add(1, "d").toDate(), u = P(k), E = () => F(!0), h = (f) => {
    if (!f) return;
    const [r, a] = f;
    v({
      startDate: r == null ? void 0 : r.toJSON(),
      endDate: a == null ? void 0 : a.toJSON()
    });
  }, D = {
    hotels: {
      handleSearch: R,
      renderMobileForm: () => /* @__PURE__ */ e.createElement(t.Mobile, { title: "Hotel", label: "Destino" }, /* @__PURE__ */ e.createElement(S, null, /* @__PURE__ */ e.createElement(C, null))),
      renderDesktop: () => /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(l, { options: s, value: n, onChange: o }), /* @__PURE__ */ e.createElement(S, null, /* @__PURE__ */ e.createElement(C, null), /* @__PURE__ */ e.createElement(T, null, /* @__PURE__ */ e.createElement(t.Submit, null))))
    },
    resorts: {
      handleSearch: H,
      renderMobileForm: () => /* @__PURE__ */ e.createElement(b, null, /* @__PURE__ */ e.createElement(g, { errors: c, destination: i, onDestinationChange: m, onOpenPax: E, dates: u, onDateClear: d, onDateChange: h, min: p })),
      renderDesktop: () => /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(l, { options: s, value: n, onChange: o }), /* @__PURE__ */ e.createElement(b, null, /* @__PURE__ */ e.createElement(g, { errors: c, destination: i, onDestinationChange: m, onOpenPax: E, dates: u, onDateClear: d, onDateChange: h, min: p }, /* @__PURE__ */ e.createElement(I, null, /* @__PURE__ */ e.createElement(t.Submit, null)))))
    }
  }, {
    handleSearch: O,
    renderDesktop: x,
    renderMobileForm: N
  } = D[n] ?? D.hotels;
  return /* @__PURE__ */ e.createElement(t, { formId: "form-hotel", onSearch: O, renderMobile: () => /* @__PURE__ */ e.createElement(t.Mobile, { title: "Hotel", label: "Destino" }, /* @__PURE__ */ e.createElement(l, { options: s, value: n, onChange: o }), N()), renderDesktop: x });
};
M.displayName = "EngineUnifiedContent";
const q = () => /* @__PURE__ */ e.createElement(y, null, /* @__PURE__ */ e.createElement(B, null, /* @__PURE__ */ e.createElement(M, null)));
q.displayName = "EnginesUnified";
export {
  s as choices,
  q as default
};
