import c, { createContext as l, useState as a, useRef as i, useEffect as u, useMemo as m, useContext as d } from "react";
const r = l({}), M = ({
  children: s
}) => {
  const [o, t] = a(!1), e = i();
  u(() => {
    o && (e != null && e.current) && e.current.click();
  }, [o, e]);
  const n = m(() => ({
    isModalOpen: o,
    setIsModalOpen: t,
    firstInputRef: e
  }), [o, t, e]);
  return /* @__PURE__ */ c.createElement(r.Provider, { value: n }, s);
};
M.displayName = "FormMobileProvider";
const b = () => d(r);
export {
  M as default,
  b as useFormMobile
};
