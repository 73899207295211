"use client";
import e, { useEffect as i } from "react";
import { FormContainer as r } from "@cvccorp-engines/components";
import { useEngineTravelInsuranceContext as c } from "../../context/EngineTravelInsuranceContext.js";
import { useTravelInsuranceStore as u } from "../../store/TravelInsuranceStore.js";
import { getTravelInsuranceDestinationParam as s } from "../../utils/getParams.js";
import a from "../LocationAndDate/LocationAndDate.js";
import { EngineContainer as o, ButtonContainer as f } from "./EngineTravelInsuranceForm.styles.js";
const E = () => {
  const {
    handleSearch: l
  } = c(), {
    setDestination: n
  } = u();
  return i(() => {
    async function m() {
      const t = await s();
      t && (n == null || n(t));
    }
    m();
  }, [n]), /* @__PURE__ */ e.createElement(r, { formId: "form-travel-insurance", onSearch: l, renderMobile: () => /* @__PURE__ */ e.createElement(r.Mobile, { title: "Seguro Viagem", label: "Destino" }, /* @__PURE__ */ e.createElement(o, null, /* @__PURE__ */ e.createElement(a, null))), renderDesktop: () => /* @__PURE__ */ e.createElement(o, null, /* @__PURE__ */ e.createElement(a, null), /* @__PURE__ */ e.createElement(f, null, /* @__PURE__ */ e.createElement(r.Submit, null))) });
};
E.displayName = "EngineTravelInsuranceForm";
export {
  E as default
};
