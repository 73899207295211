import o from "@cvccorp-components/chui-react-icons/icons/filled/product/FilledBus";
import l from "@cvccorp-components/chui-react-icons/icons/filled/product/FilledCarRetro";
import a from "@cvccorp-components/chui-react-icons/icons/filled/product/FilledDisney";
import e from "@cvccorp-components/chui-react-icons/icons/filled/product/FilledPlaneRight";
import s from "@cvccorp-components/chui-react-icons/icons/filled/product/FilledShip";
import t from "@cvccorp-components/chui-react-icons/icons/filled/product/FilledTicketSearch";
import T from "@cvccorp-components/chui-react-icons/icons/filled/product/FilledTrips";
import c from "@cvccorp-components/chui-react-icons/icons/filled/service/FilledBaggage";
import r from "@cvccorp-components/chui-react-icons/icons/filled/service/FilledBed";
import m from "@cvccorp-components/chui-react-icons/icons/filled/service/FilledGiftBox";
import C from "@cvccorp-components/chui-react-icons/icons/filled/service/FilledItinerary";
import E from "@cvccorp-components/chui-react-icons/icons/filled/service/FilledPartners";
import p from "@cvccorp-components/chui-react-icons/icons/filled/service/FilledSecurity";
import S from "@cvccorp-components/chui-react-icons/icons/filled/service/FilledVacation";
import A from "@cvccorp-components/chui-react-icons/icons/filled/service/FilledWorld";
import n from "@cvccorp-components/chui-react-icons/icons/filled/system/FilledApp";
import R from "@cvccorp-components/chui-react-icons/icons/filled/system/FilledMoney";
import d from "@cvccorp-components/chui-react-icons/icons/filled/system/FilledPlay";
const i = {
  HOTEL: "hotel",
  AIR: "air",
  PACKAGES: "packages",
  PACKAGE_COMPLETE: "package-complete",
  CARS: "cars",
  VHC: "vhc",
  TICKETS: "tickets",
  CRU: "cru",
  RESORTS: "resorts",
  DISNEY: "disney",
  TOURS: "tours",
  TRAVEL_SECURITY: "travel-security",
  CLUB: "club",
  CVC_ME_LEVA: "cvc-me-leva",
  APP: "app",
  CVC_PLUS: "cvc+",
  PARTNERSHIPS: "partnerships",
  DESTINO: "destino",
  EXCHANGE: "exchange",
  TICKET_TRIP: "ticket-trip",
  BUSING: "busing",
  BUSING_CHARTER: "busing-charter",
  PACKAGE_FULL: "package-full"
}, K = {
  [i.AIR]: "/passagens-aereas",
  [i.HOTEL]: "/hotel",
  [i.PACKAGES]: "/pacotes-turisticos",
  [i.PACKAGE_FULL]: "/pacotes-estatico",
  [i.CRU]: "https://cruzeirosonline.cvc.com.br/?_ga=2.51952873.1737951401.1700483294-1676975524.1673306072",
  [i.CARS]: "/aluguel-de-carros",
  [i.CVC_ME_LEVA]: "https://www.cvc.com.br/lp/promocoes/cvcmeleva?chn=web",
  [i.CLUB]: "https://www.cvc.com.br/clube-cvc/",
  [i.DISNEY]: "https://www.cvc.com.br/lp/pacotes-de-viagem/internacional/disney",
  [i.APP]: "https://www.cvc.com.br/lp/app-cvc-viagens",
  [i.CVC_PLUS]: "https://www.cvcmais.com.br/#xd_co_f=MzY0ZGFhNTktNzA5ZS00YjBjLTlhZTAtMTVhZmQyMzQ1YmNk~",
  [i.TICKETS]: "/ingresso",
  [i.TRAVEL_SECURITY]: "/seguro-viagem",
  [i.TOURS]: "/tours",
  [i.PARTNERSHIPS]: "https://www.cvc.com.br/lp/parcerias",
  [i.TICKET_TRIP]: "https://www.cvc.com.br/vale-viagem.aspx",
  [i.BUSING]: "/rodoviario/rodofacil/search?TicketType=rodofacil",
  [i.BUSING_CHARTER]: "/rodoviario/exclusivo/search?TicketType=exclusivo",
  [i.RESORTS]: "https://www.submarinoviagens.com.br/promocoes/especial-resorts",
  [i.DESTINO]: "https://www.submarinoviagens.com.br/destinos",
  [i.EXCHANGE]: "https://submarinoviagens.meucambio.com.br/?xd_co_f=OTc4OTQ0ZWMtMWMyOS00ZTQ5LTk3MjgtM2ExZDM3NDM4Njhh#/"
}, M = /* @__PURE__ */ new Map([[i.PACKAGES, {
  label: "Pacotes",
  icon: c,
  id: i.PACKAGES
}], [i.AIR, {
  label: "Passagens",
  icon: e,
  id: i.AIR
}], [i.HOTEL, {
  label: "Hotéis",
  icon: r,
  id: i.HOTEL
}], [i.CRU, {
  label: "Cruzeiros",
  icon: s,
  id: i.CRU
}], [i.CARS, {
  label: "Carros",
  icon: l,
  id: i.CARS
}], [i.TRAVEL_SECURITY, {
  label: "Seguro Viagem",
  icon: p,
  id: i.TRAVEL_SECURITY
}], [i.TICKETS, {
  label: "Ingressos",
  icon: t,
  id: i.TICKETS
}], [i.CVC_ME_LEVA, {
  label: "CVC me leva",
  icon: A,
  href: "https://www.cvc.com.br/lp/promocoes/cvcmeleva-site?chn=web",
  id: i.CVC_ME_LEVA
}], [i.DISNEY, {
  label: "Disney",
  icon: a,
  id: i.DISNEY
}], [i.TOURS, {
  label: "Circuitos",
  icon: C,
  id: i.TOURS
}], [i.APP, {
  label: "App CVC",
  icon: n,
  id: i.APP
}], [i.TICKET_TRIP, {
  label: "Vale Viagem",
  icon: m,
  id: i.TICKET_TRIP
}], [i.PARTNERSHIPS, {
  label: "Parcerias",
  icon: E,
  id: i.PARTNERSHIPS
}], [i.CVC_PLUS, {
  label: "CVC+",
  icon: d,
  id: i.CVC_PLUS
}], [i.BUSING, {
  label: "Passagem rodoviária",
  icon: o,
  id: i.BUSING
}], [i.BUSING_CHARTER, {
  label: "Pacote rodoviário",
  icon: o,
  id: i.BUSING_CHARTER
}], [i.AIR, {
  label: "Passagens",
  icon: e,
  id: i.AIR
}], [i.HOTEL, {
  label: "Hotéis",
  icon: r,
  id: i.HOTEL
}], [i.PACKAGES, {
  label: "Pacotes",
  icon: c,
  id: i.PACKAGES
}], [i.RESORTS, {
  label: "Resorts",
  icon: S,
  id: i.RESORTS
}], [i.DESTINO, {
  label: "Destinos",
  icon: T,
  id: i.DESTINO
}], [i.EXCHANGE, {
  label: "Meu Câmbio",
  icon: R,
  id: i.EXCHANGE
}]]);
export {
  M as NAVIGATION_CONFIG,
  K as NAVIGATION_LINKS,
  i as NAVIGATION_PRODUCTS
};
