import e from "react";
import { getCalendarDatesAsDate as B, FormContainer as t } from "@cvccorp-engines/components";
import { d as S } from "../../dayjs-CKf7tiKR.js";
import { useMotorPackageContext as T } from "../../context/EnginePackageContext/EnginePackageContext.js";
import { useMotorPackageFullContext as W } from "../../context/EnginePackageFullContext/EnginePackageFullContext.js";
import { usePackageFullStoreStore as X } from "../../store/EnginePackageFullStore/EnginePackageFullStore.js";
import { usePackageStore as Y } from "../../store/PackageStore/PackageStore.js";
import P from "../DefaultLocationForm/index.js";
import b, { CHOICE_PACKAGE as i, CHOICE_PACKAGE_FULL as v } from "../NavigationChips/NavigationChips.js";
import F from "../PackageFullLocationAndDate/LocationAndDate.js";
import { MotorContainer as M, buttonContainer as q, EngineContainer as x, ButtonContainer as w } from "./styles.js";
const z = 330, Q = ({
  product: r,
  hideChips: l = !1
}) => {
  const {
    handleSearch: A
  } = T(), {
    handleSearch: _
  } = W(), n = X(), {
    origin: s,
    errors: c,
    destination: m,
    calendarDates: R,
    reverse: d,
    setOrigin: g,
    setOpenPax: N,
    clearDates: u,
    setDestination: D,
    setCalendarDates: L
  } = Y(), C = S().add(1, "d").toDate(), h = S().add(z, "d").toDate(), p = B(R), E = () => N(!0), k = (O) => {
    if (!O) return;
    const [a, o] = O;
    L({
      startDate: a == null ? void 0 : a.toJSON(),
      endDate: o == null ? void 0 : o.toJSON()
    });
  }, H = () => /* @__PURE__ */ e.createElement(M, null, /* @__PURE__ */ e.createElement(P, { errors: c, origin: s, destination: m, onDestinationChange: D, onOriginChange: g, onOpenPax: E, dates: p, onReverse: d, onDateClear: u, onDateChange: k, min: C, max: h })), I = () => /* @__PURE__ */ e.createElement(M, null, /* @__PURE__ */ e.createElement(P, { errors: c, origin: s, destination: m, onDestinationChange: D, onOriginChange: g, onOpenPax: E, dates: p, onReverse: d, onDateClear: u, onDateChange: k, min: C, max: h }, /* @__PURE__ */ e.createElement(q, null, /* @__PURE__ */ e.createElement(t.Submit, null)))), K = () => /* @__PURE__ */ e.createElement(x, null, /* @__PURE__ */ e.createElement(F, { errors: n.errors, origin: n.origin, destination: n.destination, onDestinationChange: n.setDestination, onOriginChange: n.setOrigin, onReverse: n.reverse })), y = () => /* @__PURE__ */ e.createElement(x, null, /* @__PURE__ */ e.createElement(F, { errors: n.errors, origin: n.origin, destination: n.destination, onDestinationChange: n.setDestination, onOriginChange: n.setOrigin, onReverse: n.reverse }), /* @__PURE__ */ e.createElement(w, null, /* @__PURE__ */ e.createElement(t.Submit, null))), f = {
    [i.value]: {
      handleSearch: A,
      renderMobile: H,
      renderDesktop: I,
      title: i.label
    },
    [v.value]: {
      handleSearch: _,
      renderMobile: K,
      renderDesktop: y,
      title: v.label
    }
  }, {
    handleSearch: G,
    renderDesktop: J,
    renderMobile: U,
    title: j
  } = f[r] ?? f[i.value];
  return /* @__PURE__ */ e.createElement(t, { formId: "form-package", onSearch: G, renderMobile: () => /* @__PURE__ */ e.createElement(t.Mobile, { title: j, label: "Destino" }, l ? void 0 : /* @__PURE__ */ e.createElement(b, { product: r }), U()), renderDesktop: () => /* @__PURE__ */ e.createElement(e.Fragment, null, l ? void 0 : /* @__PURE__ */ e.createElement(b, { product: r }), J()) });
};
Q.displayName = "Form";
export {
  Q as default
};
