import i, { useState as O, useMemo as P } from "react";
import h from "@cvccorp-components/chui-react-components/components/Autocomplete";
import { getTicketsLocations as R } from "@cvccorp-engines/api";
import { useError as $, Pesqinc as k } from "@cvccorp-engines/components";
import { getTicketsParamsForLogs as M } from "../../utils/getTicketInitialValue.js";
import { LocationContainer as _ } from "./styled.js";
const x = (d) => {
  const {
    error: l,
    value: o,
    onSelect: p,
    supportText: f,
    supportTextProps: T,
    onClear: n,
    label: E = "Destino",
    inputProps: b
  } = d, [u, v] = O([]);
  function s(e) {
    return e ? `${e.provider !== null ? `${e.provider} - ` : ""}${e != null && e.cityName ? e.cityName : ""} - ${e != null && e.stateName ? e.stateName : ""}` : "";
  }
  const {
    throwError: m
  } = $(), g = async (e) => {
    var y, N;
    const a = M(), c = {
      channel: "WEB",
      friendlyMessage: "EMPTYRESULT",
      _message: `EMPTYRESULT - ${e}`,
      operation: "location",
      productType: "services",
      packageGroup: "STANDALONE",
      provider: "LA",
      tags: [],
      _level_name: "info",
      ...a
    };
    try {
      const r = await R(e);
      if (((y = r == null ? void 0 : r.cities) == null ? void 0 : y.length) > 0) {
        const S = (N = r == null ? void 0 : r.cities) == null ? void 0 : N.map((t) => ({
          label: s({
            cityName: t.cityName,
            stateName: t.stateName,
            provider: t.name,
            cityId: t.cityId,
            countryName: t.countryName,
            description: t.description
          }),
          value: {
            cityName: t.cityName,
            stateName: t.stateName,
            provider: t.name,
            cityId: t.cityId,
            countryName: t.countryName,
            description: t.description
          }
        }));
        v(S);
      } else
        return Object.assign(c, {
          _message: `EMPTYRESULT ${e}`
        }), m(c), [];
    } catch {
      return Object.assign(c, {
        _message: `ERROR ${e}`
      }), m(c), [];
    }
  }, L = P(() => {
    const e = u;
    return o && e.push({
      value: o,
      label: s(o)
    }), e;
  }, [u, o]);
  return /* @__PURE__ */ i.createElement(_, null, /* @__PURE__ */ i.createElement(k, { label: l || E, supportText: f, supportTextProps: T, error: l, onSearch: g, onSelect: function(a) {
    if (!a) return n ? n == null ? void 0 : n() : p(o);
    p(a);
  }, inputProps: b, options: L, value: o, renderOption: ({
    option: e
  }) => /* @__PURE__ */ i.createElement(h.Option, { fullWidth: !0, supportText: s(e.value), label: s(e.value), onSelect: () => p(e.value) }) }));
};
x.displayName = "SingleTickets";
export {
  x as default
};
