import { GTMProducts as r, GTMProductTower as e, gtm as i } from "@cvccorp-engines/components";
const d = (t) => ({
  product: r.TOUR,
  product_tower: e.INTERNATIONAL,
  checkin_date: i.formatDate(t.date),
  destination_city: t.destinations.map((o) => o.description).join(", ")
});
export {
  d as formatStateToGTM
};
