import e from "@emotion/styled";
const p = e.div`
    display: flex;
    align-items: center;
    gap: 16px;

    @media (max-width: 600px) {
        gap: 4px;
    }
`;
export {
  p as Container
};
