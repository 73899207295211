import { Grid as o } from "@cvccorp-components/chui-react-components";
import t from "@emotion/styled";
const i = t(o)`
    display: flex;
    padding-block-start: 16;
    border-top: 1px solid gray;

    @media (max-width: 600px) {
        display: none;
    }
`;
export {
  i as ButtonContainer
};
