import o from "../../libs/fetcher.js";
import a from "../../utils/QueryString.js";
const c = async (r, t) => {
  try {
    const {
      data: e
    } = await o.get("/api/hotels/pesqinc", {
      params: {
        productType: r,
        q: a.text(t).replaceSpace().normalizeNFD().toString()
      }
    });
    return e;
  } catch {
    throw new Error("Error in pesqInc");
  }
};
export {
  c as pesqIncService
};
