import p from "react";
import { Input as u } from "@cvccorp-components/chui-react-components";
import { u as i } from "../../../EngineBusingCharterContext-Dp5Np45L.js";
const c = {
  excursion: {
    label: "Excursão",
    placeholder: "Digite o código",
    inputAttributes: {
      type: "number"
    }
  }
}, a = () => {
  const {
    searchType: t,
    setPesqincInputValue: n,
    errors: e,
    pesqincInputValue: r
  } = i(), s = c[t];
  return (
    // <Styled.DefaultWrapper>
    /* @__PURE__ */ p.createElement(u, { fullWidth: !0, onChange: (o) => n(o.target.value), supportText: e.pesqinc ? "Preencha o código de excursão" : void 0, status: e.pesqinc ? "danger" : void 0, value: r, ...s })
  );
};
a.displayName = "PesqincInput";
export {
  a as default
};
