import e from "@emotion/styled";
const t = e.div`
    display: flex;

    @media (max-width: 600px) {
        gap: 16px;
        align-self: flex-start;
        width: calc(100% - 32px);
        justify-content: flex-start;
    }
`, a = e.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 10px;
    align-items: center;

    @media (max-width: 600px) {
        gap: 0;
        font-size: 13px;
        padding: 0;
    }
`;
export {
  a as PriceLegendContainer,
  t as Wrapper
};
