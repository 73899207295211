import n, { useMemo as h } from "react";
import T from "@cvccorp-components/chui-react-components/components/AutoCompleteTag";
import { useFormMobile as x, useAutoCompleteItemSize as b } from "@cvccorp-engines/components";
import { useToursOptions as S } from "../../context/ToursOptionsContext/ToursOptionContext.js";
import { LocationContainer as v } from "./MultiToursLocation.styles.js";
const w = (l) => {
  const {
    error: e,
    value: o,
    supportText: p,
    supportTextProps: u,
    label: r = "Destino",
    onSelect: a
  } = l, i = S(), {
    firstInputRef: c
  } = x(), m = h(() => {
    const t = [...i];
    return o ? (o.forEach((s) => {
      const f = {
        label: s.description,
        value: s
      };
      t.push(f);
    }), [...t]) : t;
  }, [i, o]), d = b();
  return /* @__PURE__ */ n.createElement(v, null, /* @__PURE__ */ n.createElement(T, { placeholder: r, label: r, itemSize: d, supportText: p ?? e, loading: !1, inputProps: {
    supportTextProps: u,
    inputRef: c,
    fullWidth: !0,
    size: "md",
    inputAttributes: {
      readOnly: !0
    }
  }, onSelect: (t) => {
    t && a(t);
  }, options: m, status: e ? "danger" : void 0, value: o, maxSize: 5, dropdownAttributes: {
    style: {
      width: 530,
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  } }));
};
w.displayName = "MultiToursLocation";
export {
  w as default
};
