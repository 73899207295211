import b, { useState as D, useEffect as A, useCallback as B } from "react";
import x from "@cvccorp-components/chui-react-components/components/Autocomplete";
import { r as P } from "../../../OutlinePinMap-hG5rf3UN.js";
import { getBusingCharterLocations as E } from "@cvccorp-engines/api";
import { useError as w } from "@cvccorp-engines/components";
import { u as G } from "../../../EngineBusingCharterContext-Dp5Np45L.js";
import { getBusingCharterParamsForLogs as j } from "../../../utils/getBusingParamsForLogs.js";
const N = (n) => n.map((u) => ({
  label: u.description,
  value: u
})), K = ({
  searchType: n,
  isUniqueDirection: u,
  onSelect: O
}) => {
  const [T, g] = D([]), {
    pesqincLocationSelected: t,
    isAtlas: l
  } = G(), {
    throwError: a
  } = w(), v = async () => {
    var s, f, o, I;
    const r = {
      type: n,
      uniqueDirection: u,
      isAtlas: l
    };
    n === "ORIGIN" && ((s = t == null ? void 0 : t.destination) != null && s.id) && (r.locationId = (f = t == null ? void 0 : t.destination) == null ? void 0 : f.id), n === "DESTINATION" && ((o = t == null ? void 0 : t.origin) != null && o.id) && (r.locationId = (I = t == null ? void 0 : t.origin) == null ? void 0 : I.id);
    const m = await E(r);
    return N(m.content);
  };
  return A(() => {
    (async () => {
      if (n === "ORIGIN" && (t != null && t.origin) || n === "DESTINATION" && (t != null && t.destination)) {
        const s = [];
        return t != null && t.origin && s.push({
          label: t == null ? void 0 : t.origin.name,
          value: t == null ? void 0 : t.origin
        }), t != null && t.destination && s.push({
          label: t == null ? void 0 : t.destination.name,
          value: t == null ? void 0 : t.destination
        }), g(s);
      }
      const m = await v();
      g(m);
    })();
  }, [n, t]), {
    onSearch: B(
      async (r) => {
        var f, o, I, R;
        const m = j(), s = (/* @__PURE__ */ new Date()).getTime();
        try {
          const d = {
            type: n,
            searchString: r,
            uniqueDirection: u,
            isAtlas: l
          };
          n === "ORIGIN" && ((f = t == null ? void 0 : t.destination) != null && f.id) && (d.locationId = (o = t == null ? void 0 : t.destination) == null ? void 0 : o.id), n === "DESTINATION" && ((I = t == null ? void 0 : t.origin) != null && I.id) && (d.locationId = (R = t == null ? void 0 : t.origin) == null ? void 0 : R.id);
          const i = await E(d);
          if (!(i != null && i.totalElements)) {
            const C = (/* @__PURE__ */ new Date()).getTime() - s;
            return Object.assign(m, {
              message: `EMPTYRESULT ${r}`,
              duration: C,
              durationUnit: "milisseconds"
            }), g([]), a == null ? void 0 : a(m);
          }
          return g(N(i.content));
        } catch {
          const i = (/* @__PURE__ */ new Date()).getTime() - s;
          return Object.assign(m, {
            message: `ERROR ${r}`,
            duration: i,
            durationUnit: "milisseconds",
            level: 4
          }), g([]), a == null ? void 0 : a(m);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [u, n]
    ),
    options: T,
    renderOption: ({
      option: r
    }) => {
      var m;
      return /* @__PURE__ */ b.createElement(x.Option, { fullWidth: !0, label: r == null ? void 0 : r.label, supportText: (m = r == null ? void 0 : r.value) == null ? void 0 : m.description, onSelect: () => O(r.value, n), addonBefore: /* @__PURE__ */ b.createElement(P, null), style: {
        display: "flex"
      } });
    },
    onSelect: (r) => {
      O(r, n);
    }
  };
};
export {
  K as default
};
