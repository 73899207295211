import e from "react";
import { DesktopContainer as i } from "./FormContainer.styles.js";
import l from "./FormMobileProvider.js";
import p from "./FormProvider.js";
const u = ({
  formId: t,
  onSearch: r,
  renderMobile: o,
  renderDesktop: m
}) => {
  const n = (a) => {
    a.preventDefault(), r();
  };
  return /* @__PURE__ */ e.createElement(p, { formId: t }, /* @__PURE__ */ e.createElement("form", { id: t, onSubmit: n, "data-testid": t }, /* @__PURE__ */ e.createElement(i, null, m()), /* @__PURE__ */ e.createElement(l, null, o())));
};
u.displayName = "FormContainer";
export {
  u as default
};
