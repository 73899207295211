import { HTMLAttributes } from "react";
import * as Styled from "./Engine.styled";

function EngineBox(props: HTMLAttributes<HTMLDivElement>) {
    const { children, ...restProps } = props;

    return (
        <Styled.Container className="chui-engines" {...restProps}>
            {children}
        </Styled.Container>
    );
}

export default EngineBox;
