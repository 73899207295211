import r from "react";
import { InputDatePickerRange as D } from "@cvccorp-engines/components";
import { u } from "../../../EngineBusingCharterContext-Dp5Np45L.js";
const g = ({
  minDate: i,
  maxDate: c
}) => {
  var e, t, n, d;
  const {
    setCalendarDateSelected: l,
    calendarDateSelected: o,
    errors: a
  } = u(), m = ((e = a == null ? void 0 : a.dates) == null ? void 0 : e.startDate) || ((t = a == null ? void 0 : a.dates) == null ? void 0 : t.endDate);
  return /* @__PURE__ */ r.createElement(D, { value: o, onChange: (s) => {
    l(s);
  }, error: m ? {
    startDate: (n = a == null ? void 0 : a.dates) != null && n.startDate ? "Preencha a data de ida" : void 0,
    endDate: (d = a == null ? void 0 : a.dates) != null && d.endDate ? "Preencha a data de volta" : void 0
  } : void 0, min: i, max: c });
};
g.displayName = "CalendarRangePicker";
export {
  g as default
};
