"use client";
import r from "react";
import p from "@cvccorp-components/chui-react-components/components/Button";
import { h as u } from "../../OutlineDataTransferHorizontal-CdkXjr4c.js";
import { useTheme as i } from "@emotion/react";
import { Wrapper as l } from "./InputGroup.styles.js";
function s(o) {
  const {
    onReverse: t,
    hasError: n = !1,
    ...a
  } = o, {
    name: m
  } = i();
  return /* @__PURE__ */ r.createElement(l, { fullWidth: !0, hasError: n, ...a, renderButton: t ? () => /* @__PURE__ */ r.createElement(p, { color: m === "submarino" ? "primary" : "secondary", icon: /* @__PURE__ */ r.createElement(u, null), type: "button", variant: "text", className: "reverse-button", onClick: (e) => {
    e.stopPropagation(), e.preventDefault(), t == null || t();
  } }) : void 0 });
}
s.displayName = "InputGroup";
export {
  s as default
};
