import a, { useState as q, useMemo as K } from "react";
import U from "@cvccorp-components/chui-react-components/components/Button";
import { _ as J, a as A, e as Q, l as X, P as k } from "./PesqincFight-CZaFSiGf.js";
import { InputGroup as Y, InputDatePickerRange as Z, InputDatePicker as ee } from "@cvccorp-engines/components";
import { PaxFlights as te } from "@cvccorp-engines/pax";
import { useTheme as ae } from "@emotion/react";
import ne from "./hooks/useMergedMultDestination.js";
import { useFlightStore as oe } from "./store/FlightStore.js";
import { FlightOptions as y } from "./store/FlightStore.types.js";
import { LocationAndDateWrapper as re } from "./styled.js";
var le = ["size", "className"], O = function(e) {
  var u, m, s = e.size, c = s === void 0 ? "medium" : s, f = e.className, P = f === void 0 ? "" : f, i = J(e, le), v = i, h = ["chui-icon", P].join(" "), D = v.color || "#000014", g = A({}, i, {
    color: void 0
  }), l = Q[c] || X[c];
  return /* @__PURE__ */ a.createElement("svg", A({
    xmlns: "http://www.w3.org/2000/svg",
    fill: D,
    viewBox: "0 0 24 24",
    width: (u = i.width) != null ? u : l,
    height: (m = i.height) != null ? m : l,
    className: h
  }, g), /* @__PURE__ */ a.createElement("path", {
    fill: void 0,
    d: "M10 9.53a.5.5 0 0 0-.5.5v6.59a.5.5 0 0 0 1 0v-6.59a.5.5 0 0 0-.5-.5M13.606 9.676a.5.5 0 0 1 .854.354v6.59a.5.5 0 0 1-1 0v-6.59a.5.5 0 0 1 .146-.354"
  }), /* @__PURE__ */ a.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M21.24 4.91h-5.5v-.82a1.82 1.82 0 0 0-1.77-1.82h-4a1.82 1.82 0 0 0-1.83 1.82v.82H2.75a.5.5 0 1 0 0 1h1.49l1.21 14.16a1.81 1.81 0 0 0 1.79 1.67h9.45a1.81 1.81 0 0 0 1.81-1.66l1.19-14.16h1.55a.5.5 0 0 0 0-1zm-12.1-.82a.82.82 0 0 1 .82-.82h4a.82.82 0 0 1 .79.82v.82H9.14zm8.38 15.9a.83.83 0 0 1-.82.75H7.24a.82.82 0 0 1-.81-.75L5.24 5.91h13.42z",
    clipRule: "evenodd"
  }));
};
O.displayName = "OutlineBin";
function De($) {
  const {
    errors: e,
    max: u,
    min: m,
    origin: s,
    destination: c,
    isMult: f = !1,
    hidePax: P = !1,
    dates: i = [],
    onRemove: v,
    onReverse: h,
    onDateClear: D,
    onDateChange: g,
    onOriginChange: l,
    onDestinationChange: d,
    firstInputRef: S
  } = $, C = ae(), [F, x] = q(!1), L = ne(), {
    choice: b,
    pax: j,
    setPax: B,
    clearPax: G,
    classe: H
  } = oe(), p = b === y.GOING_AND_BACK, M = Array.isArray(i) ? i : [i], N = p ? M : M[0], W = p ? "Escolha as datas" : "Escolha a data", V = K(() => {
    if (b !== y.MULTIPLE_DESTINATION) return [];
    const n = L.reduce((o, t, r) => {
      var z, T;
      if (!((z = t == null ? void 0 : t.calendarDates) != null && z.startDate)) return o;
      const E = o[(T = t.calendarDates) == null ? void 0 : T.startDate] ?? [];
      return E.push({
        currentIndex: r,
        ...t
      }), o[t.calendarDates.startDate] = E, o;
    }, {});
    return Object.entries(n).map(([o, t]) => {
      const r = t.map((E) => E.currentIndex + 1);
      return {
        date: new Date(o),
        subtitle: r.length > 1 ? "Voos" : `Voo ${r.join("")}`,
        variant: "border",
        subtitleProps: {
          weight: "bold",
          style: {
            color: C.colors.feedback.active.default[500]
          }
        }
      };
    });
  }, [b, L, C.colors.feedback.active.default]), w = () => {
    var n, o, t, r;
    if (!((n = e == null ? void 0 : e.calendarDates) != null && n.endDate) && !((o = e == null ? void 0 : e.calendarDates) != null && o.startDate))
      return !1;
    if (p && ((t = e == null ? void 0 : e.calendarDates) != null && t.endDate || (r = e == null ? void 0 : e.calendarDates) != null && r.startDate))
      return "Preencha as datas";
    if (e.calendarDates.startDate) return "Preencha a data";
  }, I = () => e != null && e.destination ? "Preencha o campo de destino" : "", _ = () => e != null && e.origin ? "Preencha o campo de origem" : "";
  function R(n, o) {
    const {
      city: t,
      state: r
    } = n ?? {};
    return t && r ? `${t}, ${r}` : o;
  }
  return /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(Y, { fullWidth: !0, hasError: e.origin || e.destination, onReverse: () => h == null ? void 0 : h(c, s), status: I() || _() ? "danger" : void 0, hideIcon: !0, hideClearIcon: !1 }, /* @__PURE__ */ a.createElement(k, { supportText: _(), error: _(), placeholder: "De onde você vai sair?", label: R(s, "Origem"), value: s, onSelect: (n) => {
    l == null || l(n);
  }, onClear: () => {
    l == null || l(void 0);
  }, inputProps: {
    inputRef: S
  } }), /* @__PURE__ */ a.createElement(k, { supportText: I(), error: I(), placeholder: "Para onde você vai?", value: c, onSelect: (n) => {
    d == null || d(n);
  }, label: R(c, "Destino"), onClear: () => {
    d == null || d(void 0);
  } })), /* @__PURE__ */ a.createElement(re, { isMult: f }, p ? /* @__PURE__ */ a.createElement(
    Z,
    {
      showRangeCount: !0,
      max: u,
      min: m,
      value: N,
      onChange: g,
      onClear: D,
      error: {
        startDate: w() || "",
        endDate: w() ? " " : void 0
      }
    }
  ) : /* @__PURE__ */ a.createElement(ee, { label: W, value: N, onChange: g, onClear: D, range: p, max: u, min: m, options: V, showRangeCount: !0, error: w() }), v ? /* @__PURE__ */ a.createElement(U, { icon: /* @__PURE__ */ a.createElement(O, null), variant: "text", color: "neutral", size: "lg", type: "button", onClick: v }) : void 0, !P && /* @__PURE__ */ a.createElement(te, { open: F, onToggle: x, value: j, classe: H, onConfirm: (n) => {
    B(n), x(!1);
  }, onClear: () => {
    G(), x(!1);
  }, error: e == null ? void 0 : e.pax })));
}
export {
  De as L,
  O as v
};
