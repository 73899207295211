"use client";
import n, { useMemo as p } from "react";
import I from "@cvccorp-components/chui-react-components/components/InputDatePicker";
import { d as E } from "../../dayjs-CKf7tiKR.js";
import w from "../InputLoadingOverlay/InputLoadingOverlay.js";
import { Container as z } from "./styled.js";
function A(f) {
  const {
    max: a,
    min: o,
    label: c,
    value: d,
    error: i,
    options: m,
    content: y,
    inputRef: D,
    isLoading: g = !1,
    calendarDateVisible: t,
    range: h = !1,
    showRangeCount: C = !0,
    onClear: x,
    onChange: r,
    onChangeCalendar: P,
    disableDates: s = () => !1
  } = f, {
    minDate: u,
    maxDate: l
  } = p(() => {
    const e = o ?? /* @__PURE__ */ new Date();
    return {
      minDate: e,
      maxDate: a ?? E(e).add(1, "year").toDate()
    };
  }, [o, a]), k = (e) => {
    e && (r == null || r(e.toString()));
  }, v = p(() => ({
    options: m,
    maxDate: l,
    minDate: u,
    size: "sm",
    month: t == null ? void 0 : t.month,
    year: t == null ? void 0 : t.year,
    disable: s
  }), [s, t, l, u, m]);
  return /* @__PURE__ */ n.createElement(z, null, /* @__PURE__ */ n.createElement(I, { size: "md", range: h, label: c, onClear: x, datePickerProps: v, inputRef: D, status: i ? "danger" : void 0, value: d, fullWidth: !0, supportText: i, showRangeCount: C, onChangeCalendar: P, onChange: (e) => {
    Array.isArray(e) || k(e);
  }, content: y }), g ? /* @__PURE__ */ n.createElement(w, null) : void 0);
}
A.displayName = "InputDatePicker";
export {
  A as default
};
