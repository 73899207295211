import r, { useState as A, useMemo as S } from "react";
import D from "@cvccorp-components/chui-react-components/components/Autocomplete";
import q from "@cvccorp-components/chui-react-components/hooks/useResponsive";
import { getFlightsLocations as B } from "@cvccorp-engines/api";
import { getParams as E, useError as F, Pesqinc as k } from "@cvccorp-engines/components";
import G from "./utils/getFlightsParams.js";
function w() {
  return w = Object.assign ? Object.assign.bind() : function(i) {
    for (var n = 1; n < arguments.length; n++) {
      var t = arguments[n];
      for (var l in t) ({}).hasOwnProperty.call(t, l) && (i[l] = t[l]);
    }
    return i;
  }, w.apply(null, arguments);
}
function N(i, n) {
  if (i == null) return {};
  var t = {};
  for (var l in i) if ({}.hasOwnProperty.call(i, l)) {
    if (n.includes(l)) continue;
    t[l] = i[l];
  }
  return t;
}
var I = {
  size: {
    xs: "0.75rem",
    sm: "1rem",
    md: "1.25rem",
    lg: "1.5rem",
    xl: "2rem",
    xxl: "2.5rem"
  }
}, m = I.size, z = {
  xsmall: m.xs,
  small: m.sm,
  medium: m.md,
  large: m.lg,
  xlarge: m.xl,
  xxlarge: m.xxl
}, W = ["size", "className"], _ = function(n) {
  var t, l, d = n.size, p = d === void 0 ? "medium" : d, a = n.className, s = a === void 0 ? "" : a, o = N(n, W), x = o, f = ["chui-icon", s].join(" "), b = x.color || "#000014", P = w({}, o, {
    color: void 0
  }), h = m[p] || z[p];
  return /* @__PURE__ */ r.createElement("svg", w({
    xmlns: "http://www.w3.org/2000/svg",
    fill: b,
    viewBox: "0 0 24 24",
    width: (t = o.width) != null ? t : h,
    height: (l = o.height) != null ? l : h,
    className: f
  }, P), /* @__PURE__ */ r.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "m9.924 7.938 1.003 1.775c.232.4-.05.934-.566.934H7.776c-.401 0-.774-.132-1.075-.397L5.421 9.14C4.81 8.597 3.99 8.3 3.146 8.3h-.143a.03.03 0 0 0-.015.004.1.1 0 0 0-.016.013.1.1 0 0 0-.008.019l.001.014v.001l1.342 3.898c.222.653.867 1.108 1.6 1.108h2.925q.06-.016.124-.016h10.677c.772 0 1.402-.605 1.402-1.347 0-.74-.63-1.347-1.402-1.347h-3.431a.67.67 0 0 1-.445-.174L11.58 6.7h-.001a1.05 1.05 0 0 0-.723-.273c-.8 0-1.317.822-.932 1.51m-.107 6.344H5.91c-1.15 0-2.165-.71-2.517-1.745l-1.34-3.895c-.216-.623.273-1.27.952-1.27h.143c1.072 0 2.132.377 2.927 1.089l1.279 1.105c.114.102.25.154.423.154h2.057l-.757-1.34v-.002c-.732-1.306.25-2.877 1.78-2.877.52 0 1.005.181 1.384.525l4.093 3.694h3.302c1.303 0 2.365 1.02 2.365 2.273s-1.062 2.274-2.366 2.274h-3.303l-4.106 3.709c-.38.345-.884.525-1.385.525-1.53 0-2.512-1.571-1.78-2.877v-.001zm1.094.006-1.003 1.776c-.385.688.132 1.51.932 1.51.265 0 .529-.096.723-.272V17.3l3.346-3.021-3.988-.009zm5.521-4.479-.008-.007z",
    clipRule: "evenodd"
  }));
};
_.displayName = "OutlinePlaneRight";
var C = ["size", "className"], j = function(n) {
  var t, l, d = n.size, p = d === void 0 ? "medium" : d, a = n.className, s = a === void 0 ? "" : a, o = N(n, C), x = o, f = ["chui-icon", s].join(" "), b = x.color || "#000014", P = w({}, o, {
    color: void 0
  }), h = m[p] || z[p];
  return /* @__PURE__ */ r.createElement("svg", w({
    xmlns: "http://www.w3.org/2000/svg",
    fill: b,
    viewBox: "0 0 24 24",
    width: (t = o.width) != null ? t : h,
    height: (l = o.height) != null ? l : h,
    className: f
  }, P), /* @__PURE__ */ r.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M12 5.777a3.695 3.695 0 1 0 0 7.39 3.695 3.695 0 0 0 0-7.39M9.307 9.472a2.695 2.695 0 1 1 5.39 0 2.695 2.695 0 0 1-5.39 0",
    clipRule: "evenodd"
  }), /* @__PURE__ */ r.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M12 2.221a7.24 7.24 0 0 0-7.25 7.25c0 2.436 1.155 4.988 2.478 7.11 1.331 2.133 2.876 3.901 3.747 4.785l.002.002a1.433 1.433 0 0 0 2.021 0c.87-.87 2.422-2.612 3.76-4.738 1.332-2.114 2.493-4.672 2.493-7.158a7.24 7.24 0 0 0-7.25-7.25m-6.25 7.25A6.24 6.24 0 0 1 12 3.222a6.24 6.24 0 0 1 6.251 6.25c0 2.204-1.042 4.567-2.339 6.627a28 28 0 0 1-3.62 4.563.433.433 0 0 1-.607.001c-.83-.843-2.325-2.553-3.608-4.61-1.292-2.072-2.327-4.429-2.327-6.58",
    clipRule: "evenodd"
  }));
};
j.displayName = "OutlinePinMap";
const H = (i) => {
  const {
    error: n,
    value: t,
    supportTextProps: l,
    placeholder: d,
    label: p = "Destino",
    onSelect: a,
    onClear: s,
    inputProps: o
  } = i, {
    isMobile: x
  } = q(), [f, b] = A([]);
  function P(e) {
    if (!e) return "";
    const c = e.description, u = [e.city, e.state && [e.state, e.city].join(", ")].filter((v) => !!v).join(" - ");
    return e.type === "AIRPORT" ? c : u;
  }
  const h = E("packageGroup") ? String(E("packageGroup")).toUpperCase() : "STANDALONE", {
    throwError: $
  } = F(), L = async (e) => {
    var T, y;
    const c = G(), u = {
      operation: "location",
      productType: "flights",
      packageGroup: h,
      ...c
    }, v = (/* @__PURE__ */ new Date()).getTime();
    try {
      const g = await B(e);
      if (((T = g == null ? void 0 : g.locations) == null ? void 0 : T.length) > 0) {
        const O = (y = g == null ? void 0 : g.locations) == null ? void 0 : y.map((R) => ({
          label: R.description,
          value: R
        }));
        b(O);
      } else {
        const R = (/* @__PURE__ */ new Date()).getTime() - v;
        return Object.assign(u, {
          duration: R,
          _message: `EMPTYRESULT ${e}`
        }), $(u), [];
      }
    } catch {
      const O = (/* @__PURE__ */ new Date()).getTime() - v;
      return Object.assign(u, {
        duration: O,
        _message: `ERROR ${e}`
      }), $(u), [];
    }
  }, M = S(() => {
    const e = f;
    return t && e.push({
      value: t,
      label: t.description
    }), e;
  }, [f, t]);
  return /* @__PURE__ */ r.createElement(k, { label: p, placeholder: d, supportTextProps: l, onSearch: L, onSelect: function(c) {
    if (!c) {
      s ? s == null || s() : a == null || a(t);
      return;
    }
    a(c);
  }, options: M, error: n, value: t, renderOption: ({
    option: e
  }) => {
    var v;
    const {
      type: c
    } = e.value, u = {
      AIRPORT: /* @__PURE__ */ r.createElement(_, null),
      CITY: /* @__PURE__ */ r.createElement(j, null)
    };
    return /* @__PURE__ */ r.createElement(D.Option, { fullWidth: !0, supportText: P(e.value), label: [(v = e.value) == null ? void 0 : v.city, e.value.state].filter((T) => T).join(" - "), onSelect: () => a(e.value), addonBefore: u[c] });
  }, itemSize: x ? 80 : 60, inputProps: o });
};
H.displayName = "PesqincFight";
export {
  H as P,
  N as _,
  w as a,
  m as e,
  z as l
};
