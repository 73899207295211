import i from "@emotion/styled";
const d = i.div`
    display: flex;

    @media (max-width: 600px) {
        display: block;
    }

    align-items: center;

    & .divider {
        display: block;
        width: 1px;
        height: 40px;
        background-color: #000;
        margin: 0 16px;
        margin-block-end: 24px;
    }

    & div {
        container-type: normal;
    }
`;
export {
  d as OptionsContainer
};
