import e from "react";
import { useResponsive as l, Grid as p, Typography as t } from "@cvccorp-components/chui-react-components";
import "../../FilledSearch-CbEpjqut.js";
import { r as m } from "../../OutlineCalendar2-C7zMqPY5.js";
import "../../OutlineDataTransferHorizontal-CdkXjr4c.js";
import { useTheme as n } from "@emotion/react";
import { Container as s } from "./styled.js";
const c = ({
  address: o,
  name: a
}) => {
  const {
    isMobile: r
  } = l(), i = n().colors.support.primary;
  return /* @__PURE__ */ e.createElement(s, null, /* @__PURE__ */ e.createElement(m, { size: r ? "lg" : "xl", style: {
    display: "block"
  }, color: i }), /* @__PURE__ */ e.createElement(p, { direction: "column" }, /* @__PURE__ */ e.createElement(t, { variant: r ? "paragraph" : "headline", scale: r ? 3 : 7, weight: "bold", color: "support.primary" }, a), o && !r ? /* @__PURE__ */ e.createElement(t, { variant: "paragraph", scale: 3, color: "support.primary" }, o) : void 0));
};
c.displayName = "DestinationLabel";
export {
  c as default
};
