import o from "@emotion/styled";
const a = o.div`
    display: flex;
    gap: 8px;

    @media (min-width: ${({
  theme: i
}) => i.breakpoints.layoutGridSm}) {
        display: none;
    }
`, e = o.div`
    @media (max-width: ${({
  theme: i
}) => i.breakpoints.layoutGridSm}) {
        display: none;
    }
`;
export {
  e as DesktopContainer,
  a as MobileContainer
};
