"use client";
import r, { useEffect as j } from "react";
import V from "@cvccorp-components/chui-react-components/components/Checkbox";
import { gtm as l, InputGroup as W, InputDatePicker as H } from "@cvccorp-engines/components";
import { d as c } from "../../dayjs-CKf7tiKR.js";
import { CarsTags as n } from "../../constants/dataLayerTags.js";
import { useCarsStore as B } from "../../store/CarsStore.js";
import S from "../../utils/getDisabledOfficeHours.js";
import T from "../CarsLocation/CarsLocation.js";
import O from "../SelectHour/SelectHour.js";
import { LocationWrapper as F, DateWrapper as N } from "./LocationAndDate.styled.js";
import Y from "./useTimeLocations.js";
const g = 30;
function re() {
  const {
    errors: o,
    pickupDate: a,
    pickupTime: b,
    returnDate: d,
    returnTime: R,
    pickupLocation: u,
    returnLocation: m,
    showReturnLocation: v,
    reverse: h,
    setPickupDate: s,
    setPickupTime: p,
    setReturnDate: f,
    setReturnTime: C,
    clearPickupDate: y,
    clearReturnDate: D,
    setReturnLocation: i,
    setPickupLocation: t,
    setShowReturnLocation: K
  } = B(), A = () => {
    K(!v), l.pushEvent(n.TOGGLE_CHECKOUT_ORIGIN_OPTION);
  }, {
    pickupOptions: G,
    returnOptions: U
  } = Y();
  function I() {
    return o != null && o.pickupLocation ? "Preencha o local de retirada" : "";
  }
  function _() {
    return o != null && o.returnLocation ? "Preencha o local de devolução" : "";
  }
  const w = c(a).add(g, "days").toDate();
  return j(() => {
    if (!a || !d) return;
    const e = c(a), E = c(d), x = e.isAfter(E), M = E.diff(e, "days") > g;
    (x || M) && D();
  }, [a, d, D]), /* @__PURE__ */ r.createElement(r.Fragment, null, /* @__PURE__ */ r.createElement(F, null, v ? /* @__PURE__ */ r.createElement(W, { fullWidth: !0, className: "input-group", onReverse: () => h == null ? void 0 : h(), status: _() || I() ? "danger" : void 0, hideIcon: !0, hideClearIcon: !1 }, /* @__PURE__ */ r.createElement(T, { label: "Local de retirada", value: u, onSelect: (e) => {
    t == null || t(e), l.pushEvent(n.SET_CHECKIN_ORIGIN);
  }, onClear: () => {
    t == null || t(void 0), s(void 0), p(void 0), l.pushEvent(n.SET_CHECKIN_ORIGIN);
  }, error: I() }), /* @__PURE__ */ r.createElement(T, { label: "Local de devolução", value: m, onSelect: (e) => {
    i == null || i(e), l.pushEvent(n.SET_CHECKOUT_ORIGIN);
  }, error: _(), onClear: () => {
    i == null || i(void 0), f(void 0), C(void 0), l.pushEvent(n.SET_CHECKOUT_ORIGIN);
  } })) : /* @__PURE__ */ r.createElement(T, { label: "Local de retirada", value: u, onSelect: (e) => {
    t == null || t(e), l.pushEvent(n.SET_CHECKIN_ORIGIN);
  }, error: I(), onClear: () => {
    t == null || t(void 0), l.pushEvent(n.SET_CHECKIN_ORIGIN);
  } }), /* @__PURE__ */ r.createElement(V, { color: "primary", falseValue: "false", label: "Devolução em outro local ou destino", size: "md", trueValue: "true", checked: v, onChange: A })), /* @__PURE__ */ r.createElement(N, null, /* @__PURE__ */ r.createElement(H, { label: "Retirada", value: new Date(a || ""), onChange: (e) => {
    if (!e) return;
    const [E] = Array.isArray(e) ? e : [e];
    s == null || s(E), l.pushEvent(n.SET_CHECKIN_DATE);
  }, disableDates: (e) => S(e, u == null ? void 0 : u.officeHours), onClear: y, error: o == null ? void 0 : o.pickupDate }), /* @__PURE__ */ r.createElement(O, { options: G, label: "Horário", value: b, placeholder: "Horário de retirada", onSelect: (e) => {
    e && (p == null || p(e), l.pushEvent(n.SET_CHECKIN_TIME));
  } })), /* @__PURE__ */ r.createElement(N, null, /* @__PURE__ */ r.createElement(H, { label: "Devolução", value: new Date(d || ""), onChange: (e) => {
    if (!e) return;
    const [E] = Array.isArray(e) ? e : [e];
    f == null || f(E), l.pushEvent(n.SET_CHECKOUT_DATE);
  }, disableDates: (e) => S(e, (m == null ? void 0 : m.officeHours) || (u == null ? void 0 : u.officeHours)), min: a ? c(a).add(1, "d").toDate() : void 0, max: w, onClear: D, error: o == null ? void 0 : o.returnDate, calendarDateVisible: {
    month: a ? c(a).toDate().getMonth() : void 0,
    year: a ? c(a).toDate().getFullYear() : void 0
  } }), /* @__PURE__ */ r.createElement(O, { options: U, label: "Horário", value: R, placeholder: "Horário de devolução", onSelect: (e) => {
    e && (C == null || C(e), l.pushEvent(n.SET_CHECKOUT_TIME));
  } })));
}
export {
  re as default
};
