import e from "@emotion/styled";
const t = e.div(() => ({
  borderRadius: "0.5rem",
  position: "absolute",
  top: 0,
  width: "100%",
  backgroundColor: "#e0e0e0",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 100
})), i = e.div(() => ({
  position: "absolute"
}));
export {
  i as Indicator,
  t as Wrapper
};
