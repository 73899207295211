import { getParams as t } from "@cvccorp-engines/components";
const p = () => {
  const e = {
    iata: t("IataOri") ?? "",
    description: t("OriginDesc") ?? "",
    zoneId: Number(t("ZoneIdOrigin") || 0),
    city: t("CityOrigin") ?? "",
    state: t("StateOrigin") ?? "",
    country: t("CountryOrigin") ?? "",
    latitude: 0,
    longitude: 0,
    type: ""
  };
  return e.description ? e : void 0;
}, m = () => {
  const e = {
    iata: t("IataDes") ?? "",
    description: t("DestinationDesc") ?? "",
    latitude: Number(t("DestinationLat") || 0),
    longitude: Number(t("DestinationLon") || 0),
    zoneId: Number(t("ZoneId") || 0),
    city: t("City") ?? "",
    state: t("State") ?? "",
    country: t("Country") ?? "",
    type: ""
  };
  return e.description ? e : void 0;
}, D = () => {
  const e = t("CheckIn");
  return e ? e.concat("T03:00:00.000Z") ?? "" : "";
}, I = () => {
  const e = t("CheckOut");
  return e ? e.concat("T03:00:00.000Z") ?? "" : "";
}, O = () => {
  const e = t("Rooms"), i = t("Adults"), a = t("Children"), o = t("ChildAges"), u = [];
  if (!e) return [{
    adult: 2,
    children: 0,
    childrenDateOfBirth: []
  }];
  for (let n = 0; n < Number(e); n++) {
    const r = i == null ? void 0 : i.split(","), c = a == null ? void 0 : a.split(","), s = o == null ? void 0 : o.split(";"), l = {
      adult: Number((r == null ? void 0 : r[n]) || 0),
      children: Number((c == null ? void 0 : c[n]) || 0),
      childrenDateOfBirth: (s == null ? void 0 : s[n].split(",").map((g) => new Date((/* @__PURE__ */ new Date()).setFullYear((/* @__PURE__ */ new Date()).getFullYear() - Number(g))).toISOString().split("T")[0])) || []
    };
    u.push(l);
  }
  return u;
};
export {
  m as getPackageDestinationInitialValue,
  I as getPackageEndDateInitialValue,
  p as getPackageOriginInitialValue,
  O as getPackagePaxInitialValue,
  D as getPackageStartDateInitialValue
};
