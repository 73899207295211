import m, { useMemo as n } from "react";
import p from "@cvccorp-components/chui-react-components/components/Select";
import { useAutoCompleteItemSize as s } from "@cvccorp-engines/components";
import { usePax as a } from "../PaxPackages/PaxProvider/PaxProvider.js";
import l from "./getOptionsAgeInputSelect.js";
import { Container as c } from "./SelectAges.styles.js";
const u = (e) => {
  const {
    infantAge: t,
    variant: o
  } = a(), r = s(), i = n(() => l(t, o), [t, o]);
  return /* @__PURE__ */ m.createElement(c, null, /* @__PURE__ */ m.createElement(p, { inputProps: {
    size: "md",
    ...e.inputProps
  }, itemSize: r, ...e, options: i }));
};
u.displayName = "SelectAge";
export {
  u as default
};
