"use client";
var s = function(e) {
  return e == null || e === "undefined" || e === "null";
};
const p = () => window.location.href.replace("#rooms", "").replace("#freteRecommend", "").replace("#HotelSelected", "").replace("#FlightSelected", "").replace(/%252C/g, ",").split("?")[1];
function i(r, e) {
  try {
    const c = p();
    if (!r) return c.replace(/%257C/i, "|");
    const n = c.split("&"), a = [];
    for (let t = 0; t < n.length; t += 1) {
      const o = n[t].split("=");
      a[o[0]] = o[1];
    }
    const l = a[r].replace(/%257C/i, "|");
    return s(l) ? e : decodeURIComponent(l);
  } catch {
    return e;
  }
}
export {
  i as default
};
