import o from "react";
import { useEngineNavigation as l } from "@cvccorp-engines/navigation";
import u from "./components/Form/Form.js";
import { choices as s } from "./components/NavigationChips/NavigationChips.js";
import g from "./context/EnginePackageContext/EnginePackageContext.js";
import p from "./context/EnginePackageFullContext/EnginePackageFullContext.js";
import { usePackagePackageFullStoreStore as P } from "./store/PackagePackageFullStore/PackagePackageFullStore.js";
const f = (e) => {
  var t;
  const {
    product: r,
    ...a
  } = e, {
    choice: n
  } = P(), {
    navigationType: c
  } = l(), i = c === "button" ? n : (t = s.find((m) => m.product === r)) == null ? void 0 : t.value;
  return /* @__PURE__ */ o.createElement(g, null, /* @__PURE__ */ o.createElement(p, null, /* @__PURE__ */ o.createElement(u, { product: i, ...a })));
};
f.displayName = "EnginePackagePackageFull";
export {
  f as default
};
