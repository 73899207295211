import t from "react";
import { u as o, a as r } from "../../EngineBusingCharterContext-Dp5Np45L.js";
import s from "./CalendarPicker/CalendarPicker.js";
import m from "./CalendarRangePicker/CalendarRangePicker.js";
const c = () => {
  const {
    isExcursion: n,
    isOriginAndDestination: i
  } = o(), e = r().toDate(), a = r().add(1, "years").toDate();
  return n ? null : i ? /* @__PURE__ */ t.createElement(s, { minDate: e, maxDate: a }) : /* @__PURE__ */ t.createElement(m, { minDate: e, maxDate: a });
};
c.displayName = "Calendar";
export {
  c as default
};
