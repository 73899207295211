import l from "react";
import { InputDatePicker as m } from "@cvccorp-engines/components";
import { u as c } from "../../../EngineBusingContext-aoE1hnqw.js";
const u = ({
  minDate: o,
  maxDate: i
}) => {
  var n, r;
  const {
    setCalendarDateSelected: t,
    calendarDateSelected: e,
    errors: a
  } = c(), s = ((n = a == null ? void 0 : a.dates) == null ? void 0 : n.startDate) || ((r = a == null ? void 0 : a.dates) == null ? void 0 : r.endDate);
  return /* @__PURE__ */ l.createElement(m, { value: e == null ? void 0 : e[0], label: "Escolha a data", onChange: (d) => t([new Date(d)]), error: s ? "Preencha a data" : void 0, onClear: () => t([]), min: o, max: i });
};
u.displayName = "CalendarRangePicker";
export {
  u as default
};
