const a = ({
  level: r = 6,
  message: e = "",
  operation: o = "location",
  ...t
} = {}) => ({
  _app_name: "cvccorp-engines-busing-charter",
  productType: "charterBusing",
  productGroupType: "PACKAGE",
  _message: e,
  level: r,
  operation: o,
  ...t
});
export {
  a as getBusingCharterParamsForLogs
};
