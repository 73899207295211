import e from "@emotion/styled";
const t = e.div`
    container-type: inline-size;
`, n = e.div`
    width: 100%;
    display: flex;
    gap: 20px;

    @media (max-width: 600px) {
        flex-direction: column;
        gap: 12px;
    }

    @media (max-width: 988px) {
        justify-content: space-between;
        flex-wrap: wrap;
        & > .engine-motor {
            order: 1;
        }
    }
`;
export {
  n as CollapsableContainer,
  t as Container
};
