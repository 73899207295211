import { getBaseHostname as h, encodeSearchURL as m } from "@cvccorp-engines/components";
import { d as c } from "../dayjs-CKf7tiKR.js";
function P(e) {
  var a, s, o, r, i, n, d;
  const t = new URL(`${h()}/ingresso/search`);
  return t.searchParams.set("originCode", ((a = e.destination) == null ? void 0 : a.cityId) || ""), t.searchParams.set("destinationCode", ((s = e.destination) == null ? void 0 : s.cityId) || ""), t.searchParams.set("location", `${(o = e.destination) == null ? void 0 : o.cityName} - ${(r = e.destination) == null ? void 0 : r.stateName} - ${(i = e.destination) == null ? void 0 : i.countryName} - ` || ""), t.searchParams.set("startDate", c(e.date).format("YYYY-MM-DD") || ""), t.searchParams.set("endDate", c(e.date).add(2, "months").format("YYYY-MM-DD") || ""), (n = e.destination) != null && n.provider && t.searchParams.set("provider", (d = e.destination) == null ? void 0 : d.provider), t.searchParams.set("language", "pt-br"), m(t);
}
export {
  P as default
};
