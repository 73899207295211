import n, { useState as O, useMemo as _ } from "react";
import $ from "@cvccorp-components/chui-react-components/components/Autocomplete";
import { _ as z, a as P, e as A, l as k, r as L } from "../../OutlinePinMap-DS9UaHFY.js";
import { pesqIncService as N } from "@cvccorp-engines/api";
import { getParams as R, useError as S, Pesqinc as C } from "@cvccorp-engines/components";
var I = ["size", "className"], w = function(l) {
  var o, d, m = l.size, h = m === void 0 ? "medium" : m, i = l.className, v = i === void 0 ? "" : i, r = z(l, I), a = r, g = ["chui-icon", v].join(" "), f = a.color || "#000014", y = P({}, r, {
    color: void 0
  }), p = A[h] || k[h];
  return /* @__PURE__ */ n.createElement("svg", P({
    xmlns: "http://www.w3.org/2000/svg",
    fill: f,
    viewBox: "0 0 24 24",
    width: (o = r.width) != null ? o : p,
    height: (d = r.height) != null ? d : p,
    className: g
  }, y), /* @__PURE__ */ n.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "m9.924 7.938 1.003 1.775c.232.4-.05.934-.566.934H7.776c-.401 0-.774-.132-1.075-.397L5.421 9.14C4.81 8.597 3.99 8.3 3.146 8.3h-.143a.03.03 0 0 0-.015.004.1.1 0 0 0-.016.013.1.1 0 0 0-.008.019l.001.014v.001l1.342 3.898c.222.653.867 1.108 1.6 1.108h2.925q.06-.016.124-.016h10.677c.772 0 1.402-.605 1.402-1.347 0-.74-.63-1.347-1.402-1.347h-3.431a.67.67 0 0 1-.445-.174L11.58 6.7h-.001a1.05 1.05 0 0 0-.723-.273c-.8 0-1.317.822-.932 1.51m-.107 6.344H5.91c-1.15 0-2.165-.71-2.517-1.745l-1.34-3.895c-.216-.623.273-1.27.952-1.27h.143c1.072 0 2.132.377 2.927 1.089l1.279 1.105c.114.102.25.154.423.154h2.057l-.757-1.34v-.002c-.732-1.306.25-2.877 1.78-2.877.52 0 1.005.181 1.384.525l4.093 3.694h3.302c1.303 0 2.365 1.02 2.365 2.273s-1.062 2.274-2.366 2.274h-3.303l-4.106 3.709c-.38.345-.884.525-1.385.525-1.53 0-2.512-1.571-1.78-2.877v-.001zm1.094.006-1.003 1.776c-.385.688.132 1.51.932 1.51.265 0 .529-.096.723-.272V17.3l3.346-3.021-3.988-.009zm5.521-4.479-.008-.007z",
    clipRule: "evenodd"
  }));
};
w.displayName = "OutlinePlaneRight";
const j = {
  AIRPORT: /* @__PURE__ */ n.createElement(w, null),
  CITY: /* @__PURE__ */ n.createElement(L, null)
}, G = (E) => {
  const {
    error: l,
    value: o,
    placeholder: d,
    supportText: m,
    inputProps: h,
    label: i = "Destino",
    supportTextProps: v,
    onClear: r,
    onSelect: a
  } = E, [g, f] = O(() => []), y = R("packageGroup") ? String(R("packageGroup")).toUpperCase() : "PACKAGE", {
    throwError: p
  } = S(), T = async (e) => {
    const c = {
      productType: "package",
      packageGroup: y,
      operation: "location",
      player: null,
      errorResponse: l || {}
    };
    try {
      if (!e) return [];
      const u = e.replace(/ /g, "+"), s = await N("PAC", u), x = (s == null ? void 0 : s.locations.filter(({
        IATA: t
      }) => !!t.trim())).map((t) => ({
        label: t.description,
        value: {
          iata: t.IATA,
          description: t.description,
          zoneId: t.masterCode,
          city: t.city,
          state: t.state,
          country: t.country,
          latitude: t.latitude ?? 0,
          longitude: t.longitude ?? 0,
          type: t.type
        }
      }));
      (s == null ? void 0 : s.locations.length) === 0 && (Object.assign(c, {
        _message: `EMPTYRESULT ${e}`
      }), p(c)), f(x);
    } catch (u) {
      return Object.assign(c, {
        _message: `ERROR ${e}`,
        errorResponse: {
          error: u.message
        }
      }), p(c), [];
    }
  }, b = _(() => {
    const e = g;
    return o && e.push({
      value: o,
      label: o.description
    }), e;
  }, [g, o]);
  return /* @__PURE__ */ n.createElement(C, { label: i, supportText: m, supportTextProps: v, error: l, placeholder: d || i, onSearch: T, onSelect: (e) => {
    if (!e) {
      r ? r == null || r() : a == null || a(o);
      return;
    }
    a(e);
  }, options: b, value: o, renderOption: ({
    option: e
  }) => {
    const {
      type: c,
      iata: u
    } = e.value;
    return /* @__PURE__ */ n.createElement($.Option, { fullWidth: !0, "data-iata": u, supportText: e.label, label: e.label, onSelect: () => a(e.value), addonBefore: j[c] });
  }, inputProps: h });
};
G.displayName = "SinglePackage";
export {
  G as default
};
