import { create as r } from "zustand";
var t = /* @__PURE__ */ ((e) => (e.HOTELS = "hotels", e.RESORTS = "resorts", e))(t || {});
const c = r((e) => ({
  choice: "hotels",
  setChoice: (o) => e(() => ({
    choice: o
  }))
}));
export {
  t as OptionHotelsResortsBarValue,
  c as useHotelsResortsStore
};
