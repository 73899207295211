import e from "react";
import { useResponsive as s, Grid as n, Typography as a } from "@cvccorp-components/chui-react-components";
import "../../FilledSearch-CbEpjqut.js";
import { d as p, a as d } from "../../OutlineCalendar2-C7zMqPY5.js";
import "../../OutlineDataTransferHorizontal-CdkXjr4c.js";
import { Container as E } from "./styled.js";
const h = ({
  destination: t,
  origin: r,
  type: m
}) => {
  const {
    isMobile: c
  } = s(), l = m === "AIRPLANE" ? p : d, o = () => /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(n, { direction: "column" }, /* @__PURE__ */ e.createElement(a, { variant: "headline", scale: 7, weight: "bold" }, r.name), /* @__PURE__ */ e.createElement(a, { variant: "paragraph", scale: 3 }, r.address)), /* @__PURE__ */ e.createElement(l, { size: "xl" }), /* @__PURE__ */ e.createElement(n, { direction: "column" }, /* @__PURE__ */ e.createElement(a, { variant: "headline", scale: 7, weight: "bold" }, t.name), /* @__PURE__ */ e.createElement(a, { variant: "paragraph", scale: 3 }, t.address))), i = () => /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(l, { size: "lg" }), /* @__PURE__ */ e.createElement(n, { direction: "column" }, /* @__PURE__ */ e.createElement(a, { variant: "paragraph", scale: 3, weight: "bold" }, r.name, " | ", t.name)));
  return /* @__PURE__ */ e.createElement(E, null, c ? i() : o());
};
h.displayName = "TripDestination";
export {
  h as default
};
