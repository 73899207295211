import { useCallback, useMemo } from "react";
import { EngineBusing } from "@cvccorp-engines/busing";
import { EngineBusingCharter } from "@cvccorp-engines/busing-charter";
import { EngineCar } from "@cvccorp-engines/car";
import { EngineFlight } from "@cvccorp-engines/flight";
import { EngineHotelsResorts } from "@cvccorp-engines/hotel";
import { NAVIGATION_PRODUCTS, type NavigationProduct } from "@cvccorp-engines/navigation";
import { EnginePackageFull, EnginePackagePackageFull } from "@cvccorp-engines/package";
import EngineTickets from "@cvccorp-engines/ticket";
import EngineTours from "@cvccorp-engines/tours";
import EngineTravelInsurance from "@cvccorp-engines/travel-insurance";
import { useTheme } from "@emotion/react";
import { EngineBox } from "../components";
import { useEngineSearchContext } from "../context/EngineSearchProvider";
import useEngineWrapper, { getDefaultProductByTheme } from "./useEngineWrapper";

const ENGINES_CVC = {
    [NAVIGATION_PRODUCTS.AIR]: EngineFlight,
    [NAVIGATION_PRODUCTS.HOTEL]: EngineHotelsResorts,
    [NAVIGATION_PRODUCTS.PACKAGES]: EnginePackagePackageFull,
    [NAVIGATION_PRODUCTS.CARS]: EngineCar,
    [NAVIGATION_PRODUCTS.PACKAGE_COMPLETE]: EnginePackagePackageFull,
    [NAVIGATION_PRODUCTS.TICKETS]: EngineTickets,
    [NAVIGATION_PRODUCTS.TOURS]: EngineTours,
    [NAVIGATION_PRODUCTS.DISNEY]: null,
    [NAVIGATION_PRODUCTS.TRAVEL_SECURITY]: EngineTravelInsurance,
    [NAVIGATION_PRODUCTS.BUSING_CHARTER]: EngineBusingCharter,
    [NAVIGATION_PRODUCTS.BUSING]: EngineBusing,
};

const ENGINES_SUBMARINO = {
    [NAVIGATION_PRODUCTS.AIR]: EngineFlight,
    [NAVIGATION_PRODUCTS.HOTEL]: EngineHotelsResorts,
    [NAVIGATION_PRODUCTS.PACKAGE_COMPLETE]: EnginePackageFull,
};

function EngineWrapper() {
    const { name: themeName } = useTheme();
    const productDefault = getDefaultProductByTheme(themeName);
    const { renderNavigation } = useEngineSearchContext();
    const engines = useMemo(() => {
        if (themeName === "cvc") {
            return ENGINES_CVC;
        }

        return ENGINES_SUBMARINO;
    }, [themeName]);

    const { product, pathname, productsLinks, setProduct } = useEngineWrapper();

    const validatePackageName = useCallback(
        (product: NavigationProduct): NavigationProduct => {
            if (["/", productsLinks.packages].includes(pathname)) return productDefault;

            if (themeName === "cvc") {
                if (pathname === productsLinks[NAVIGATION_PRODUCTS.PACKAGES])
                    return NAVIGATION_PRODUCTS.PACKAGE_COMPLETE;
            }

            return product;
        },
        [pathname, productDefault, productsLinks, themeName],
    );

    const renderEngineProduct = useCallback(() => {
        const engineDefault = engines[productDefault];
        const engine = engines[validatePackageName(product)];
        return engine ? engine : engineDefault;
    }, [engines, product, productDefault, validatePackageName]);

    const EngineProduct = renderEngineProduct();

    return (
        <EngineBox>
            {renderNavigation?.({ setProduct })}
            <EngineProduct product={product} />
        </EngineBox>
    );
}

export default EngineWrapper;
