import { pesqIncService as h } from "@cvccorp-engines/api";
import { getParams as o, DateFormat as p } from "@cvccorp-engines/components";
import { d as D } from "../dayjs-CKf7tiKR.js";
const N = async () => {
  const t = o("Location");
  return t ? (await h("HOT", t)).locations.find((s) => s.description === t) ?? void 0 : void 0;
}, C = () => {
  const t = o("CheckIn");
  return t ? t.concat("T03:00:00.000Z") ?? "" : "";
}, H = () => {
  const t = o("CheckOut");
  return t ? t.concat("T03:00:00.000Z") ?? "" : "";
}, I = () => {
  var a;
  const t = o("Rooms"), e = o("Adults"), c = o("Children"), s = o("ChildAges"), r = [];
  if (!t) return [{
    adult: 2,
    children: 0,
    childrenDateOfBirth: []
  }];
  for (let n = 0; n < Number(t); n++) {
    const i = e == null ? void 0 : e.split(","), d = c == null ? void 0 : c.split(","), m = s ? s.split(";") : [], u = {
      adult: Number((i == null ? void 0 : i[n]) || 0),
      children: Number((d == null ? void 0 : d[n]) || 0),
      childrenDateOfBirth: (a = m == null ? void 0 : m[n]) == null ? void 0 : a.split(",").map((l) => D(/* @__PURE__ */ new Date()).subtract(Number(l), "years").format(p.YEAR_MONTH_DATE))
    };
    r.push(u);
  }
  return r;
}, O = () => {
  const t = Number(o("Adults") || 0), e = Number(o("Children") || 0), c = o("CheckIn") || "", s = o("CheckOut") || "", r = Number(o("Rooms") || 0), a = decodeURI(o("Location") || ""), n = o("ZoneId") || "";
  return {
    adult: t,
    children: e,
    origDate: c,
    destDate: s,
    room: r,
    destDescription: a,
    destZoneId: n,
    durationUnit: "Milliseconds"
  };
};
export {
  N as getHotelDestinationParam,
  H as getHotelEndDateParam,
  O as getHotelParamsForLogs,
  I as getHotelPaxParam,
  C as getHotelStartDateParam
};
