import e from "react";
import { useResponsive as i } from "@cvccorp-components/chui-react-components";
import l from "./components/DefaultLocationForm/DefaultLocationForm.js";
import m from "./components/ProductOptions/ProductOptions.js";
import a from "./components/SearchOptions/SearchOptions.js";
import { E as s } from "./EngineBusingCharterContext-Dp5Np45L.js";
import { OptionsContainer as c } from "./EngineBusingCharter.styles.js";
const p = (t) => {
  const {
    isAtlas: r
  } = t, {
    isMobile: n,
    isTablet: o
  } = i();
  return /* @__PURE__ */ e.createElement(s, { isAtlas: r }, /* @__PURE__ */ e.createElement(c, null, /* @__PURE__ */ e.createElement(m, null), !o && !n ? /* @__PURE__ */ e.createElement("div", { className: "divider" }) : null, /* @__PURE__ */ e.createElement(a, null)), /* @__PURE__ */ e.createElement(l, null));
};
p.displayName = "EngineBusing";
export {
  p as default
};
