import m, { createContext as i, useMemo as u, useContext as c } from "react";
const r = i({});
function d(o) {
  const {
    children: t,
    formId: e
  } = o, n = u(() => ({
    formId: e
  }), [e]);
  return /* @__PURE__ */ m.createElement(r.Provider, { value: n }, t);
}
d.displayName = "FormProvider";
function a() {
  return c(r);
}
export {
  d as default,
  a as useFormProvider
};
