import p from "@cvccorp-components/chui-react-components/components/InputGroup";
import a from "@emotion/styled";
const s = a(p)(({
  status: e,
  theme: r,
  hideIcon: i = !0,
  hideClearIcon: t = !1,
  hasError: o
}) => ({
  ".chui-input-group__separator": {
    "@media (max-width: 720px)": {
      zIndex: 100
    }
  },
  "fieldset > div": {
    'button[aria-label="Expandir"]': {
      opacity: i ? 0 : "unset",
      width: i ? 0 : "unset",
      display: i ? "none" : "unset",
      visibility: i ? "hidden" : "visible"
    },
    'button[aria-label="Limpar"]': {
      opacity: t ? 0 : "unset",
      width: t ? 0 : "unset",
      display: t ? "none" : "unset",
      visibility: t ? "hidden" : "visible"
    }
  },
  ".chui-input__help-text": {
    color: e === "danger" ? r.colors.feedback.danger[600] : "unset"
  },
  "& .chui-input-group__separator": {
    [`@media (min-width: ${r.breakpoints.layoutGridSm})`]: {
      ...o ? {
        borderColor: r.component.input.color.danger.borderColor,
        height: "3.5rem"
      } : {}
    }
  }
}));
export {
  s as Wrapper
};
