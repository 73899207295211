import C, { createContext as d, useState as i, useEffect as m, useCallback as c, useMemo as P, useContext as f } from "react";
import { DEFAULT_BUSING as h } from "../../../constants/defaultValues.js";
const a = d({});
function T(u) {
  const {
    children: v,
    travelers: e,
    error: n
  } = u, [o, r] = i(e);
  m(() => {
    r({
      ...e
    });
  }, [e]);
  const s = c((t) => {
    r({
      ...t
    });
  }, []), l = c(() => {
    const t = e ? {
      ...e
    } : {
      ...h
    };
    r(t);
  }, [e]), x = P(() => ({
    travelers: o,
    onChangeTraveler: s,
    onClearTraveler: l,
    error: n
  }), [s, l, o, n]);
  return /* @__PURE__ */ C.createElement(a.Provider, { value: x }, v);
}
T.displayName = "PaxProvider";
const g = a.Consumer;
function N() {
  return f(a);
}
export {
  g as PaxConsumer,
  T as default,
  N as usePax
};
