import n from "axios";
import { cookies as t } from "./cookies.js";
const a = n.create();
a.interceptors.request.use(function(e) {
  if (e.headers.isAtlas) {
    const s = t.get("access_token"), r = t.get("transactionId");
    e.headers["auth-new-token"] = "true", e.headers["access-token"] = s, e.headers["transaction-id"] = r || "undefined";
  }
  return e;
}, function(e) {
  return Promise.reject(e);
});
export {
  a as default
};
