import t from "react";
import a from "../InputDatePicker/InputDatePicker.js";
import n from "./CalendarDatePickerContent/CalendarDatePickerContent.js";
function i(e) {
  const {
    isLoading: r
  } = e;
  return /* @__PURE__ */ t.createElement(a, { ...e, content: /* @__PURE__ */ t.createElement(n, { isLoading: r }) });
}
i.displayName = "CalendarDatePicker";
export {
  i as default
};
