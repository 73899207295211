import { getBaseHostname as J, EngineQueryParams as e, BusingsTicketType as V, formatDateToParam as t, generateSearchParams as X, encodeSearchURL as Z } from "@cvccorp-engines/components";
import { calcChildAges as m } from "@cvccorp-engines/pax";
function ei(i) {
  var d, o, N, c, O, g, S, D, E, s, r, C, A, _, l, R, a, h, y, f, u, L, G, H, Y, x, P, U, j, K, b, v, F, W, w, B, k, M, Q, $;
  const I = new URL(`${J()}/rodoviario/exclusivo/search`), q = m(i.pax), n = {
    [e.TICKET_TYPE]: V.exclusivo,
    [e.ROOMS]: String((d = i.pax) == null ? void 0 : d.length),
    [e.ADULTS]: (N = (o = i.pax) == null ? void 0 : o.map) == null ? void 0 : N.call(o, (T) => T.adult).join(","),
    [e.CHILDREN]: (O = (c = i.pax) == null ? void 0 : c.map) == null ? void 0 : O.call(c, (T) => T.children).join(","),
    [e.CHILDREN_AGES]: q,
    [e.SEARCH_TYPE]: i.searchType
  };
  i.searchType === "originAndDestination" && Object.assign(n, {
    [e.ORIGIN_ID]: (g = i.origin) == null ? void 0 : g.id,
    [e.ORIGIN_DESC]: (S = i.origin) == null ? void 0 : S.description,
    [e.CITY_ORIGIN]: ((E = (D = i.origin) == null ? void 0 : D.city) == null ? void 0 : E.name) || ((s = i.origin) == null ? void 0 : s.name),
    [e.STATE_ORIGIN]: (A = (C = (r = i.origin) == null ? void 0 : r.city) == null ? void 0 : C.state) == null ? void 0 : A.name,
    [e.DESTINATION_ID]: (_ = i.destination) == null ? void 0 : _.id,
    [e.DESTINATION_DESC]: (l = i.destination) == null ? void 0 : l.description,
    [e.DESTINATION_LAT]: ((R = i.destination) == null ? void 0 : R.latitude) || "",
    [e.DESTINATION_LON]: ((a = i.destination) == null ? void 0 : a.longitude) || "",
    [e.CITY]: ((y = (h = i.destination) == null ? void 0 : h.city) == null ? void 0 : y.name) || ((f = i.destination) == null ? void 0 : f.name),
    [e.CHECKIN]: t(i.calendarDates[0].toString()),
    [e.STATE]: (G = (L = (u = i.destination) == null ? void 0 : u.city) == null ? void 0 : L.state) == null ? void 0 : G.name,
    [e.FORWARD_ONLY]: "false"
  }), i.searchType === "origin" && Object.assign(n, {
    [e.ORIGIN_ID]: (H = i.origin) == null ? void 0 : H.id,
    [e.ORIGIN_DESC]: (Y = i.origin) == null ? void 0 : Y.description,
    [e.CITY_ORIGIN]: ((P = (x = i.origin) == null ? void 0 : x.city) == null ? void 0 : P.name) || ((U = i.origin) == null ? void 0 : U.name),
    [e.STATE_ORIGIN]: (b = (K = (j = i.origin) == null ? void 0 : j.city) == null ? void 0 : K.state) == null ? void 0 : b.name,
    [e.CHECKIN]: t(i.calendarDates[0].toString()),
    [e.CHECKOUT]: t(i.calendarDates[1].toString()),
    [e.FORWARD_ONLY]: "false"
  }), i.searchType === "destination" && Object.assign(n, {
    [e.DESTINATION_ID]: (v = i.destination) == null ? void 0 : v.id,
    [e.DESTINATION_DESC]: (F = i.destination) == null ? void 0 : F.description,
    [e.DESTINATION_LAT]: (W = i.destination) == null ? void 0 : W.latitude,
    [e.DESTINATION_LON]: "",
    [e.CITY]: ((B = (w = i.destination) == null ? void 0 : w.city) == null ? void 0 : B.name) || ((k = i.destination) == null ? void 0 : k.name),
    [e.STATE]: ($ = (Q = (M = i.destination) == null ? void 0 : M.city) == null ? void 0 : Q.state) == null ? void 0 : $.name,
    [e.CHECKIN]: t(i.calendarDates[0].toString()),
    [e.CHECKOUT]: t(i.calendarDates[1].toString()),
    [e.FORWARD_ONLY]: "false"
  }), i.searchType === "excursion" && Object.assign(n, {
    [e.TOUR_CODE]: i.code
  });
  const z = X(n);
  return I.search = z.toString(), Z(I);
}
export {
  ei as default
};
