import p, { createContext as E, useState as i, useEffect as R, useCallback as u, useMemo as g, useContext as A } from "react";
import { DEFAULT_ROOM as d } from "../../../constants/defaultValues.js";
import D from "../../../validations/childrenPaxDate.js";
const t = E({});
function M(x) {
  const {
    children: C,
    infantAge: n,
    variant: a,
    rooms: e = [{
      ...d
    }]
  } = x, [s, r] = i(e), [c, f] = i();
  R(() => {
    r(e);
  }, [e]);
  const l = u((o) => {
    const [h, v] = D(o);
    f(h ? v : void 0), r(o);
  }, []), m = u(() => {
    const o = e ? [...e] : [{
      ...d
    }];
    r(o);
  }, [e]), P = g(() => ({
    infantAge: n,
    variant: a,
    rooms: s,
    errors: c,
    onChangeRooms: l,
    onClearRooms: m
  }), [c, l, m, s, n, a]);
  return /* @__PURE__ */ p.createElement(t.Provider, { value: P }, C);
}
M.displayName = "PaxProvider";
const y = t.Consumer;
function F() {
  return A(t);
}
export {
  y as PaxConsumer,
  M as default,
  F as usePax
};
