import t from "react";
import { u as o, d as r } from "../../EngineBusingContext-aoE1hnqw.js";
import d from "./CalendarPicker/CalendarPicker.js";
import s from "./CalendarRangePicker/CalendarRangePicker.js";
const m = () => {
  const {
    isOneWay: n
  } = o(), a = r().add(1, "days").toDate(), e = r().add(180, "days").toDate();
  return n ? /* @__PURE__ */ t.createElement(d, { minDate: a, maxDate: e }) : /* @__PURE__ */ t.createElement(s, { minDate: a, maxDate: e });
};
m.displayName = "Calendar";
export {
  m as default
};
