import a from "react";
import u from "@cvccorp-components/chui-react-components/components/Divider";
import p from "@cvccorp-components/chui-react-components/components/Typography";
import v from "@cvccorp-engines/date";
import x from "../../utils/calcAge.js";
import E from "../../utils/formatAgeToDate.js";
import d from "../Counter/Counter.js";
import C from "../Label/Label.js";
import A from "../SelectAge/SelectAge.js";
import { usePax as y } from "./PaxProvider/PaxProvider.js";
const F = () => {
  const {
    travelers: r,
    onChangeTraveler: n,
    error: h
  } = y(), {
    adult: o,
    children: i
  } = r, s = h && r.childrenDateOfBirth.length < r.children;
  function m(e) {
    n({
      ...r,
      [e]: ++r[e]
    });
  }
  function c(e) {
    n({
      ...r,
      [e]: --r[e]
    });
  }
  function g(e, t) {
    const l = r;
    return l.childrenDateOfBirth[t] = e, n(l);
  }
  return /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(d, { label: "Adultos", helperText: "Acima de 18 anos", max: 9, onChange: (e) => {
    e > o ? m("adult") : c("adult");
  }, value: o }), /* @__PURE__ */ a.createElement(d, { label: "Crianças", helperText: "Até 17 anos", max: 9, onChange: (e) => {
    e > i ? m("children") : c("children");
  }, value: i }), r.children ? /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(u, { color: "gray-light", size: "sm", variant: "line", vertical: !1 }), Array.from(Array(r.children).keys()).map((e, t) => /* @__PURE__ */ a.createElement(C, { key: t, text: `Nascimento Criança ${t + 1}` }, /* @__PURE__ */ a.createElement("div", null, /* @__PURE__ */ a.createElement(A, { label: "Idade", onSelect: (l) => {
    const f = E(l);
    g(v(String(f)).toString(), t);
  }, value: String(x(r.childrenDateOfBirth[t])) || void 0, placeholder: "Idade" })))), /* @__PURE__ */ a.createElement(p, { variant: "paragraph", scale: 3, weight: "regular", style: {
    margin: "0 16px 0px 16px",
    color: s ? "red" : "#000014"
  } }, "*Idade ao voltar da viagem")) : null);
};
export {
  F as default
};
