import { getParams as e, DateFormat as p } from "@cvccorp-engines/components";
import { d } from "../dayjs-CKf7tiKR.js";
const g = () => e("inter") ?? "false", P = () => {
  const t = e("CheckIn");
  return t ? t.concat("T03:00:00.000Z") ?? "" : "";
}, R = () => {
  const t = e("CheckOut");
  return t ? t.concat("T03:00:00.000Z") ?? "" : "";
}, b = () => {
  var u;
  const t = e("Rooms"), s = e("Adults"), r = e("Children"), m = e("ChildAges"), i = [];
  if (!t) return [{
    adult: 2,
    children: 0,
    childrenDateOfBirth: []
  }];
  for (let o = 0; o < Number(t); o++) {
    const n = s == null ? void 0 : s.split(","), a = r == null ? void 0 : r.split(","), c = m ? m.split(";") : [], h = {
      adult: Number(n == null ? void 0 : n[o]),
      children: Number(a == null ? void 0 : a[o]),
      childrenDateOfBirth: (u = c == null ? void 0 : c[o]) == null ? void 0 : u.split(",").map((l) => d(/* @__PURE__ */ new Date()).subtract(Number(l), "years").format(p.YEAR_MONTH_DATE))
    };
    i.push(h);
  }
  return i;
};
export {
  g as getResortsDestinationParam,
  R as getResortsEndDateParam,
  b as getResortsPaxParam,
  P as getResortsStartDateParam
};
