import { getBaseHostname as d, BusingsTicketType as s, formatDateToParam as o, generateSearchParams as g, encodeSearchURL as l } from "@cvccorp-engines/components";
import { calcChildAges as h } from "@cvccorp-engines/pax";
function u(e) {
  var i, r;
  const n = new URL(`${d()}/rodoviario/rodofacil/search`), a = h([e.pax]), t = {
    TicketType: s.rodofacil,
    Adults: String((i = e.pax) == null ? void 0 : i.adult),
    Children: String((r = e.pax) == null ? void 0 : r.children),
    ChildAges: a
  };
  Object.assign(t, {
    OriginId: String(e.origin.id),
    DestinationId: String(e.destination.id),
    OriginDesc: e.origin.name,
    DestinationDesc: e.destination.name,
    CheckIn: o(e.calendarDates[0].toString()),
    ...e.isOneWay ? {
      ForwardOnly: "true"
    } : {
      ForwardOnly: "false",
      CheckOut: o(e.calendarDates[1].toString())
    }
  });
  const c = g(t);
  return n.search = c.toString(), l(n);
}
export {
  u as default
};
