import e from "react";
import { useWindow as y, Typography as A, Button as N } from "@cvccorp-components/chui-react-components";
import { E as b } from "../../EngineFlight-kky_Pwke.js";
import { _ as x, a as E, e as T, l as _ } from "../../PesqincFight-CZaFSiGf.js";
import "../../index-Cq97huam.js";
import { useToggle as $, useQueryParam as o, EngineQueryParams as g, CollapsableEngineContainer as w, TripDestination as P, DateIntervalLabel as L, DateFormat as S, getQueryParam as v } from "@cvccorp-engines/components";
import { d as p } from "../../dayjs-CKf7tiKR.js";
import { PaxShort as F } from "@cvccorp-engines/pax";
import { EngineContainer as Q, Grid as z, TravelClassContainer as I } from "./styled.js";
var M = ["size", "className"], f = function(a) {
  var t, r, i = a.size, s = i === void 0 ? "medium" : i, c = a.className, m = c === void 0 ? "" : c, l = x(a, M), d = l, u = ["chui-icon", m].join(" "), D = d.color || "#000014", C = E({}, l, {
    color: void 0
  }), h = T[s] || _[s];
  return /* @__PURE__ */ e.createElement("svg", E({
    xmlns: "http://www.w3.org/2000/svg",
    fill: D,
    viewBox: "0 0 24 24",
    width: (t = l.width) != null ? t : h,
    height: (r = l.height) != null ? r : h,
    className: u
  }, C), /* @__PURE__ */ e.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M12 2.351a.5.5 0 0 1 .471.332l2.27 6.377 6.461.296a.5.5 0 0 1 .298.883l-5.08 4.244 1.737 6.578a.5.5 0 0 1-.77.538L12 17.854 6.612 21.6a.5.5 0 0 1-.769-.539l1.737-6.578L2.5 10.24a.5.5 0 0 1 .298-.883l6.46-.296 2.271-6.377A.5.5 0 0 1 12 2.351m0 1.99-1.912 5.37a.5.5 0 0 1-.448.332l-5.512.253 4.337 3.624a.5.5 0 0 1 .163.511l-1.47 5.572 4.557-3.168a.5.5 0 0 1 .57 0l4.558 3.168-1.471-5.572a.5.5 0 0 1 .163-.511l4.337-3.624-5.512-.253a.5.5 0 0 1-.448-.332z",
    clipRule: "evenodd"
  }));
};
f.displayName = "OutlineStar";
const O = (n) => {
  const {
    pathname: a
  } = n.location, t = a.split("/").filter((r) => r.length === 3).length / 2;
  return {
    originAddress: v(`MCO${t}`, n, "").split("-")[0],
    destinationAddress: v(`MCD${t}`, n, "").split("-")[0]
  };
}, j = (n) => {
  const {
    pathname: a
  } = n.location, t = a.split("/");
  if (t.length <= 4) return {
    origin: {
      name: "",
      address: ""
    },
    destination: {
      name: "",
      address: ""
    }
  };
  const {
    destinationAddress: r,
    originAddress: i
  } = O(n);
  return {
    origin: {
      name: t.at(-2),
      address: i
    },
    destination: {
      name: t.at(-1),
      address: r
    }
  };
}, k = (n) => {
  const {
    value: a,
    toggle: t
  } = $(), r = o("Date1", (/* @__PURE__ */ new Date()).toString()), i = o("Date2", p(/* @__PURE__ */ new Date()).add(7, "days").toString()), s = o("CLA", ""), c = o(g.ADT, 0), m = o(g.CHD, 0), l = o(g.INF, 0), d = y(), u = () => s === "exe" ? "Executiva & Primeira" : s === "eco" ? "Econômica & Premium" : "Todas as Classes";
  return /* @__PURE__ */ e.createElement(w, { ...n, isExpanded: a, renderExpandedEngine: () => /* @__PURE__ */ e.createElement(Q, null, /* @__PURE__ */ e.createElement(b, null)) }, /* @__PURE__ */ e.createElement(z, { container: !0, direction: "row", alignItems: "center" }, /* @__PURE__ */ e.createElement(P, { ...j(d), type: "AIRPLANE" }), /* @__PURE__ */ e.createElement(L, { dateFormat: S.DAY_AND_MONTH, initialDate: p(r).toDate(), finalDate: p(i).toDate() }), /* @__PURE__ */ e.createElement(F, { peopleQuantity: Number(c) + Number(m) + Number(l), roomsQuantity: 0 }), /* @__PURE__ */ e.createElement(I, null, /* @__PURE__ */ e.createElement(f, null), /* @__PURE__ */ e.createElement(A, { variant: "paragraph", scale: 3 }, u())), /* @__PURE__ */ e.createElement(N, { variant: "outline", color: "secondary", size: "sm", onClick: t }, a ? "Cancelar" : "Alterar")));
};
k.displayName = "EngineFlightCollapsable";
export {
  k as default
};
