import n from "react";
import { u as t } from "./EngineBusingCharterContext-Dp5Np45L.js";
import { ESearchTypes as i } from "@cvccorp-engines/api";
import { InputGroup as p } from "@cvccorp-engines/components";
import m from "./components/Pesqinc/PesqincInput/PesqincInput.js";
import { DefaultWrapper as u } from "./components/Pesqinc/Pesqinc.styles.js";
import o from "./components/Pesqinc/SingleBusingCharter/SingleBusingCharter.js";
const s = () => {
  const {
    errors: r,
    pesqincLocationSelected: e
  } = t();
  return /* @__PURE__ */ n.createElement(p, { status: r.pesqinc ? "danger" : void 0 }, /* @__PURE__ */ n.createElement(o, { searchType: i.ORIGIN, label: "Origem", placeholder: "De onde vai sair?", error: r.pesqinc && !(e != null && e.origin) ? a.origin : void 0 }), /* @__PURE__ */ n.createElement(o, { searchType: i.DESTINATION, label: "Destino", placeholder: "Para onde vai?", error: r.pesqinc && !(e != null && e.destination) ? a.destination : void 0 }));
};
s.displayName = "PesqincFull";
const d = {
  origin: {
    label: "Origem",
    placeholder: "De onde vai sair?",
    searchType: i.ORIGIN
  },
  destination: {
    label: "Destino",
    placeholder: "Para onde vai?",
    searchType: i.DESTINATION
  }
}, l = () => {
  const {
    searchType: r,
    errors: e
  } = t(), c = d[r];
  return /* @__PURE__ */ n.createElement(u, null, /* @__PURE__ */ n.createElement(o, { ...c, isUniqueDirection: !0, error: e.pesqinc ? a[r] : void 0 }));
};
l.displayName = "PesqincSingle";
const a = {
  origin: "Preencha o campo de origem",
  destination: "Preencha o campo de destino"
}, g = () => {
  const {
    isOriginAndDestination: r,
    isExcursion: e
  } = t();
  return r ? /* @__PURE__ */ n.createElement(s, null) : e ? /* @__PURE__ */ n.createElement(m, null) : /* @__PURE__ */ n.createElement(l, null);
};
g.displayName = "Pesqinc";
export {
  g as P,
  l as a,
  s as b,
  a as e
};
