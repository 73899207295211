"use client";
import e from "react";
import { FormContainer as t } from "@cvccorp-engines/components";
import l from "../../context/useEngineCarContext.js";
import n from "../LocationAndDate/LocationAndDate.js";
import { MotorContainer as r, ButtonContainer as a } from "./Form.styled.js";
function m() {
  const {
    handleSearch: o
  } = l();
  return /* @__PURE__ */ e.createElement(t, { formId: "form-car", onSearch: o, renderMobile: () => /* @__PURE__ */ e.createElement(t.Mobile, { title: "Carro", label: "Destino" }, /* @__PURE__ */ e.createElement(r, null, /* @__PURE__ */ e.createElement(n, null))), renderDesktop: () => /* @__PURE__ */ e.createElement(r, null, /* @__PURE__ */ e.createElement(n, null), /* @__PURE__ */ e.createElement(a, null, /* @__PURE__ */ e.createElement(t.Submit, null))) });
}
m.displayName = "SingleDestinationForm";
export {
  m as default
};
