import o from "../../libs/fetcher.js";
import e from "../../utils/QueryString.js";
import { AUTHORIZATION as s } from "./tokens.js";
const l = async (r) => {
  try {
    const {
      data: t
    } = await o.get("/api/tours/calendar", {
      headers: {
        authorization: s
      },
      params: {
        zoneId: r
      }
    });
    return t == null ? void 0 : t.calendar.map(({
      date: a,
      month: n
    }) => ({
      label: n,
      value: a
    }));
  } catch {
    throw new Error("Error in Tours calendar");
  }
}, m = async () => {
  try {
    const {
      data: r
    } = await o.get("/api/tours/consul/recommendedDestinations");
    return JSON.parse(r.consulValue);
  } catch {
    throw new Error("Error in Locations");
  }
}, p = async (r) => {
  try {
    const {
      data: t
    } = await o.get(`/api/tours/locations/pt-br/${e.text(r).normalizeNFD().toString()}`);
    return t;
  } catch {
    throw new Error("Error in Locations");
  }
};
export {
  l as getCalendarMonths,
  m as getRecommendedDestinations,
  p as getToursLocations
};
