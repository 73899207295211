"use client";
import T, { useState as C, useMemo as _ } from "react";
import { getCarsLocations as x } from "@cvccorp-engines/api";
import { getParams as d, useError as A, useFormMobile as N, Pesqinc as v } from "@cvccorp-engines/components";
function w(m) {
  const {
    error: f,
    label: R,
    value: s,
    inputProps: g,
    supportText: O,
    supportTextProps: y,
    onClear: c,
    onSelect: a
  } = m, [i, b] = C([]), h = d("packageGroup") ? String(d("packageGroup")).toUpperCase() : "STANDALONE", {
    throwError: u
  } = A(), {
    firstInputRef: E
  } = N(), P = async (o) => {
    var n, p;
    try {
      const e = await x(o);
      if ((n = e == null ? void 0 : e.locations) != null && n.length) {
        const r = (p = e.locations) == null ? void 0 : p.map((t) => ({
          label: t.description,
          value: {
            iata: t.IATA,
            zoneId: t.masterCode,
            description: t.description,
            type: t.type,
            officeHours: t.officeHours,
            city: t.city,
            state: t.state,
            country: t.country
          }
        }));
        b(r);
      } else {
        const r = l("Nenhuma cidade encontrada com o texto buscado", o, 200, "success");
        Object.assign(r, {
          _message: `EMPTYRESULT ${o}`
        }), u(r);
      }
    } catch (e) {
      const r = l("Ocorreu um erro ao tentar buscar a cidade", o, (e == null ? void 0 : e.message) || 500, "error");
      return Object.assign(r, {
        _message: `ERROR ${o}`,
        errorResponse: {
          error: e.message
        }
      }), u(r), [];
    }
  }, k = (o) => {
    if (!o) {
      c ? c == null || c() : a == null || a(s);
      return;
    }
    a == null || a(o);
  }, L = _(() => {
    const o = i;
    return s && o.push({
      value: s,
      label: s.description
    }), o;
  }, [i, s]), l = (o, n, p, e) => ({
    friendlyMessage: o,
    _message: `ERROR ${n}`,
    operation: "location",
    error: "Request failed with status code " + p,
    productType: "car",
    _level_name: e,
    packageGroup: h
  });
  return /* @__PURE__ */ T.createElement(v, { oneLineOption: !0, placeholder: "Cidade ou aeroporto", label: R, supportText: O, supportTextProps: y, error: f, value: s, onSearch: P, options: L, onSelect: k, inputProps: {
    inputRef: E,
    ...g
  } });
}
w.displayName = "CarsLocation";
export {
  w as default
};
