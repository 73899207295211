import { create as o } from "zustand";
import { getTourDateInitialValue as a, getTourAllDestinyInitialValue as i } from "../utils/getToursInitialValue.js";
const s = {
  destinations: !1,
  date: !1
}, d = o((t) => ({
  date: a(),
  errors: s,
  allDestiny: i(),
  destinations: [],
  setDestinations: (e) => t((r) => ({
    destinations: e,
    errors: {
      ...r.errors,
      destination: s.destinations
    }
  })),
  setDate: (e) => t((r) => ({
    date: e,
    errors: {
      ...r.errors,
      date: s.date
    }
  })),
  setAllDestiny: (e) => t({
    allDestiny: e
  }),
  setErrors: (e) => t(() => ({
    errors: e
  }))
}));
export {
  d as useToursStore
};
