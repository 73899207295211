import e from "react";
import o from "@cvccorp-components/chui-react-components/components/Button";
import { useEnginesConfigContext as i } from "@cvccorp-engines/provider";
function n(r) {
  const {
    searchButton: t
  } = i();
  return /* @__PURE__ */ e.createElement(o, { ...r, color: (t == null ? void 0 : t.color) ?? "primary", fullWidth: !0, size: "lg", variant: "filled", type: "submit" }, "Buscar");
}
n.displayName = "SearchButton";
export {
  n as default
};
