import { gtm as n, GTMProducts as c, GTMProductTower as i } from "@cvccorp-engines/components";
const a = (t) => {
  const o = [t.origin.city.state.country.name, t.destination.city.state.country.name].every((r) => r === "Brasil");
  return {
    checkin_date: n.formatDate(t.calendarDates),
    product: c.EXCLUSIVE_PACKAGE,
    product_tower: o ? i.NATIONAL : i.INTERNATIONAL,
    origin_city: t.origin.city.name,
    destination_city: t.destination.city.name
  };
};
export {
  a as formatStateToGTM
};
