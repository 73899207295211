"use client";
import e from "react";
import { DEFAULT_TRAVERS as p } from "../../constants/defaultValues.js";
import x from "../../utils/formatPaxFlights.js";
import P from "../Dropdown/Dropdown.js";
import d from "./PaxContent/PaxContent.js";
import g, { PaxConsumer as C } from "./PaxProvider/PaxProvider.js";
function E(a) {
  const {
    open: l,
    value: m = p,
    classe: s,
    error: o,
    onToggle: i,
    onConfirm: n,
    onClear: c
  } = a;
  return /* @__PURE__ */ e.createElement(g, { classe: s, travelers: m }, /* @__PURE__ */ e.createElement(C, null, ({
    travelers: t,
    classe: r,
    errors: f,
    onClearTraveler: u
  }) => /* @__PURE__ */ e.createElement(P, { error: o, supportText: o ? "Preencha a quantidade de passageiros válida" : "", onToggle: i, onClear: () => {
    c(), u();
  }, onClickOutside: () => {
    n({
      ...t,
      classe: r
    });
  }, onConfirm: () => {
    f || n({
      ...t,
      classe: r
    });
  }, open: l, label: x(t, r), renderContent: () => /* @__PURE__ */ e.createElement(d, null) })));
}
E.displayName = "PaxFlights";
export {
  E as default
};
