import o from "./FormContainer.js";
import r from "./FormContainerSubmit.js";
import m from "./FormMobile.js";
import { useFormMobile as f } from "./FormMobileProvider.js";
const n = Object.assign(o, {
  Submit: r,
  Mobile: m
});
export {
  n as default,
  f as useFormMobile
};
