"use client";
import s, { useRef as S, useState as b, useCallback as C } from "react";
import O from "@cvccorp-components/chui-react-components/components/Autocomplete";
import z from "@cvccorp-components/chui-react-components/components/Typography";
import A from "@cvccorp-components/chui-react-components/hooks/useResponsive";
import E from "../../hooks/useAutoCompleteItemSize/useAutoCompleteItemSize.js";
import { Container as L } from "./Pesqinc.styles.js";
const q = (a) => {
  const {
    error: t,
    value: p,
    label: i,
    options: u,
    inputProps: c,
    placeholder: m,
    supportText: f,
    supportTextProps: d,
    oneLineOption: h = !1,
    onClose: r,
    onSearch: e,
    ...g
  } = a, o = S(), [y, n] = b(!1), T = E(h), {
    isMobile: v
  } = A(), w = C((l, x) => {
    if (o.current && clearTimeout(o.current), l.length > 2) {
      const P = l.toLowerCase().replace(/^\s+|\s+$/g, "");
      o.current = setTimeout(async () => {
        try {
          n(!0), await (e == null ? void 0 : e(P, x));
        } finally {
          n(!1);
        }
      }, 700);
    }
  }, [e]);
  return /* @__PURE__ */ s.createElement(O, { itemSize: T, loading: y, inputProps: {
    role: "search",
    supportTextProps: d,
    fullWidth: !0,
    size: "md",
    ...c
  }, filterOption: () => !0, status: t ? "danger" : void 0, placeholder: m || i, noOption: /* @__PURE__ */ s.createElement(L, null, /* @__PURE__ */ s.createElement(z, { variant: "paragraph" }, "Digite pelo menos 3 letras")), value: p, label: i, options: u, dropdownAttributes: {
    style: {
      width: 530,
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  }, onClose: () => {
    v && (r == null || r());
  }, onSearch: w, supportText: t ? String(t) : f, ...g });
};
q.displayName = "Pesqinc";
export {
  q as default
};
