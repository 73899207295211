import { useRef as $, useMemo as S, useEffect as H } from "react";
import K from "@cvccorp-components/chui-react-components/hooks/useResponsive";
import { getAffreightmentCalendar as U } from "@cvccorp-engines/api";
import { useFetch as V, DateFormat as W, getCalendarDatesAsDate as Y, formatDateToParam as A } from "@cvccorp-engines/components";
import { d as k } from "../../dayjs-CKf7tiKR.js";
import { calcAge as q } from "@cvccorp-engines/pax";
import { usePackageFullStoreStore as w } from "../../store/EnginePackageFullStore/EnginePackageFullStore.js";
import z from "./formatOptions.js";
const G = 60 * 5 * 1e3;
function ne(y) {
  const {
    max: T,
    min: b
  } = y, {
    isMobile: D
  } = K(), u = $(), {
    errors: x,
    startCheckIn: n,
    destination: t,
    origin: a,
    pax: s,
    calendarDates: d,
    clearDates: M,
    setStartCheckin: C,
    setCalendarDates: h
  } = w((e) => ({
    pax: e.pax,
    origin: e.origin,
    errors: e.errors ?? {},
    destination: e.destination,
    startCheckIn: e.startCheckIn,
    calendarDates: e.calendarDates,
    clearDates: e.clearDates,
    setStartCheckin: e.setStartCheckin,
    setCalendarDates: e.setCalendarDates
  })), m = (n == null ? void 0 : n.length) === 2, E = (e) => {
    if (!e) return h("");
    const [r] = Array.isArray(e) ? e : [e];
    h(r == null ? void 0 : r.toString());
  }, O = (e, r) => {
    u.current && clearTimeout(u.current), u.current = setTimeout(() => {
      C([r.toString(), e.toString().padStart(2, "0")]);
    }, 700);
  }, p = S(() => s != null && s.length ? s.reduce((e, r) => {
    const l = [];
    for (let c = 0; c < r.adult; c++)
      l.push(30);
    for (let c = 0; c < r.children; c++) {
      const N = q(r.childrenDateOfBirth[c]);
      l.push(N);
    }
    return e.push(l), e;
  }, []) : [], [s]), B = async () => {
    var e;
    if (!(!(a != null && a.id) || !(t != null && t.id)))
      return U({
        startCheckIn: m ? `${n[0]}-${(e = (+(n[1] ?? "0") + 1).toString()) == null ? void 0 : e.padStart(2, "0")}-01` : void 0,
        numberOfMonths: m ? 2 : void 0,
        origin: a == null ? void 0 : a.id,
        destination: t == null ? void 0 : t.id,
        rooms: p
      });
  }, P = [t == null ? void 0 : t.id, a == null ? void 0 : a.id, n, p], {
    data: g,
    isLoading: f,
    previous: R,
    invalidate: _
  } = V(B, P, {
    cacheTime: G,
    enabled: !!(a != null && a.id && (t != null && t.id)),
    onSuccess: (e) => {
      if (d) {
        const r = k(d).format(W.YEAR_MONTH_DATE);
        (!e.calendar.length || !e.calendar.some((l) => l.date === r)) && h(void 0);
      }
    }
  }), i = Object.values(R).filter((e) => !!(e != null && e.calendar)).flatMap((e) => e == null ? void 0 : e.calendar), j = S(() => f && !m ? [] : z(i), [i, m, f]), v = (e) => !(i != null && i.some((r) => A(r == null ? void 0 : r.date) === A(e))), F = Y({
    startDate: d,
    endDate: ""
  }), I = () => {
    var r;
    if (!g || f) return;
    const {
      date: e
    } = ((r = g.calendar) == null ? void 0 : r[0]) || {};
    return k(e);
  };
  H(() => {
    _();
  }, [t, a, p]);
  const L = "column", o = I();
  return {
    min: b,
    max: T,
    errors: x,
    options: j,
    isMobile: D,
    isLoading: f,
    currentDate: F,
    initialDate: {
      month: o == null ? void 0 : o.month(),
      year: o == null ? void 0 : o.year()
    },
    priceWrapperDirection: L,
    clearDates: M,
    handleChange: E,
    setStartCheckin: C,
    handleDisableDates: v,
    handleChangeCalendar: O
  };
}
export {
  ne as useCalendarPicker
};
