import i from "@emotion/styled";
const d = i.div`
    padding: 0px;

    @container (min-width: 1024px) {
        display: flex;

        > div {
            width: 100%;
        }
    }
`;
export {
  d as LocationContainer
};
