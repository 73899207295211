import a, { Fragment as d } from "react";
import D from "@cvccorp-components/chui-react-components/components/Counter";
import g from "@cvccorp-components/chui-react-components/components/Divider";
import B from "@cvccorp-engines/date";
import { DEFAULT_ROOM as O } from "../../../constants/defaultValues.js";
import p from "../../Label/Label.js";
import { usePax as v } from "../PaxProvider/PaxProvider.js";
import E from "../PaxRooms/PaxRooms.js";
function n(r) {
  return /* @__PURE__ */ a.createElement(g, { color: "gray-light", size: "sm", variant: "line", vertical: !1, ...r });
}
n.displayName = "BaseDivider";
const C = () => {
  const {
    onChangeRooms: r,
    rooms: e,
    infantAge: c
  } = v();
  function o() {
    r([...e, O]);
  }
  function h() {
    r([...e].slice(0, -1));
  }
  function m(i, t) {
    r([...e.slice(0, t), {
      ...e[t],
      [i]: e[t][i] + 1,
      childrenDateOfBirth: i === "children" ? [...e[t].childrenDateOfBirth, B(/* @__PURE__ */ new Date()).subtract(c, "years").toDate().toString()] : e[t].childrenDateOfBirth
    }, ...e.slice(t + 1)]);
  }
  function s(i, t) {
    r([...e.slice(0, t), {
      ...e[t],
      [i]: e[t][i] - 1,
      childrenDateOfBirth: i === "children" ? [...e[t].childrenDateOfBirth].slice(0, -1) : e[t].childrenDateOfBirth
    }, ...e.slice(t + 1)]);
  }
  function f(i, t, u) {
    const l = [...e];
    l[t].childrenDateOfBirth[u] = i, r(l);
  }
  return /* @__PURE__ */ a.createElement("div", null, /* @__PURE__ */ a.createElement(p, { text: "Quartos", helperText: "Adicione os quartos" }, /* @__PURE__ */ a.createElement(D, { min: 1, max: 8, onChange: (i) => {
    i > e.length ? o() : h();
  }, value: e.length })), /* @__PURE__ */ a.createElement(n, null), e.map((i, t) => /* @__PURE__ */ a.createElement(d, { key: t }, /* @__PURE__ */ a.createElement(
    E,
    {
      roomIndex: t,
      room: i,
      onAdd: m,
      onRemove: s,
      addChildDateOfBirth: f
    }
  ), t < e.length - 1 && /* @__PURE__ */ a.createElement(n, null))));
};
C.displayName = "PaxContent";
export {
  C as default
};
