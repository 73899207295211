import p from "react";
import { Pesqinc as a } from "@cvccorp-engines/components";
const u = (e) => {
  const {
    label: t,
    options: r,
    value: o,
    placeholder: l,
    onSelect: s,
    inputProps: n,
    error: i
  } = e;
  return /* @__PURE__ */ p.createElement(a, { value: o, placeholder: l, onSelect: s, error: i, label: t, options: r, inputProps: {
    ...n,
    inputAttributes: {
      readOnly: !0
    },
    style: {
      userSelect: "none",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  } });
};
u.displayName = "SingleResorts";
export {
  u as default
};
