import e, { Fragment as D } from "react";
import f from "../ResortLocationAndDate/index.js";
const p = (t) => {
  const {
    children: a,
    onDateChange: n,
    onDateClear: o,
    max: r,
    min: i,
    destination: m,
    onDestinationChange: l,
    onOpenPax: c,
    dates: s = [],
    errors: d = {}
  } = t;
  return /* @__PURE__ */ e.createElement(D, null, /* @__PURE__ */ e.createElement(f, { errors: d, dates: s, onDateClear: o, onDateChange: n, max: r, min: i, destination: m, onDestinationChange: l, onOpenPax: c }), a);
};
p.displayName = "DefaultLocationForm";
export {
  p as default
};
