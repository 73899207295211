import r from "react";
import { Typography as t } from "@cvccorp-components/chui-react-components";
import "../../FilledSearch-CbEpjqut.js";
import { m } from "../../OutlineCalendar2-C7zMqPY5.js";
import "../../OutlineDataTransferHorizontal-CdkXjr4c.js";
import { d as o } from "../../dayjs-CKf7tiKR.js";
import { Container as p } from "./styled.js";
const i = ({
  date: e,
  dateFormat: a
}) => /* @__PURE__ */ r.createElement(p, null, /* @__PURE__ */ r.createElement(m, { size: "sm" }), /* @__PURE__ */ r.createElement(t, { variant: "paragraph", scale: 3 }, o(e).format(a)));
i.displayName = "TripDateLabel";
export {
  i as default
};
