import { useCallback as i, useMemo as f } from "react";
import u from "../useParsedLocalStorage/useParsedLocalStorage.js";
const d = 4, c = [], I = (l) => {
  const [e, r] = u(l, c), t = Array.isArray(e) ? e : c, o = i(() => {
    r([]);
  }, [r]), n = i((a) => {
    const y = JSON.stringify(a), s = [a, ...t.filter((S) => JSON.stringify(S) !== y)];
    s.length > d && s.pop(), r(s);
  }, [r, t]);
  return f(() => ({
    searchHistory: t,
    clearHistory: o,
    setSearchHistory: r,
    addValue: n
  }), [t, r, o, n]);
};
export {
  I as default
};
