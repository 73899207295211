import { _ as p, a as d, e as w, l as $ } from "./Icon-o1sXhey4.js";
import c from "react";
var f = ["size", "className"], g = function(i) {
  var l, a, o = i.size, s = o === void 0 ? "medium" : o, t = i.className, h = t === void 0 ? "" : t, e = p(i, f), m = e, n = ["chui-icon", h].join(" "), v = m.color || "#000014", u = d({}, e, {
    color: void 0
  }), r = w[s] || $[s];
  return /* @__PURE__ */ c.createElement("svg", d({
    xmlns: "http://www.w3.org/2000/svg",
    fill: v,
    viewBox: "0 0 24 24",
    width: (l = e.width) != null ? l : r,
    height: (a = e.height) != null ? a : r,
    className: n
  }, u), /* @__PURE__ */ c.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M13.239 2.595A8.19 8.19 0 0 0 2.676 13.436a8.18 8.18 0 0 0 10.745 4.294 8.2 8.2 0 0 0 2.013-1.23l5.271 5.28a.75.75 0 0 0 1.062-1.06l-5.272-5.28a8.19 8.19 0 0 0 .458-9.873 8.2 8.2 0 0 0-3.714-2.972m-4.428 1.06a6.69 6.69 0 0 1 4.02 12.696h-.001a6.68 6.68 0 0 1-8.775-3.506 6.69 6.69 0 0 1 4.756-9.19",
    clipRule: "evenodd"
  }));
};
g.displayName = "FilledSearch";
export {
  g as s
};
