import I, { useState as A, useEffect as D, useCallback as z } from "react";
import M from "@cvccorp-components/chui-react-components/components/Autocomplete";
import { getBusingLocations as P } from "@cvccorp-engines/api";
import { useError as _ } from "@cvccorp-engines/components";
import { u as y } from "../../../EngineBusingContext-aoE1hnqw.js";
import { getBusingParamsForLogs as G } from "../../../utils/getBusingParamsForLogs.js";
import { EPesqincSearchType as d } from "../Pesqinc.types.js";
function N() {
  return N = Object.assign ? Object.assign.bind() : function(n) {
    for (var i = 1; i < arguments.length; i++) {
      var l = arguments[i];
      for (var s in l) ({}).hasOwnProperty.call(l, s) && (n[s] = l[s]);
    }
    return n;
  }, N.apply(null, arguments);
}
function U(n, i) {
  if (n == null) return {};
  var l = {};
  for (var s in n) if ({}.hasOwnProperty.call(n, s)) {
    if (i.includes(s)) continue;
    l[s] = n[s];
  }
  return l;
}
var C = {
  size: {
    xs: "0.75rem",
    sm: "1rem",
    md: "1.25rem",
    lg: "1.5rem",
    xl: "2rem",
    xxl: "2.5rem"
  }
}, g = C.size, F = {
  xsmall: g.xs,
  small: g.sm,
  medium: g.md,
  large: g.lg,
  xlarge: g.xl,
  xxlarge: g.xxl
}, W = ["size", "className"], B = function(i) {
  var l, s, e = i.size, r = e === void 0 ? "medium" : e, f = i.className, R = f === void 0 ? "" : f, m = U(i, W), b = m, w = ["chui-icon", R].join(" "), E = b.color || "#000014", a = N({}, m, {
    color: void 0
  }), t = g[r] || F[r];
  return /* @__PURE__ */ I.createElement("svg", N({
    xmlns: "http://www.w3.org/2000/svg",
    fill: E,
    viewBox: "0 0 24 24",
    width: (l = m.width) != null ? l : t,
    height: (s = m.height) != null ? s : t,
    className: w
  }, a), /* @__PURE__ */ I.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M12 5.777a3.695 3.695 0 1 0 0 7.39 3.695 3.695 0 0 0 0-7.39M9.307 9.472a2.695 2.695 0 1 1 5.39 0 2.695 2.695 0 0 1-5.39 0",
    clipRule: "evenodd"
  }), /* @__PURE__ */ I.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M12 2.221a7.24 7.24 0 0 0-7.25 7.25c0 2.436 1.155 4.988 2.478 7.11 1.331 2.133 2.876 3.901 3.747 4.785l.002.002a1.433 1.433 0 0 0 2.021 0c.87-.87 2.422-2.612 3.76-4.738 1.332-2.114 2.493-4.672 2.493-7.158a7.24 7.24 0 0 0-7.25-7.25m-6.25 7.25A6.24 6.24 0 0 1 12 3.222a6.24 6.24 0 0 1 6.251 6.25c0 2.204-1.042 4.567-2.339 6.627a28 28 0 0 1-3.62 4.563.433.433 0 0 1-.607.001c-.83-.843-2.325-2.553-3.608-4.61-1.292-2.072-2.327-4.429-2.327-6.58",
    clipRule: "evenodd"
  }));
};
B.displayName = "OutlinePinMap";
const $ = (n) => n.map((i) => ({
  label: i.name,
  value: i
})), Z = ({
  searchType: n,
  isUniqueDirection: i,
  onSelect: l
}) => {
  const [s, e] = A([]), {
    pesqincLocationSelected: r,
    isAtlas: f,
    persistedBusing: R
  } = y(), {
    throwError: m
  } = _(), b = async () => {
    var u, v, x, O;
    const a = {
      isAtlas: f
    };
    n === d.ORIGIN && ((u = r == null ? void 0 : r.destination) != null && u.id) && (a.locationId = (v = r == null ? void 0 : r.destination) == null ? void 0 : v.id), n === d.DESTINATION && ((x = r == null ? void 0 : r.origin) != null && x.id) && (a.locationId = (O = r == null ? void 0 : r.origin) == null ? void 0 : O.id);
    const t = await P(a);
    return $(t);
  };
  return D(() => {
    const a = async () => {
      if (n === d.ORIGIN && (r != null && r.origin) || n === d.DESTINATION && (r != null && r.destination)) {
        const u = [];
        return r != null && r.origin && u.push({
          label: r == null ? void 0 : r.origin.name,
          value: r == null ? void 0 : r.origin
        }), r != null && r.destination && u.push({
          label: r == null ? void 0 : r.destination.name,
          value: r == null ? void 0 : r.destination
        }), e(u);
      }
      const t = await b();
      e(t);
    };
    s.length || a();
  }, [R, r]), {
    onSearch: z(
      async (a) => {
        var v, x, O, T;
        const t = G(), u = (/* @__PURE__ */ new Date()).getTime();
        try {
          const h = {
            searchString: a,
            isAtlas: f
          };
          n === d.ORIGIN && ((v = r == null ? void 0 : r.destination) != null && v.id) && (h.locationId = (x = r == null ? void 0 : r.destination) == null ? void 0 : x.id), n === d.DESTINATION && ((O = r == null ? void 0 : r.origin) != null && O.id) && (h.locationId = (T = r == null ? void 0 : r.origin) == null ? void 0 : T.id);
          const o = await P(h);
          if (!(o != null && o.length)) {
            const j = (/* @__PURE__ */ new Date()).getTime() - u;
            return Object.assign(t, {
              message: `EMPTYRESULT ${a}`,
              duration: j,
              durationUnit: "milisseconds"
            }), e([]), m == null ? void 0 : m(t);
          }
          return e($(o));
        } catch {
          const o = (/* @__PURE__ */ new Date()).getTime() - u;
          return Object.assign(t, {
            message: `ERROR ${a}`,
            duration: o,
            durationUnit: "milisseconds",
            level: 4
          }), e([]), m == null ? void 0 : m(t);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [i, n, r]
    ),
    options: s,
    renderOption: ({
      option: a
    }) => {
      var t;
      return /* @__PURE__ */ I.createElement(M.Option, { fullWidth: !0, label: a == null ? void 0 : a.label, supportText: (t = a == null ? void 0 : a.value) == null ? void 0 : t.name, onSelect: () => l(a.value, n), addonBefore: /* @__PURE__ */ I.createElement(B, null), style: {
        display: "flex"
      } });
    },
    onSelect: (a) => {
      l(a, n);
    }
  };
};
export {
  Z as default
};
