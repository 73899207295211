import e from "react";
import g from "@cvccorp-components/chui-react-components/components/Divider";
import u from "@cvccorp-components/chui-react-components/components/Typography";
import f from "@cvccorp-engines/date";
import v from "../../../utils/calcAge.js";
import x from "../../../utils/formatAgeToDate.js";
import m from "../../Counter/Counter.js";
import y from "../../Label/Label.js";
import E from "../../SelectAge/SelectAge.js";
import { usePax as A } from "../PaxProvider/PaxProvider.js";
const B = (i) => {
  const {
    room: r,
    roomIndex: a,
    onAdd: l,
    onRemove: n,
    addChildDateOfBirth: c
  } = i, {
    errors: d = {}
  } = A(), s = Object.keys(d).some((t) => t.split("-")[0] === String(a));
  return /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(m, { label: "Adultos", helperText: "Acima de 18 anos", value: r.adult, min: 1, max: 9, onChange: (t) => {
    t > r.adult ? l("adult", a) : n("adult", a);
  } }), /* @__PURE__ */ e.createElement(m, { label: "Crianças", max: 9, helperText: "De 0 a 17 anos", onChange: (t) => {
    t > r.children ? l("children", a) : n("children", a);
  }, value: r.children }), r.children > 0 && /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(g, { color: "gray-light", size: "sm", variant: "line", vertical: !1 }), Array.from(Array(r.children).keys()).map((t, o) => /* @__PURE__ */ e.createElement(y, { key: o, text: `Nascimento Criança ${o + 1}` }, /* @__PURE__ */ e.createElement("div", null, /* @__PURE__ */ e.createElement(E, { label: "Idade", onSelect: (p) => {
    const h = x(p);
    c(f(String(h)).toString(), a, o);
  }, value: String(v(r.childrenDateOfBirth[o])) || void 0, placeholder: "Idade" })))), /* @__PURE__ */ e.createElement(u, { variant: "paragraph", scale: 3, weight: "regular", style: {
    margin: "0 16px 0px 16px",
    color: s ? "red" : "#000014"
  } }, "*Idade ao voltar da viagem")));
};
export {
  B as default
};
