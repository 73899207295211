"use client";
import c from "react";
import s from "@cvccorp-components/chui-react-components/components/Select";
import { useAutoCompleteItemSize as n } from "@cvccorp-engines/components";
const u = (e) => {
  const {
    value: t,
    placeholder: o,
    options: r,
    inputProps: l,
    label: i,
    onSelect: m
  } = e, p = n();
  return /* @__PURE__ */ c.createElement(s, { itemSize: p, label: i, options: r, placeholder: o, value: t, onSelect: m, inputProps: {
    ...l,
    size: "md"
  } });
};
u.displayName = "SelectHour";
export {
  u as default
};
