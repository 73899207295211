"use client";
import e from "react";
import { DEFAULT_TRAVEL_INSURANCE as s } from "../../constants/defaultValues.js";
import c from "../../utils/formatPaxTravelInsurance.js";
import f from "../Dropdown/Dropdown.js";
import p from "./PaxContent.js";
import d, { PaxConsumer as x } from "./PaxProvider/PaxProvider.js";
function P(t) {
  const {
    open: l,
    value: m = s,
    error: a,
    onToggle: i,
    onConfirm: o,
    onClear: r
  } = t;
  return /* @__PURE__ */ e.createElement(d, { travelers: m }, /* @__PURE__ */ e.createElement(x, null, ({
    onClearTraveler: u,
    travelers: n
  }) => /* @__PURE__ */ e.createElement(f, { label: c(n), error: a, supportText: a ? "Preencha a quantidade de passageiros válida" : "", onToggle: i, onClickOutside: () => {
    o(n);
  }, onClear: () => {
    r == null || r(), u();
  }, onConfirm: () => {
    o(n);
  }, renderContent: () => /* @__PURE__ */ e.createElement(p, null), open: l })));
}
P.displayName = "PaxTravelInsurance";
export {
  P as default
};
