import u from "../utils/countPax.js";
import "@cvccorp-engines/date";
function m(o) {
  const {
    adult: r = 0,
    baby: a = 0,
    children: n = 0
  } = o ?? {}, {
    total: e
  } = u(o), c = e > 9, s = r < 1 || r > 9, d = n > 8, t = a > r;
  return [c || s || d || t, {
    baby: t && "Número de bebês maior que o de adultos"
  }];
}
export {
  m as default
};
