"use client";
import e, { useCallback as f } from "react";
import d from "@cvccorp-components/chui-react-components/components/Carousel/CarouselShelf";
import h from "@cvccorp-components/chui-react-components/components/Chip";
import { useTheme as k } from "@emotion/react";
import { Container as v, Link as y } from "./OptionsBar.styles.js";
const E = (o) => {
  const {
    checked: t,
    value: a,
    children: l,
    variant: r
  } = o;
  return r === "link" ? /* @__PURE__ */ e.createElement(y, { disabled: t, href: a, key: a, "aria-current": t ? "page" : void 0 }, l) : /* @__PURE__ */ e.createElement(e.Fragment, null, l);
};
function C(o) {
  const {
    options: t,
    value: a,
    variant: l,
    onChange: r,
    isChecked: i
  } = o, {
    name: s
  } = k(), p = f((c, m) => {
    c || r == null || r(m);
  }, [r]);
  return /* @__PURE__ */ e.createElement(v, null, /* @__PURE__ */ e.createElement(d, null, t.map((c) => {
    const {
      label: m,
      value: n
    } = c, u = (i == null ? void 0 : i(c)) || a === n;
    return /* @__PURE__ */ e.createElement(E, { checked: u, value: n, key: n, variant: l }, /* @__PURE__ */ e.createElement(h, { onClick: () => p(u, n), checked: u, color: s === "cvc" ? "secondary" : "primary", variant: "filter", key: n }, m));
  })));
}
C.displayName = "OptionsBar";
export {
  C as default
};
