import st, { createContext as it, useState as N, useCallback as at, useMemo as ot, useContext as ut } from "react";
import { useIsomorphicLayoutEffect as ct } from "@cvccorp-components/chui-react-components";
import { getParams as c, useParsedLocalStorage as dt, LocalStorageKeys as ft, redirectToSearch as lt } from "@cvccorp-engines/components";
import { formatPaxForService as ht, validateChildrenPaxDate as mt, formatAgeToDate as gt } from "@cvccorp-engines/pax";
import pt from "./utils/formatUrlToSearch.js";
var X = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function $t(D) {
  return D && D.__esModule && Object.prototype.hasOwnProperty.call(D, "default") ? D.default : D;
}
var tt = { exports: {} };
(function(D, G) {
  (function(_, v) {
    D.exports = v();
  })(X, function() {
    var _ = 1e3, v = 6e4, f = 36e5, H = "millisecond", m = "second", w = "minute", S = "hour", x = "day", B = "week", y = "month", C = "quarter", T = "year", E = "date", V = "Invalid Date", q = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/, R = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g, W = {
      name: "en",
      weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"),
      months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"),
      ordinal: function(t) {
        var n = ["th", "st", "nd", "rd"], r = t % 100;
        return "[" + t + (n[(r - 20) % 10] || n[r] || n[0]) + "]";
      }
    }, L = function(t, n, r) {
      var e = String(t);
      return !e || e.length >= n ? t : "" + Array(n + 1 - e.length).join(r) + t;
    }, p = {
      s: L,
      z: function(t) {
        var n = -t.utcOffset(), r = Math.abs(n), e = Math.floor(r / 60), i = r % 60;
        return (n <= 0 ? "+" : "-") + L(e, 2, "0") + ":" + L(i, 2, "0");
      },
      m: function o(t, n) {
        if (t.date() < n.date()) return -o(n, t);
        var r = 12 * (n.year() - t.year()) + (n.month() - t.month()), e = t.clone().add(r, y), i = n - e < 0, a = t.clone().add(r + (i ? -1 : 1), y);
        return +(-(r + (n - e) / (i ? e - a : a - e)) || 0);
      },
      a: function(t) {
        return t < 0 ? Math.ceil(t) || 0 : Math.floor(t);
      },
      p: function(t) {
        return {
          M: y,
          y: T,
          w: B,
          d: x,
          D: E,
          h: S,
          m: w,
          s: m,
          ms: H,
          Q: C
        }[t] || String(t || "").toLowerCase().replace(/s$/, "");
      },
      u: function(t) {
        return t === void 0;
      }
    }, j = "en", b = {};
    b[j] = W;
    var J = "$isDayjsObject", z = function(t) {
      return t instanceof h || !(!t || !t[J]);
    }, U = function o(t, n, r) {
      var e;
      if (!t) return j;
      if (typeof t == "string") {
        var i = t.toLowerCase();
        b[i] && (e = i), n && (b[i] = n, e = i);
        var a = t.split("-");
        if (!e && a.length > 1) return o(a[0]);
      } else {
        var u = t.name;
        b[u] = t, e = u;
      }
      return !r && e && (j = e), e || !r && j;
    }, l = function(t, n) {
      if (z(t)) return t.clone();
      var r = typeof n == "object" ? n : {};
      return r.date = t, r.args = arguments, new h(r);
    }, s = p;
    s.l = U, s.i = z, s.w = function(o, t) {
      return l(o, {
        locale: t.$L,
        utc: t.$u,
        x: t.$x,
        $offset: t.$offset
      });
    };
    var h = function() {
      function o(n) {
        this.$L = U(n.locale, null, !0), this.parse(n), this.$x = this.$x || n.x || {}, this[J] = !0;
      }
      var t = o.prototype;
      return t.parse = function(n) {
        this.$d = function(r) {
          var e = r.date, i = r.utc;
          if (e === null) return /* @__PURE__ */ new Date(NaN);
          if (s.u(e)) return /* @__PURE__ */ new Date();
          if (e instanceof Date) return new Date(e);
          if (typeof e == "string" && !/Z$/i.test(e)) {
            var a = e.match(q);
            if (a) {
              var u = a[2] - 1 || 0, d = (a[7] || "0").substring(0, 3);
              return i ? new Date(Date.UTC(a[1], u, a[3] || 1, a[4] || 0, a[5] || 0, a[6] || 0, d)) : new Date(a[1], u, a[3] || 1, a[4] || 0, a[5] || 0, a[6] || 0, d);
            }
          }
          return new Date(e);
        }(n), this.init();
      }, t.init = function() {
        var n = this.$d;
        this.$y = n.getFullYear(), this.$M = n.getMonth(), this.$D = n.getDate(), this.$W = n.getDay(), this.$H = n.getHours(), this.$m = n.getMinutes(), this.$s = n.getSeconds(), this.$ms = n.getMilliseconds();
      }, t.$utils = function() {
        return s;
      }, t.isValid = function() {
        return this.$d.toString() !== V;
      }, t.isSame = function(n, r) {
        var e = l(n);
        return this.startOf(r) <= e && e <= this.endOf(r);
      }, t.isAfter = function(n, r) {
        return l(n) < this.startOf(r);
      }, t.isBefore = function(n, r) {
        return this.endOf(r) < l(n);
      }, t.$g = function(n, r, e) {
        return s.u(n) ? this[r] : this.set(e, n);
      }, t.unix = function() {
        return Math.floor(this.valueOf() / 1e3);
      }, t.valueOf = function() {
        return this.$d.getTime();
      }, t.startOf = function(n, r) {
        var e = this, i = !!s.u(r) || r, a = s.p(n), u = function(k, O) {
          var A = s.w(e.$u ? Date.UTC(e.$y, O, k) : new Date(e.$y, O, k), e);
          return i ? A : A.endOf(x);
        }, d = function(k, O) {
          return s.w(e.toDate()[k].apply(e.toDate("s"), (i ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice(O)), e);
        }, g = this.$W, $ = this.$M, Y = this.$D, I = "set" + (this.$u ? "UTC" : "");
        switch (a) {
          case T:
            return i ? u(1, 0) : u(31, 11);
          case y:
            return i ? u(1, $) : u(0, $ + 1);
          case B:
            var P = this.$locale().weekStart || 0, F = (g < P ? g + 7 : g) - P;
            return u(i ? Y - F : Y + (6 - F), $);
          case x:
          case E:
            return d(I + "Hours", 0);
          case S:
            return d(I + "Minutes", 1);
          case w:
            return d(I + "Seconds", 2);
          case m:
            return d(I + "Milliseconds", 3);
          default:
            return this.clone();
        }
      }, t.endOf = function(n) {
        return this.startOf(n, !1);
      }, t.$set = function(n, r) {
        var e, i = s.p(n), a = "set" + (this.$u ? "UTC" : ""), u = (e = {}, e[x] = a + "Date", e[E] = a + "Date", e[y] = a + "Month", e[T] = a + "FullYear", e[S] = a + "Hours", e[w] = a + "Minutes", e[m] = a + "Seconds", e[H] = a + "Milliseconds", e)[i], d = i === x ? this.$D + (r - this.$W) : r;
        if (i === y || i === T) {
          var g = this.clone().set(E, 1);
          g.$d[u](d), g.init(), this.$d = g.set(E, Math.min(this.$D, g.daysInMonth())).$d;
        } else u && this.$d[u](d);
        return this.init(), this;
      }, t.set = function(n, r) {
        return this.clone().$set(n, r);
      }, t.get = function(n) {
        return this[s.p(n)]();
      }, t.add = function(n, r) {
        var e, i = this;
        n = Number(n);
        var a = s.p(r), u = function(Y) {
          var I = l(i);
          return s.w(I.date(I.date() + Math.round(Y * n)), i);
        };
        if (a === y) return this.set(y, this.$M + n);
        if (a === T) return this.set(T, this.$y + n);
        if (a === x) return u(1);
        if (a === B) return u(7);
        var d = (e = {}, e[w] = v, e[S] = f, e[m] = _, e)[a] || 1, g = this.$d.getTime() + n * d;
        return s.w(g, this);
      }, t.subtract = function(n, r) {
        return this.add(-1 * n, r);
      }, t.format = function(n) {
        var r = this, e = this.$locale();
        if (!this.isValid()) return e.invalidDate || V;
        var i = n || "YYYY-MM-DDTHH:mm:ssZ", a = s.z(this), u = this.$H, d = this.$m, g = this.$M, $ = e.weekdays, Y = e.months, I = e.meridiem, P = function(O, A, K, rt) {
          return O && (O[A] || O(r, i)) || K[A].slice(0, rt);
        }, F = function(O) {
          return s.s(u % 12 || 12, O, "0");
        }, Z = I || function(k, O, A) {
          var K = k < 12 ? "AM" : "PM";
          return A ? K.toLowerCase() : K;
        };
        return i.replace(R, function(k, O) {
          return O || function(A) {
            switch (A) {
              case "YY":
                return String(r.$y).slice(-2);
              case "YYYY":
                return s.s(r.$y, 4, "0");
              case "M":
                return g + 1;
              case "MM":
                return s.s(g + 1, 2, "0");
              case "MMM":
                return P(e.monthsShort, g, Y, 3);
              case "MMMM":
                return P(Y, g);
              case "D":
                return r.$D;
              case "DD":
                return s.s(r.$D, 2, "0");
              case "d":
                return String(r.$W);
              case "dd":
                return P(e.weekdaysMin, r.$W, $, 2);
              case "ddd":
                return P(e.weekdaysShort, r.$W, $, 3);
              case "dddd":
                return $[r.$W];
              case "H":
                return String(u);
              case "HH":
                return s.s(u, 2, "0");
              case "h":
                return F(1);
              case "hh":
                return F(2);
              case "a":
                return Z(u, d, !0);
              case "A":
                return Z(u, d, !1);
              case "m":
                return String(d);
              case "mm":
                return s.s(d, 2, "0");
              case "s":
                return String(r.$s);
              case "ss":
                return s.s(r.$s, 2, "0");
              case "SSS":
                return s.s(r.$ms, 3, "0");
              case "Z":
                return a;
            }
            return null;
          }(k) || a.replace(":", "");
        });
      }, t.utcOffset = function() {
        return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
      }, t.diff = function(n, r, e) {
        var i, a = this, u = s.p(r), d = l(n), g = (d.utcOffset() - this.utcOffset()) * v, $ = this - d, Y = function() {
          return s.m(a, d);
        };
        switch (u) {
          case T:
            i = Y() / 12;
            break;
          case y:
            i = Y();
            break;
          case C:
            i = Y() / 3;
            break;
          case B:
            i = ($ - g) / 6048e5;
            break;
          case x:
            i = ($ - g) / 864e5;
            break;
          case S:
            i = $ / f;
            break;
          case w:
            i = $ / v;
            break;
          case m:
            i = $ / _;
            break;
          default:
            i = $;
        }
        return e ? i : s.a(i);
      }, t.daysInMonth = function() {
        return this.endOf(y).$D;
      }, t.$locale = function() {
        return b[this.$L];
      }, t.locale = function(n, r) {
        if (!n) return this.$L;
        var e = this.clone(), i = U(n, r, !0);
        return i && (e.$L = i), e;
      }, t.clone = function() {
        return s.w(this.$d, this);
      }, t.toDate = function() {
        return new Date(this.valueOf());
      }, t.toJSON = function() {
        return this.isValid() ? this.toISOString() : null;
      }, t.toISOString = function() {
        return this.$d.toISOString();
      }, t.toString = function() {
        return this.$d.toUTCString();
      }, o;
    }(), M = h.prototype;
    return l.prototype = M, [["$ms", H], ["$s", m], ["$m", w], ["$H", S], ["$W", x], ["$M", y], ["$y", T], ["$D", E]].forEach(function(o) {
      M[o[1]] = function(t) {
        return this.$g(t, o[0], o[1]);
      };
    }), l.extend = function(o, t) {
      return o.$i || (o(t, h, l), o.$i = !0), l;
    }, l.locale = U, l.isDayjs = z, l.unix = function(o) {
      return l(1e3 * o);
    }, l.en = b[j], l.Ls = b, l.p = {}, l;
  });
})(tt);
var et = tt.exports;
const Q = /* @__PURE__ */ $t(et);
var Dt = { exports: {} };
(function(D, G) {
  (function(_, v) {
    D.exports = v(et);
  })(X, function(_) {
    function v(m) {
      return m && typeof m == "object" && "default" in m ? m : {
        default: m
      };
    }
    var f = v(_), H = {
      name: "pt-br",
      weekdays: "domingo_segunda-feira_terça-feira_quarta-feira_quinta-feira_sexta-feira_sábado".split("_"),
      weekdaysShort: "dom_seg_ter_qua_qui_sex_sáb".split("_"),
      weekdaysMin: "Do_2ª_3ª_4ª_5ª_6ª_Sá".split("_"),
      months: "janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro".split("_"),
      monthsShort: "jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez".split("_"),
      ordinal: function(w) {
        return w + "º";
      },
      formats: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D [de] MMMM [de] YYYY",
        LLL: "D [de] MMMM [de] YYYY [às] HH:mm",
        LLLL: "dddd, D [de] MMMM [de] YYYY [às] HH:mm"
      },
      relativeTime: {
        future: "em %s",
        past: "há %s",
        s: "poucos segundos",
        m: "um minuto",
        mm: "%d minutos",
        h: "uma hora",
        hh: "%d horas",
        d: "um dia",
        dd: "%d dias",
        M: "um mês",
        MM: "%d meses",
        y: "um ano",
        yy: "%d anos"
      }
    };
    return f.default.locale(H, null, !0), H;
  });
})(Dt);
Q.locale("pt-br");
const _t = [{
  adult: 2,
  children: 0,
  childrenDateOfBirth: []
}], nt = it(null), yt = ({
  children: D,
  isAtlas: G
}) => {
  const [_, v] = N(() => c("SearchType") || "originAndDestination"), [f, H] = N(() => {
    const s = {
      origin: void 0,
      destination: void 0
    };
    return c("OriginId") && (s.origin = {
      name: c("CityOrigin"),
      id: c("OriginId")
    }), c("DestinationId") && (s.destination = {
      name: c("City"),
      id: c("DestinationId")
    }), s;
  }), [m, w] = N(() => {
    let s = "";
    return c("TourCode") && (s = c("TourCode")), s;
  }), [S, x] = N(() => {
    if (c("Adults") || c("Children") || c("ChildAges") || c("Rooms")) {
      const s = [], h = (i) => i.map(Number), M = (i) => i.map((a) => gt(a)), o = () => c("ChildAges").split(";").map((a) => M(a.split(","))), t = c("Rooms") ? Number(c("Rooms")) : 1, n = c("Adults") ? h(c("Adults").split(",")) : [1], r = c("Children") ? h(c("Children").split(",")) : [1], e = c("ChildAges") ? o() : [];
      for (let i = 0; i < t; i++)
        s.push({
          adult: n[i],
          children: r[i],
          childrenDateOfBirth: e.length ? e[i] : e
        });
      return s;
    }
    return _t;
  }), [B, y] = N([]), [C, T] = N(() => {
    const s = [];
    return c("CheckIn") && (s[0] = Q(c("CheckIn")).toDate()), c("CheckOut") && (s[1] = Q(c("CheckOut")).toDate()), s;
  }), [E, V] = N({
    pesqinc: !1,
    pax: !1,
    dates: {
      startDate: !1,
      endDate: !1
    }
  }), q = _ === "excursion", R = _ === "originAndDestination", W = _ === "origin", L = _ === "destination", [p, j] = dt(ft.LAST_SEARCH_BUSING_CHARTER);
  ct(() => {
    if (p) {
      if (v(p.searchType), x(p.pax), p.calendarDates.length) {
        const s = p.calendarDates.map((h) => Q(h).toDate());
        T(s);
      }
      p.searchType === "originAndDestination" && (b(p.origin, "ORIGIN"), b(p.destination, "DESTINATION")), p.searchType === "origin" && b(p.origin, "ORIGIN"), p.searchType === "destination" && b(p.destination, "DESTINATION"), p.searchType === "excursion" && w(p.code);
    }
  }, []);
  const b = (s, h) => {
    y([]), H((M) => ({
      ...M,
      [h.toLowerCase()]: s
    }));
  }, J = (s) => {
    V((h) => ({
      ...h,
      ...s
    }));
  }, z = at(() => {
    const s = [!1, {}], h = !(f != null && f.origin), M = !(f != null && f.destination), o = !m, t = !ht(S).length, n = !C.length, r = C.length !== 2;
    return R && (s[0] = [h, M, t, n].some((e) => e), s[1] = {
      pesqinc: h || M,
      pax: t,
      dates: {
        startDate: n,
        endDate: !1
      }
    }), W && (s[0] = [h, t, r].some((e) => e), s[1] = {
      pesqinc: h,
      pax: t,
      dates: {
        startDate: C.length < 1,
        endDate: C.length < 2
      }
    }), L && (s[0] = [M, t, r].some((e) => e), s[1] = {
      pesqinc: M,
      pax: t,
      dates: {
        startDate: C.length < 1,
        endDate: C.length < 2
      }
    }), q && (s[0] = [o, t].some((e) => e), s[1] = {
      pesqinc: o,
      pax: t,
      dates: {
        startDate: !1,
        endDate: !1
      }
    }), s;
  }, [f, m, S, C, q, R, W, L]), U = () => {
    const [s, h] = z(), [M] = mt(S);
    if (V({
      ...h,
      pax: M
    }), s || M) return;
    const o = {
      origin: f == null ? void 0 : f.origin,
      destination: f == null ? void 0 : f.destination,
      code: m,
      pax: S,
      calendarDates: C,
      searchType: _
    };
    j(o);
    const t = pt(o);
    lt(t);
  }, l = ot(() => ({
    searchType: _,
    setSearchType: v,
    pesqincLocationSelected: f,
    setPesqincLocationSelected: b,
    pesqincInputValue: m,
    setPesqincInputValue: w,
    calendarDateSelected: C,
    setCalendarDateSelected: T,
    errors: E,
    setErrors: J,
    paxValue: S,
    setPaxValue: x,
    startDate: B,
    setStartDate: y,
    handleSearch: U,
    isExcursion: q,
    isOriginAndDestination: R,
    isOrigin: W,
    isDestination: L,
    isAtlas: G
  }), [_, v, f, m, C, E, S, B, q, R, W, L, G]);
  return /* @__PURE__ */ st.createElement(nt.Provider, { value: l }, D);
};
yt.displayName = "EngineBusingCharterProvider";
function Ot() {
  const D = ut(nt);
  if (!D) throw new Error("Chamada do hook useEngineBusingCharterContext inválida! Certifique-se que ele esta sendo chamado dentro do contexto EngineBusingCharterContext");
  return D;
}
export {
  yt as E,
  Q as a,
  nt as b,
  _t as d,
  Ot as u
};
