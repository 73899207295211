import type { ProductError } from "@attentions-components/hooks/dist/@types/error";
import { ErrorProvider, useError } from "@cvccorp-engines/components";
import { Navigation } from "@cvccorp-engines/navigation";
import { EngineBox } from "@cvccorp-engines/search/src";
import Engine from "@cvccorp-engines/search/src/engine";

function EngineWrapper() {
    const { throwError } = useError();
    return (
        <EngineBox>
            <ErrorProvider
                onError={params => {
                    throwError(params as ProductError);
                }}
            >
                <Engine renderNavigation={({ setProduct }) => <Navigation onClick={setProduct} />} />
            </ErrorProvider>
        </EngineBox>
    );
}

export default EngineWrapper;
