import n from "@emotion/styled";
const e = n.div`
    display: grid;
    width: 100%;
    container-type: inline-size;
    grid-template-columns: repeat(1, 1fr);
    column-gap: ${({
  theme: i
}) => i.spacing.size[5]};
    grid-gap: ${({
  theme: i
}) => i.spacing.size[5]};

    @container (min-width: 899px) {
        display: grid;
        grid-template-columns: 1.8fr 300px 300px 120px;
        column-gap: ${({
  theme: i
}) => i.spacing.size[5]};
        grid-gap: initial;
    }
`, a = n.div`
    margin-left: ${({
  theme: i
}) => i.spacing.size[5]};
    container-type: inline-size;
    @container (max-width: 1024px) {
        margin: 0;
    }
`;
export {
  a as ButtonContainer,
  e as MotorContainer
};
