import { useMemo as o } from "react";
import { useEngineSearchHistory as a, LocalStorageKeys as s } from "@cvccorp-engines/components";
import { formatDateAndTimeToDayJs as t } from "../../utils/formatDateAndTimeToDayJS.js";
const c = () => {
  const e = a(s.LAST_SEARCH_CARS);
  return o(() => ({
    ...e,
    searchHistory: e.searchHistory.map((r) => ({
      ...r,
      pickupDate: t(r.pickupDate, r.pickupTime).toDate(),
      returnDate: t(r.returnDate, r.returnTime).toDate()
    }))
  }), [e]);
};
export {
  c as default
};
