import s from "../../libs/fetcher.js";
import "../../libs/cookies.js";
const h = async ({
  searchString: r = "",
  size: o = "200",
  type: a,
  uniqueDirection: e,
  locationId: t,
  isAtlas: n
}) => {
  try {
    const {
      data: i
    } = await s.get("/api/static-busing/v1/cvc/charter/locations", {
      headers: {
        isAtlas: n
      },
      params: {
        ...r && {
          description: r
        },
        ...t && {
          id: t
        },
        ...e ? {
          [`${a.toLowerCase()}Charter`]: !0
        } : {
          size: o,
          type: a
        }
      }
    });
    return i;
  } catch {
    throw new Error("Error in Locations");
  }
}, g = async ({
  startDate: r,
  originId: o,
  destinationId: a,
  rooms: e,
  numberOfMonths: t,
  isAtlas: n
}) => {
  try {
    const {
      data: i
    } = await s.get("/api/static-busing/v1/packages/calendar", {
      headers: {
        isAtlas: n
      },
      params: {
        productType: "ROAD",
        rooms: e.map((c) => c).join(" "),
        origin: o,
        destination: a,
        startCheckIn: r,
        numberOfMonths: t
      }
    });
    return i;
  } catch {
    throw new Error("Error in Calendar");
  }
};
export {
  g as getBusingCharterCalendar,
  h as getBusingCharterLocations
};
