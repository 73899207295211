import t from "react";
import { Button as e } from "@cvccorp-components/chui-react-components";
import { useEnginesConfigContext as i } from "@cvccorp-engines/provider";
import { useFormProvider as m } from "./FormProvider.js";
function n() {
  const {
    searchButton: o
  } = i(), {
    formId: r
  } = m();
  return /* @__PURE__ */ t.createElement(e, { form: r, type: "submit", color: o.color, fullWidth: !0, size: "lg", variant: "filled" }, "Buscar");
}
n.displayName = "FormContainerSubmit";
export {
  n as default
};
