import n from "./calcAge.js";
const t = ";";
function o(c) {
  return (c == null ? void 0 : c.map((e) => {
    var r;
    return e.children ? ((r = e.childrenDateOfBirth) == null ? void 0 : r.map((i) => n(i))) || [] : [];
  }).join(t)) ?? t;
}
export {
  o as default
};
