function p(l, s) {
  const a = [], u = s === "package-full" ? ["0 a 23 meses"] : ["0 a 11 meses", "1 ano"];
  for (let e = 0; e <= 16; e++) {
    let t = `${e} anos`, o = String(e);
    e === 0 ? (t = u[0], o = l ? String(l) : "0") : s !== "package-full" && e === 1 && (t = u[1], o = l ? String(l + 1) : "1"), (e !== 1 || s !== "package-full") && a.push({
      label: t,
      value: o
    });
  }
  return a;
}
export {
  p as default
};
